import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Message from './Message'
import Loader from './Loader'
import { delete_account } from '../actions/userActions'
import { Spinner } from 'react-bootstrap'
import '../styles/modals.css'
import { USER_DELETE_RESET } from '../constants/userConstants'

function DeleteModal({closeCallback}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [deleteValidator, setDeleteValidator] = useState(null)

    const deleteInfo = useSelector(state => state.userDelete)
    const {loading, error, success} = deleteInfo

    const deleteHandler = async () => {
        dispatch(delete_account())
    }

    useEffect(() => {
        if(success){
            dispatch({
                type: USER_DELETE_RESET
            })
            window.location.href = `${window.location.origin}`
        }
    }, [success])

  return (
    <div className='modal-container'>
        <div style={{maxWidth: '320px', borderRadius: '8px'}} className='modal-window p-4'>
            <p className='text-black p-paragraph'>
                Are you sure you want to delete your account? All your information will be lost. This action cannot be reversed.
                <br /><br />
                <span style={{color: '#757575'}}>
                    If you are sure, please type DELETE ACCOUNT below to proceed.
                </span>
            </p>
            {loading ? (
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
            ) : error ? (
                <Message variant={'danger'}>
                    {error}
                </Message>
            ): (
            <>
                <input value={deleteValidator} onChange={e => setDeleteValidator(e.target.value)} className='p-input w-100' placeholder='Type DELETE ACCOUNT' type="text" />
                <div className='d-flex justify-content-center pt-4'>
                    <div onClick={closeCallback} className='p-button-2 p-2 me-2 text-black bg-white'>Cancel</div>
                    <div onClick={deleteHandler} className={`p-button-2 p-2 ms-2 ${(deleteValidator != 'DELETE ACCOUNT') ? 'button-disabled': ''}`}>Delete Account</div>
                </div>
            </>
            )}
        </div>
    </div>


  )
}

export default DeleteModal
