import '../styles/modals.css'
import React, { useEffect, useState } from 'react'
import  Select  from 'react-select'
import {
    getAreas,
    getTopics,
    getIndicators,
    getCountries,
  } from '../actions/searchActions'
import {
    getIndicatorUnits,
    getIndicatorTimeframe,
} from '../actions/platformActions'
import { roundy } from '../components/Utilities'
import { useDispatch, useSelector } from 'react-redux'
import { SAVE_SERIES_RESET } from '../constants/viewerConstants'
import Message from './Message'
import { Spinner, Form, Row, Col } from 'react-bootstrap'

function EditWidgetIndicator({closeCallback, updateCallback, data, wid}) {


  const dispatch = useDispatch()

  const [areaValue, setAreaValue] = useState(data.areaValue)
  const [topicValue, setTopicValue] = useState(data.topicValue)
  const [indicatorValue, setIndicatorValue] = useState(data.indicatorValue)
  const [unitValue, setUnitValue] = useState(data.unitValue)
  const [frequencyValue, setFrequencyValue] = useState(data.frequencyValue)
  const [countryValue, setCountryValue] = useState(data.countryValue)
  const [timeframeValue, setTimeframeValue] = useState(data.timeframeValue)
  const [timeframe2Value, setTimeframe2Value] = useState(data.timeframe2Value)
  const [rangeValue, setRangeValue] = useState(data.rangeValue)

  const [cardValue, setCardValue] = useState(data.cardValue)

  const [topicFiltered, setTopicFiltered] = useState([])
  const [indicatorFiltered, setIndicatorFiltered] = useState([])
  const [frequencyFiltered, setFrequencyFilteres] = useState([])

  useEffect(() => {
    dispatch(getAreas())
    dispatch(getTopics())
    dispatch(getIndicators())
    dispatch(getCountries())
  }, [])

  const areaInfo = useSelector(state => state.areaList)
  const { loading: loadingArea, error: errorArea, areaList } = areaInfo

  const topicInfo = useSelector(state => state.topicList)
  const { loading: loadingTopic , error: errorTopic, topicList } = topicInfo

  const indicatorInfo = useSelector(state => state.indicatorList)
  const { loading: loadingIndicator , error: errorIndicator, indicatorList } = indicatorInfo

  const countryInfo = useSelector(state => state.countryList)
  const { loading: loadingCountry , error: errorCountry, countryList } = countryInfo

  const unitInfo = useSelector(state => state.getIndicatorUnits)
  const { loading: loadingUnits , error: errorUnits, result } = unitInfo

  const timeframeInfo = useSelector(state => state.indicatorTimeframe)
  const { loading: loadingTimeframe , error: errorTimeframe, timeframe } = timeframeInfo

  useEffect(() => {
    setTopicFiltered(topicList)
    // if(topic && topic !== '' && topicList && topicList.length > 0){
    //   const topicItem = topicList.find(item => item.id == topic)
    //   const topicOption = [{api: topicItem.name, area: topicItem.foreign_area, label: topicItem.beauty_name, value: topicItem.id}]
    //   setTopicValue(topicOption)
    // }
  }, [topicList])



  useEffect(() => {
    setIndicatorFiltered(indicatorList)
    // if(indicator && indicator !== '' && indicatorList && indicatorList.length > 0){
    //   const indicatorItem = [...indicatorList].find(item => item.id == indicator)
    //   const indicatorOption = [{api: indicatorItem.name, area: indicatorItem.foreign_area, label: indicatorItem.name, topic: indicatorItem.foreign_topic, value: indicatorItem.id}]
    //   setIndicatorValue(indicatorOption)
    // }
  }, [indicatorList])



  useEffect(() => {

    if(countryValue && indicatorValue){

      let countryPar = null
      if(!isNaN(countryValue.value)){
        countryPar = countryValue.label
      }else{
        countryPar = countryValue.value
      }

        const indicatorPar = indicatorValue.api

        
        // console.log("indicatorPar: ", indicatorPar)
        // console.log("countryPar: ", countryPar)
        
        dispatch(getIndicatorUnits(indicatorPar, [countryPar]))
    }
  }, [countryValue])

  useEffect(() => {

    if(countryValue && indicatorValue && unitValue && frequencyValue && result){

        const countryPar = countryValue.label
        const indicatorPar = indicatorValue.api
        const unitPar = unitValue.value
        const frequencyPar = frequencyValue.value

        const listCountries = result.units_and_frequencies.find(item => item.unit == unitPar && item.frequency == frequencyPar).countries

        // console.log("indicatorPar: ", indicatorPar)
        // console.log("countryPar: ", countryPar)
        // console.log("listCountries: ", listCountries)
        // console.log("unitPar: ", unitPar)
        // console.log("frequencyPar: ", frequencyPar)

        dispatch(getIndicatorTimeframe(indicatorPar, listCountries, unitPar, frequencyPar))
    }
  }, [frequencyValue, result])

  useEffect(() => {

    if(countryValue && indicatorValue && unitValue && frequencyValue && timeframe){
        // console.log("Timeframe1: ", timeframeValue)
        // console.log("Timeframe2: ", timeframe2Value)

        if(timeframeValue && !timeframe2Value){
          // console.log("TIMEFRAME: ", timeframe)
            const finalValue = timeframe?.find(item => item.date == timeframeValue.value)
            // console.log('final value: ', finalValue)
            setCardValue(roundy(parseFloat(finalValue.value), 2))
        }

        if(timeframeValue && timeframe2Value){
            let maxValue = (new Date(timeframe2Value.value) > new Date(timeframeValue.value)) ? new Date(timeframe2Value.value) : new Date(timeframeValue.value)
            // console.log("list: ", list)
            let minValue = (new Date(timeframe2Value.value) > new Date(timeframeValue.value)) ? new Date(timeframeValue.value) : new Date(timeframe2Value.value)
            let listValues = timeframe?.filter(item =>  new Date(item.date) <= maxValue && new Date(item.date) >= minValue).map(item => parseFloat(item.value))

            const sum = listValues?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            // console.log("sum: ", sum)
            // Calcular el mean (promedio)
            const mean = sum / listValues?.length;

            // console.log("mean: ", mean)
            // console.log("typeof: ", typeof(mean))

            setCardValue(roundy(mean, 2))

        }
    }
  }, [timeframeValue, timeframe2Value, timeframe])



  const areaOptions = areaList?.map(item => {
    return {
      value: item.id,
      label: item.beauty_name,
      api: item.name,
    }
  })

  const areas = [...new Set(topicFiltered?.map(item => item.foreign_area))]

  const topicOptions = areas?.map(item => {
    return {
      label: areaList?.filter(element => element.id === item)[0]?.name.split('_')[1],
      options: topicFiltered.filter(element => element.foreign_area === item).map(element => {
        return {
          value: element.id,
          label: element.name.split('_')[1],
          area: element.foreign_area,
          // topic: element.foreign_topic,
          api: element.name
        }
      })
    }
  })


  const topics = [...new Set(indicatorFiltered?.map(item => item.foreign_topic))]

  const indicatorOptions = topics?.map(item => {
    return {
      label: topicFiltered?.filter(element => element.id === item)[0]?.name.split('_')[1],
      options: indicatorFiltered.filter(element => element.foreign_topic === item).map(element => {
        return {
          value: element.id,
          label: element.name,
          area: element.foreign_area,
          topic: element.foreign_topic,
          api: element.name
        }
      })
    }
  })

  const regionJson = {
    'LAC': [
      'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Ecuador', 'Paraguay', 'Peru', 'Uruguay',
      'Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama', 'Bahamas', 'Barbados', 'Jamaica', 'Dominican Republic',
    ],
    'Andean': ['Bolivia', 'Colombia', 'Ecuador', 'Peru'],
    'Southern_cone': ['Argentina', 'Brazil', 'Chile', 'Paraguay', 'Uruguay'],
    'Central_america': ['Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama'],
    'Caribbean': [
      'Bahamas', 'Barbados', 'Jamaica',
      'Dominican Republic']
    }

//   const regionOptions = Object.keys(regionJson).map(item => {
//     return {
//       value: item,
//       label: item.split('_').join(' '),
//     }
//   })
//   const countryOptions = countryList?.map(item => {
//     // return {
//     //   value: item.id,
//     //   label: item.name,
//     // }
    const countryOptions = [{
        label: 'Regions',
        options: [
            {
                value: 'LAC',
                label: 'LAC (Avg)',
            },
            {
                value: 'Andean',
                label: 'Andean (Avg)',
            },
            {
                value: 'Southern_cone',
                label: 'Southern Cone (Avg)',
            },
            {
                value: 'Central_america',
                label: 'Central America (Avg)',
            },
            {
                value: 'Caribbean',
                label: 'Caribbean (Avg)',
            },
        ]

      }, {
        label: 'Countries',
        options: countryList?.map(item => {
                return {
                  value: item.id,
                  label: item.name,
                }
            })
        }
    ]

    const units = Array.from(new Set(result?.units_and_frequencies.map(item => item.unit)))
    const unitOptions = units?.map(item => {
        return {
          value: item,
          label: item,
        }
      })

    const frequencies = result?.units_and_frequencies.filter(item => item.unit == unitValue?.value).map(item => item.frequency)
    const frequencyOptions = frequencies?.map(item => {
        return {
            value: item,
            label: item,
        }
    })

    const timeframes = timeframe?.map(item => item.date)
    const timeframeOptions = timeframes?.map(item => {
        return {
            value: item,
            label: item,
        }
    })



//   useEffect(() => {
//     if(areaList && areaList.length > 0){
//       const areaItem = areaList?.find(item => item.id == area)
//       const areaOption = {api: areaItem.name, label: areaItem.beauty_name, value: areaItem.id}
//       setAreaValue(areaOption)
//     }
//   }, [areaList])



//   useEffect(() => {
//     setTopicFiltered(topicList)
//     if(topicList && topicList.length > 0){
//       const topicItem = topicList.find(item => item.id == topic)
//       const topicOption = [{api: topicItem.name, area: topicItem.foreign_area, label: topicItem.beauty_name, value: topicItem.id}]
//       setTopicValue(topicOption)
//     }
//   }, [topicList])



//   useEffect(() => {
//     setIndicatorFiltered(indicatorList)
//     if(indicatorList && indicatorList.length > 0){
//       const indicatorItem = [...indicatorList].find(item => item.id == indicator)
//       const indicatorOption = [{api: indicatorItem.name, area: indicatorItem.foreign_area, label: indicatorItem.name, topic: indicatorItem.foreign_topic, value: indicatorItem.id}]
//       setIndicatorValue(indicatorOption)
//     }
//   }, [indicatorList])

const areaHandler = (option) => {
    setAreaValue(option)
    setTopicValue(null)
    setIndicatorValue(null)
    const newTopicArray = topicList.filter(item => item.foreign_area === option.value)
    setTopicFiltered(newTopicArray)
    const newIndicatorArray = [...indicatorList].filter(item => item.foreign_area === option.value)
    setIndicatorFiltered(newIndicatorArray)
  }

  const topicHandler = (option) => {
    // console.log("OPTION:::: ", option)
    setIndicatorValue(null)
    // if(option.length === 0){
    //   setTopicValue(null)
    //   const newIndicatorArray = [...indicatorList].filter(item => item.foreign_area === areaValue.value)
    //   setIndicatorFiltered(newIndicatorArray)
    // }else{

      setTopicValue(option)
      let area = areaList.filter(item => item.id == option?.area)[0]
      if(area.id !== areaValue?.value){
        setAreaValue({
          value: area.id,
          label: area.beauty_name,
          api: area.name,
        })
      }
      const newTopicArray = topicList.filter(item => item.foreign_area === area.id)
      setTopicFiltered(newTopicArray)
      const newIndicatorArray = [...indicatorList].filter(item => option.value == item.foreign_topic)
      setIndicatorFiltered(newIndicatorArray)
    // }
  }

  const indicatorHandler = (option) => {
    // if(option.length === 0){
    //   setIndicatorValue(null)
    //   const newIndicatorArray = [...indicatorList].filter(item => [...new Set(topicValue.map(element => element.value))].includes(item.foreign_topic))
    //   setIndicatorFiltered(newIndicatorArray)
    // }
    // else{
      setIndicatorValue(option)
      let area = areaList?.filter(item => item.id === option.area)[0]
      if(area.id !== areaValue?.value){
        setAreaValue({
          value: area.id,
          label: area.beauty_name,
          api: area.name,
        })


      let topic = topicList?.filter(item => item.id === option.topic)[0]
      if(!topicValue?.some(item => item.value === topic.id)){
        if(topicValue == null){
          setTopicValue([
            {
              value: topic.id,
              label: topic.beauty_name,
              area: topic.foreign_area,
              api: topic.name,
            }
          ])
        }else {
          setTopicValue(prev => [
            ...prev,
            {
                value: topic.id,
                label: topic.beauty_name,
                area: topic.foreign_area,
                api: topic.name,
            },
          ]);
        }
      }
    }

    const newTopicArray = topicList.filter(item => option.area == item.foreign_area)
    setTopicFiltered(newTopicArray)

    const newIndicatorArray = [...indicatorList].filter(item => option.topic == item.foreign_topic)
    setIndicatorFiltered(newIndicatorArray)

    setCountryValue(null)
    setUnitValue(null)
    setFrequencyValue(null)
    setTimeframeValue(null)
    setTimeframe2Value(null)
    setCardValue(null)
    // }
  }


    const unitHandler = (option) => {
        setUnitValue(option)
        setFrequencyValue(null)
        setTimeframeValue(null)
        setTimeframe2Value(null)
        setCardValue(null)
    }
  const countryHandler = (option) => {
    setCountryValue(option)

    setUnitValue(null)
    setFrequencyValue(null)
    setTimeframeValue(null)
    setTimeframe2Value(null)
    setCardValue(null)
    // console.log("countryValue: ", countryValue)
  }

  const frequencyHandler = (option) => {
    setFrequencyValue(option)
    setTimeframeValue(null)
    setTimeframe2Value(null)
  }





  const sendHandler = () => {

    const jsonContent = {
        areaValue: areaValue,
        topicValue: topicValue,
        indicatorValue: indicatorValue,
        unitValue: unitValue,
        frequencyValue: frequencyValue,
        countryValue: countryValue,
        timeframeValue: timeframeValue,
        timeframe2Value: timeframe2Value,
        rangeValue: rangeValue,
        cardValue: cardValue,
    }

    updateCallback(wid, jsonContent)
  }

  const rangeHandler = (e) => {
    setRangeValue(e.target.checked)
    if(!e.target.checked){
      setTimeframe2Value(null)
    }
  }

  const timeframeHandler = (option) => {
    setTimeframeValue(option)
  }

  const timeframe2Handler = (option) => {
    setTimeframe2Value(option)
  }


  return (
    <div className='modal-container'>
        <div className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

        <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{fontSize: '25px'}} className='bold-title text-black'>Edit Figure</h1>

           <p className='p-paragraph'>
                Use the textbox to edit your text
            </p>

            <Row>
                <Col style={{width: '20em'}}>

                    <span className='p-label-2 text-black'>Select Area:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${loadingArea && 'button-disabled'}`}
                          classNamePrefix="search-select"
                          options={areaOptions}
                          placeholder={'Select area'}
                          value={areaValue}
                          onChange={option => areaHandler(option)}
                      />
                      {loadingArea && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}
                    </div>

                    <span className='p-label-2 text-black'>Select Topic:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${loadingTopic && 'button-disabled'}`}
                          classNamePrefix="search-select"
                          options={topicOptions}
                          placeholder={'Select topic'}
                          value={topicValue}
                          onChange={option => topicHandler(option)}
                      />
                      {loadingTopic && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}

                    </div>

                    <span className='p-label-2 text-black'>Select Indicator:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${loadingIndicator && 'button-disabled'}`}
                          classNamePrefix="search-select"
                          options={indicatorOptions}
                          placeholder={'Select indicator'}
                          value={indicatorValue}
                          onChange={option => indicatorHandler(option)}
                      />
                      {loadingIndicator && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}

                    </div>

                    <span className='p-label-2 text-black'>Select Country/Region:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${
                            !indicatorValue ? 'button-disabled':
                            loadingCountry && 'button-disabled'}`}
                          classNamePrefix="search-select"
                          options={countryOptions}
                          placeholder={'Select country/region'}
                          value={countryValue}
                          onChange={option => countryHandler(option)}
                      />
                      {loadingCountry && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}
                    </div>

                    <span className='p-label-2 text-black'>Select Unit:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${
                            !countryValue ? 'button-disabled' :
                            loadingCountry ? 'button-disabled':
                            loadingUnits && ''}`}
                          classNamePrefix="custom-select"
                          placeholder={'Select unit'}
                          value={unitValue}
                          options={unitOptions}
                          onChange={option => unitHandler(option)}
                      />
                      {loadingUnits && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}
                    </div>

                </Col>

                <Col style={{width: '20em'}}>

                <span className='p-label-2 text-black'>Select Frequency:</span>
                <div className='d-flex align-items-center mb-4'>
                    <Select
                        className={`custom-select-container ${
                          !unitValue ? 'button-disabled':
                          loadingUnits && 'button-disabled'}`}
                        classNamePrefix="custom-select"
                        placeholder={'Select frequency'}
                        value={frequencyValue}
                        options={frequencyOptions}
                        onChange={option => frequencyHandler(option)}
                    />
                    {loadingUnits && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}
                </div>

                    <Form.Check // prettier-ignore
                        type={'checkbox'}
                        // id={`default-${type}`}
                        className={`p-label-2 ${!frequencyValue ? 'button-disabled': ''} text-black mb-3`}
                        label='Range timeframe (Avg)'
                        checked={rangeValue}
                        onChange={e => rangeHandler(e)}
                    />

                    <span className='p-label-2 text-black'>Select Timeframe:</span>
                    <div className='d-flex align-items-center mb-4'>
                      <Select
                          className={`custom-select-container ${
                            !frequencyValue ? 'button-disabled':
                            loadingTimeframe && 'button-disabled'}`}
                          classNamePrefix="custom-select"
                          placeholder={'Select timeframe'}
                          value={timeframeValue}
                          options={timeframeOptions}
                          onChange={option => timeframeHandler(option)}
                      />
                      {loadingTimeframe && (
                        <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}
                    </div>
                    {rangeValue && (
                        <>
                            <span className='p-label-2 text-black'>Select Timeframe:</span>
                            <Select
                                className={`custom-select-container ${
                                  !frequencyValue ? 'button-disabled':
                                  loadingTimeframe && 'button-disabled'} mb4`}
                                classNamePrefix="custom-select"
                                placeholder={'Select timeframe'}
                                value={timeframe2Value}
                                options={timeframeOptions}
                                onChange={option => timeframe2Handler(option)}
                            />
                        </>
                    )}

                    <div className='d-flex flex-column justify-content-center mt-5'>
                        <p className='dwidget-indicator-title'>{indicatorValue?.label}</p>
                        {/* <p className='dwidget-indicator-country'>Argentina</p> */}
                        <p className='dwidget-indicator-value'>
                          {loadingTimeframe ? (
                            <Spinner animation="border" className='ms-1' as={'span'} style={{width: '20px', height: '20px'}}/>
                          ): (
                            cardValue
                          )}
                          </p>
                        <p className='dwidget-indicator-unit'>{unitValue?.label} ({countryValue?.label})</p>
                        {/* <p className='dwidget-indicator-time'>{
                            timeframeValue?.label.split('-')[0] + '/' + timeframeValue?.label.split('-')[1]}
                             -
                            {timeframe2Value?.label.split('-')[0] + '/' + timeframe2Value?.label.split('-')[1]}
                        </p> */}
                        <p className='dwidget-indicator-time'>
                            {timeframeValue && frequencyValue.value == 'Annual' && (
                              timeframeValue?.label.split('-')[0]
                            )}
                            {timeframeValue && frequencyValue.value == 'Monthly' && (
                              timeframeValue?.label.split('-')[0] + '/' + timeframeValue?.label.split('-')[1]
                            )}
                            {timeframeValue && frequencyValue.value == 'Quarterly' && (
                              timeframeValue?.label.split('-')[0] + ' ' + (
                                (timeframeValue?.label.split('-')[1] == '03') ? 'Q1':
                                (timeframeValue?.label.split('-')[1] == '06') ? 'Q2':
                                (timeframeValue?.label.split('-')[1] == '09') ? 'Q3':
                                (timeframeValue?.label.split('-')[1] == '12') && 'Q4')
                            )}
                            {rangeValue && (
                              ' - '
                            )}
                            {timeframe2Value && frequencyValue.value == 'Annual' && (
                              timeframe2Value?.label.split('-')[0]
                            )}
                            {timeframe2Value && frequencyValue.value == 'Monthly' && (
                              timeframe2Value?.label.split('-')[0] + '/' + timeframe2Value?.label.split('-')[1]
                            )}
                            {timeframe2Value && frequencyValue.value == 'Quarterly' && (
                              timeframe2Value?.label.split('-')[0] + ' ' + (
                                (timeframe2Value?.label.split('-')[1] == '03') ? 'Q1':
                                (timeframe2Value?.label.split('-')[1] == '06') ? 'Q2':
                                (timeframe2Value?.label.split('-')[1] == '09') ? 'Q3':
                                (timeframe2Value?.label.split('-')[1] == '12') && 'Q4'
                              )
                            )}
                        </p>
                    </div>

                    {/* <div>
                        {previewValue}
                    </div> */}
                </Col>
            </Row>




           <div onClick={sendHandler} style={{width: '100%'}} className={`login-button ${!timeframeValue ? 'button-disabled': ''} mt-3`}>Ok</div>
            {/* {loading ? (
              <div className='d-flex justify-content-center align-items-center pt-3'>
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
            ): error ? (
              <>
                <Message variant='danger'>{error}</Message>
                <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
              </>
            ): (
              <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
            )} */}


        </div>
      </div>
  )
}

export default EditWidgetIndicator
