import '../styles/home.css'
import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate} from 'react-router-dom'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
import PreFooter from '../components/PreFooter'
import { Row, Col } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'
import Message from '../components/Message'
import { changePasswordForgot } from '../actions/userActions'
import axios from 'axios'



function ResetPasswordScreen() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {url_token} = useParams()

    const [registerPassword, setRegisterPassword] = useState('')
    const [registerPassword2, setRegisterPassword2] = useState('')

    const [passwordActive, setPasswordActive] = useState(false)
    const [password2Active, setPassword2Active] = useState(false)

    const[activeToken, setActiveToken] = useState(null)
    const forgotPasswordState = useSelector(state => state.changeForgotPasswordRequest)
    const {loading, error, success} = forgotPasswordState

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                };

                const response = await axios.get(`/api/user/reset-password/${url_token}`, config);
                const data = response.data;
                setActiveToken(true)
            } catch (error) {
                setActiveToken(false)
            }
        };

        fetchData(); // Llamada a la función asíncrona
    }, [url_token]); // Asegúrate de que el efecto dependa de `url_token`

    const changePasswordHandler = () => {
        dispatch(changePasswordForgot(registerPassword, url_token))

    }

    const keyPressHandler = (event) => {
        if(event.key == 'Enter'){
            changePasswordHandler()
        }
    }

    const navigateHome = () => {
        navigate('/')
      }

    return (
        <div>
          {/* ########### HEADER ######### */}
            <Header2 />
            <div>
            
                
                <div className='d-flex justify-content-center'>
                    <span></span>
                    {activeToken ? (
                        <h1 className='normal-title text-center py-5'>Reset Password</h1>
                    ): (
                        <h1 className='normal-title text-center pt-5'>Token already used or expired!</h1>
                    )}
                </div>
                


                <div className='pb-5'>
                    <Row className='px-5'>
                        <Col style={{borderRight: '1px solid #EAEAEA'}}/>
                        <Col style={{borderRight: '1px solid #EAEAEA'}}>
                            {error && (
                                <Message variant={'danger'}>
                                    {error}
                                </Message>
                            )}
                            {success && (
                                <Message variant={'success'}>
                                    {'Password changed successfully, you can close this window now.'}
                                </Message>
                            )}

                            {loading && (
                                <div className='d-flex justify-content-center align-items-center h-100 mx-5 px-5'>
                                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                                </div>
                            )}
                            {!success && activeToken && (
                                <>

                                    <div className='py-3'>
                                        <p className='login-label'>New Password:</p>
                                        <div className='login-input'>
                                            <i style={{color: '#898989'}} className="fa-solid fa-key me-2"></i>
                                            <input style={{width: '100%'}} onFocus={e => setPasswordActive(true)} onBlur={e => setPasswordActive(false)} value={registerPassword} onChange={e => setRegisterPassword(e.target.value)} type="password" placeholder='Your password' />
                                        </div>
                                        {passwordActive && (
                                            <>
                                                <div className='login-validator-bar mt-2'>
                                                    <div style={{width:
                                                        (registerPassword.length >= 8 && /[A-Z]/.test(registerPassword) && /\d/.test(registerPassword))  ? '100%':
                                                        (registerPassword.length >= 8 && /[A-Z]/.test(registerPassword)) ? '66%':
                                                        (registerPassword.length >= 8 && /\d/.test(registerPassword)) ? '66%':
                                                        ((/[A-Z]/.test(registerPassword)) && /\d/.test(registerPassword)) ? '66%':
                                                        (registerPassword.length >= 8) ? '33%':
                                                        (/[A-Z]/.test(registerPassword)) ? '33%':
                                                        (/\d/.test(registerPassword)) ? '33%': '0'

                                                    }}></div>
                                                </div>
                                                <p className={`login-validator ${(registerPassword.length >= 8) ? 'achieved': ''}`}>At least 8 characters</p>
                                                <p className={`login-validator ${(/[A-Z]/.test(registerPassword)) ? 'achieved': ''}`}>At least one capital letter</p>
                                                <p className={`login-validator ${(/\d/.test(registerPassword)) ? 'achieved': ''}`}>At least one number</p>
                                            </>
                                        )}
                                    </div>

                                    <div className='py-3'>
                                        <p className='login-label'>Confirm new Password:</p>
                                        <div className='login-input'>
                                            <i style={{color: '#898989'}} className="fa-solid fa-key me-2"></i>
                                            <input style={{width: '100%'}} onFocus={e => setPassword2Active(true)} onBlur={e => setPassword2Active(false)} onKeyDown={keyPressHandler} value={registerPassword2} onChange={e => setRegisterPassword2(e.target.value)} type="password" placeholder='Your password again' />
                                        </div>
                                        {password2Active && registerPassword != registerPassword2 && (
                                                <p className={`login-validator text-danger`}>Passwords do not match</p>
                                        )}
                                        {password2Active && registerPassword == registerPassword2 &&  registerPassword.length > 0 && (
                                                <p className={`login-validator text-success`}>Passwords match</p>
                                        )}
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                    <div onClick={changePasswordHandler} className={`login-button mt-5 ${
                                        (
                                            registerPassword.length >= 8 &&
                                            (/[A-Z]/.test(registerPassword)) &&
                                            /\d/.test(registerPassword) &&
                                            registerPassword == registerPassword2
                                        ) ? '':
                                        'button-disabled'
                                    }`}>Change Password</div>
                                    </div>

                                </>
                            )}


                        </Col>
                        <Col/>
                    </Row>
                </div>

            </div>
            <PreFooter />
            <Footer />
        </div>
    )
}

export default ResetPasswordScreen