import React from 'react'
import { Row, Col } from 'react-bootstrap'
import '../styles/general.css'

function Footer() {
  return (
    <div>
        {/* ########### PRE FOOTER CARD ######### */}
      <div className='py-5' style={{backgroundColor: '#E7E7E7'}}>
        <div className='structure-container py-5'>
          <Row className='m-0'>
            <Col md={4} className='pre-footer-nav align-items-center px-5'>
            <h1 className='normal-title fs-3'>Resources</h1>
              <a href="">Contact us</a>
              <a href="https://dev.socrata.com/foundry/mydata.iadb.org/tbnd-z94f" target='_blank'>Web API</a>
              <a href="https://mydata.iadb.org/api/views/tbnd-z94f/rows.csv?accessType=DOWNLOAD" target='_blank'>Download CSV</a>
              <a href="https://mydata.iadb.org/Indicator-Catalog/Latin-Macro-Watch-Dataset/tbnd-z94f/about_data" target='_blank'>IDB My Data</a>
            </Col>
            <Col md={8} className='pre-footer-nav p-5'>
              <h1 className='normal-title fs-3'>For citation use:</h1>
              <p>Inter-American Development Bank 2022. Latin American Watch Dataset. Washington, DC: Inter- American Development Bank. Department of Research and Chief Economist. Distributed by Washington, DC: Inter-American Development Bank Numbers for Development.<a style={{overflowWrap: 'break-word'}} href="https://mydata.iadb.org/Economics/Latin-Macro-Watch-Dataset/tbnd-z94f" target='_blank'>https://mydata.iadb.org/Economics/Latin-Macro-Watch-Dataset/tbnd-z94f</a></p>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

    

export default Footer
