import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../styles/modals.css'
import Message from './Message'
import { Spinner } from 'react-bootstrap'
import { updateDashboardTitle } from '../actions/platformActions'

function EditTitleDashboardModal({title, closeCallback, id}) {
  
    const dispatch = useDispatch()
    const [titleValue, setTitleValue] = useState(title)


  const updateTitleHandler = () => {
    // console.log('id: ', id)
    // console.log('title: ', titleValue)
    dispatch(updateDashboardTitle(id, titleValue))
  }

  const updateInfo = useSelector(state => state.updateDashboardTitle)
  const {loading, error, name} = updateInfo

  useEffect(() => {
    if(name){
        closeCallback(true)
    }
  }, [updateInfo])

  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

          <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Edit Title</h1>

           <p className='p-paragraph text-center'>Update or change the existing title of the dashboard</p>
            
            <div className='d-flex flex-column w-100'>
                <span className='p-label-2'>Title:</span>
                <input value={titleValue} onChange={(e) => setTitleValue(e.target.value)} className='p-input my-2' placeholder='Enter title' type="text" />
            </div>
            {loading ? (
                <div style={{width: '100%'}} className='login-button mt-3'>
                    <Spinner variant='light' animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>    
            ): error ? (
                <>
                    <Message variant={'danger'}>{error}</Message>
                    <div onClick={updateTitleHandler} style={{width: '100%'}} className='login-button mt-3'>Update Title</div>
                </>
            ): (
                <div onClick={updateTitleHandler} style={{width: '100%'}} className='login-button mt-3'>Update Title</div>
            )}
              
        </div>
      </div>
  )
}

export default EditTitleDashboardModal
