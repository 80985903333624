import '../styles/modals.css'
import React, { useEffect, useState } from 'react'


function MessageModal({title, message, closeCallback}) {


  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

            <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>{title}</h1>

           <p className='p-paragraph text-center'>
                {message}
            </p>           
            
            {/* <div  style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div> */}
              
        </div>
      </div>
  )
}

export default MessageModal
