import React, {useEffect, useState} from 'react'
import { roundy } from './Utilities'
import Chart from 'react-apexcharts';

function SeriesWidget({editFlag, data,  wid, openCallback, deleteCallback}) {

  const [xdata, setXdata] = useState([])
  const [ydata, setYdata] = useState([])
  const [xAnnotation, setXannotation] = useState([])
  const [yAnnotation, setYannotation] = useState([])

  const [colors, setColors] = useState([])
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')

  //   let chartOptions = {}
  const chartOptions = {
        chart: {
            id: 'basic-line-chart',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        title: {
            text: title,
            align: 'center',
            margin: 0,
            // offsetX: 0,
            // offsetY: 0,
            // floating: true,
            style: {
                fontSize:  '17px',
                fontWeight:  'bold',
                fontFamily:  'Open Sans',
                color:  '#333333'
            },
        },
        subtitle: {
            text: subtitle,
            align: 'center',
            margin: 0,
            // offsetX: 0,
            // offsetY: 0,
            // floating: true,
            style: {
              fontSize:  '15px',
              fontWeight:  'regular',
              fontFamily:  'Open Sans',
              color:  '#949494'
            },
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '100%',
                barHeight: '100%',
                borderRadius: 1,


            },
            area: {
                fillTo: 'origin'
            },
            line: {
                isSloperChart: true,
            }
        },
        xaxis: {
            labels: {
                rotate: 0,
                rotateAlways: true,
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                }
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 6,
            // type: 'datetime',
            categories: xdata.map(item => item.split('-')[0] + ' - ' + item.split('-')[1]),
        },
        yaxis: {
            title: {
                text: data.unit,
                style: {
                    // color: '#565656',
                    // fontSize: '12px',
                    // fontFamily: 'Open Sans',
                    // // fontWeigth: 'normal'
                    cssClass: 'tester'
                }
            },
            labels: {
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                },
                formatter: function(value) {
                    return roundy(value, 2)
                }
            }
        },
        fill: {
            opacity: 0.2,
            type: ydata.map(item => item.type == 'area' ? 'solid': 'gradient'),
            gradient: {
                opacityFrom: 1,
                shadeIntensity: 0,
            }
        },
        grid:
        {
            show: false,
        },
        legend: {
            show: true,
            position: 'top',
            // horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeigth: '300',
            floating: true,
            offsetY: -20,
            labels: {
                colors: ['#333333']
            }
        },
        annotations: {
            yaxis: yAnnotation,
            xaxis: xAnnotation
        }
    }
  


    const series = (ydata.length > 0) ? ydata : [{name: '', data: []}]

    useEffect(() => {
      

        if(Object.keys(data).length > 0 && data.country_list.length > 0){
            setColors(data.country_list.map(item => item.color))
            setTitle(data.series_name)
            setSubtitle(data.indicator)
            
            let x = []
            data.country_list.forEach(item => {
                let xtemp = []
                // console.log('temItem: ', item)
                if(item.countryType == 'country'){
                    // if(!data?.data_dict.Countries[item.value]?.data){
                    //     return
                    // }
                    xtemp = data.data.filter(element => element.country == item.country).map(element => element.date)
                    // xtemp = data?.data_dict.Countries[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                }
                // else if(item.countryType == 'region'){
                //     if(!data?.data_dict.Regions[item.value]?.data){
                //         return
                //     }
                //     xtemp = data?.data_dict.Regions[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                // }
                // console.log('xtemp: ', xtemp)
                // if(xtemp){
                x = [...x, ...xtemp]
                // }
            })
            x = [...new Set(x)].sort()
        
            setXdata(x)
        
            let y = data.country_list.map(item => {
                return {
                    name: item.country,
                    data: x.map(e =>
                            (data.data.filter(a => a.country == item.country).find(b => b.date == e)) ?
                            (data.data.filter(a => a.country == item.country).find(b => b.date == e)).value :
                                null
                            ),
                    type: item.type,
                    color: item.color,
                }
            })
            setYdata(y)
        
            let xanntemp = data.xaxis.filter(item => item.value != '').map(item => {
                return {
                    x: item.value.split('-')[0] + ' - ' + item.value.split('-')[1],
                    // x2: '2022 - 01',
        
                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1
                }
            })
        
            let highlighttemp = data.highlight.filter(item => item.x1.value != '' && item.x2.value != '').map(item => {
                return {
                    x: item.x1.value.split('-')[0] + ' - ' + item.x1.value.split('-')[1],
                    x2: item.x2.value.split('-')[0] + ' - ' + item.x2.value.split('-')[1],
                    // x2: '2022 - 01',
        
                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 0.5
                }
            })
        
            // let d =  [...xanntemp]
            let d =  [...xanntemp, ...highlighttemp]
            setXannotation(d)
        
            let yanntemp = data.yaxis.filter(item => item.value != '').map(item => {
                return {
                    y: item.value,
                    // x2: '2022 - 01',
                    
                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1,
                    label: {
                        text: item.text,
                        position: 'left',
                        offsetX: 25,
                        style: {
                            background: '#2aac9c',
                            color: '#ffffff',
                            fontFamily: 'Open Sans',
                        }
                    }
                }
            })
        
        //     let metrictemp = metricValue.filter(item => item.value != '').map(item => {
        
        //         let met = 0
        //         countryValue.filter(e => e.value != '').forEach(country => {
        //             if(country.countryType == 'country'){
        //                 let gg = data.data_dict.Countries[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
        //                 met += parseFloat(gg)
        //             }
        //             else if(country.countryType == 'region'){
        //                 let gg = data.data_dict.Regions[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
        //                 met += parseFloat(gg)
        //             }
        //         })
        //         // console.log('met', met)
        
        //         let metricY = null
        //         if(met){   
        //             metricY = met / countryValue.filter(e => e.value != '').length
        //         }
        
        //         if(metricY){
        
        //             return {
        //                 y: metricY,
        //                 strokeDashArray: 1,
        //                 borderColor: '#333333',
        //                 fillColor: '#333333',
        //                 opacity: 1,
        //                 label: {
        //                     text: item.label,
        //                     style: {
        //                         background: '#577bab',
        //                         color: '#ffffff',
        //                         fontFamily: 'Open Sans',
        //                         // padding: {
        //                         //     top: 2,
        //                         //     bottom: 5,
        //                         // }
        //                     }
        //                 }
        //             }
        //         }
        //     })
            let yfinal =  [...yanntemp]
        //     let yfinal =  [...yanntemp, ...metrictemp]
        
            setYannotation(yfinal)
        }
    }, [])


  return (
    <div className='dwidget-container'>
        <div style={{position: 'relative'}} className='h-100'>
            {editFlag && (
                <div style={{position: 'absolute', right: '0', zIndex:'1000'}}>
                    <i style={{color: '#808080', cursor: 'pointer'}} className="fa-solid fa-arrows-rotate pe-2"></i>
                    <i onMouseDown={e => openCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square ps-1"></i>
                    <i onMouseDown={e => deleteCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-circle-xmark px-2 pt-2"></i>
                </div>
            )}
            <div style={{boxSizing: 'border-box', paddingTop: '1em'}} className='d-flex justify-content-center align-items-center h-100'>
                <div className='px-4 h-100 w-100 d-flex justify-content-center align-items-center' style={{flex: '1'}}>
                    {Object.keys(data).length == 0 ? (
                        <div onMouseDown={e => openCallback(wid)} style={{color: '#8b8b8b', cursor: 'pointer'}} className='text-center'>
                            Click edit button <br />
                            to edit the series
                            
                            {/* {editFlag && (
                                <i onMouseDown={e => openCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                            )} */}
                        </div>
                    ): (
                        <Chart options={chartOptions} series={series} className={'pt-2'} style={{flex: '1'}} type="line" width={'100%'} height={'100%'}  />
                    )}
                </div>
            </div>
        </div>
        {/* <div className='d-flex justify-content-between'>
            <div className='flex-grow-1 pt-4'>
                <p className='dwidget-chart-title'>{data.series_name}</p>
                <p className='dwidget-chart-subtitle'>{data.frequency}</p>
            </div>
            <div>
                <i style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                <i style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-circle-xmark px-2 pt-2"></i>
            </div>
        </div>  
        <div className='px-4' style={{flex: '1'}}>
            <Chart options={chartOptions} series={series} type="line" width={'100%'} height={'100%'}  />
        </div> */}
      
    </div>
  )
}

export default SeriesWidget
