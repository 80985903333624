import { 
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,

    SAVE_SERIES_REQUEST,
    SAVE_SERIES_SUCCESS,
    SAVE_SERIES_FAIL,
    SAVE_SERIES_RESET,

    UPDATE_SERIES_REQUEST,
    UPDATE_SERIES_SUCCESS,
    UPDATE_SERIES_FAIL,
    UPDATE_SERIES_RESET,
 } from '../constants/viewerConstants'

 export const dataListReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_DATA_REQUEST:
            return { loading: true }

        case GET_DATA_SUCCESS:
            return { loading: false, data: action.payload }
        
        case GET_DATA_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const saveSeriesRecucer = (state = {}, action) => {
    switch(action.type) {
        case SAVE_SERIES_REQUEST:
            return { loading: true }

        case SAVE_SERIES_SUCCESS:
            return { loading: false, success: true }
        
        case SAVE_SERIES_FAIL:
            return { loading: false, error: action.payload }

        case SAVE_SERIES_RESET:
            return {}

        default:
            return state
    }
 }

 export const updateSeriesReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_SERIES_REQUEST:
            return { loading: true }

        case UPDATE_SERIES_SUCCESS:
            return { loading: false, success: true }
        
        case UPDATE_SERIES_FAIL:
            return { loading: false, error: action.payload }

        case UPDATE_SERIES_RESET:
            return {}

        default:
            return state
    }
 }