import '../styles/home.css'
import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Dropdown, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { copyText } from '../components/Utilities'
import Select from 'react-select'

import { LMWDataGet } from "../components/landing/LMWData"
import Footer from '../components/Footer'
import PreFooter from '../components/PreFooter'
import  LoginModal from "../components/LoginModal"
import  RegisterModal from "../components/RegisterModal"
import  ForgotModal from "../components/ForgotModal"
import {
  logout
} from '../actions/userActions'
import {
  CHANGE_FORGOT_PASSWORD_RESET,
} from '../constants/userConstants'

import {
  getAreas,
  getIndicators,
  getGlobalTopSearch,
} from '../actions/searchActions'
import { GOOGLE_RESET } from '../constants/userConstants'


function MainScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // forgot values
  const [forgotEmail, setForgotEmail] = useState('')

  // modal windows
  const [loginModal, setLoginModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [forgotModal, setForgotModal] = useState(false)

  const [indicatorFiltered, setIndicatorFiltered] = useState([])

  const [show, setShow] = useState(false)

  const [areaValue, setAreaValue] = useState({value: null, label: 'All Categories'})
  const [topicValue, setTopicValue] = useState(null)
  const [indicatorValue, setIndicatorValue] = useState(null)

  const userLogin = useSelector(state => state.userLogin)
  const {loading: loadingUser, error: errorUser, userInfo} = userLogin

  const areaInfo = useSelector(state => state.areaList)
  const { loading, error, areaList } = areaInfo

  useEffect(() => {
    dispatch(getAreas())
    dispatch(getIndicators())
    dispatch(getGlobalTopSearch())
  },[])

  const indicatorInfo = useSelector(state => state.indicatorList)
  const { loading: loadingIndicator , error: errorIndicator, indicatorList } = indicatorInfo

  useEffect(() => {
    const filterList = indicatorList
    if(indicatorList && indicatorList.length > 0){
      setIndicatorFiltered([...filterList].sort((a,b) => a.name.localeCompare(b.name)))
    }
  }, [indicatorList])


  const googleInfo = useSelector(state => state.googleLogin)
  const {success} = googleInfo

  const globalInfo = useSelector(state => state.globalTopSearch)
  const {loading: loadingGlobal, error: errorGlobal, list} = globalInfo

  useEffect(() => {
    if(success){
      dispatch({
        type: GOOGLE_RESET
      })
      navigate('/home')
    }
  }, [googleInfo])

  const indicatorOptions = indicatorFiltered?.map(item => {
    return {value: item.id, label: item.name, area: item.foreign_area, topic: item.foreign_topic}
  })



  const handleToggle = (isOpen) => {
    setShow(isOpen);
  };

  const openModal = (type) => {
    if(type === 'login'){
      setLoginModal(true)
    }
    else if( type === 'register'){
      setRegisterModal(true)
    }
    else if( type === 'forgot'){
      setForgotModal(true)
    }
  }

  const closeModal = (type) => {
    if(type === 'login'){
      setLoginModal(false)
    }
    else if(type === 'register'){
      setRegisterModal(false)
    }
    else if(type === 'forgot'){
      setForgotModal(false)
      setForgotEmail('')
    }
  }

  const forgotHandler = () => {
    console.log("forgot handler")
  }

  const changeCategoryHandler = (item) => {
    setAreaValue({value: item.id, label: item.beauty_name})
    if(item.id){
      const filterList = [...indicatorList].filter(element => element.foreign_area === item.id)
      setIndicatorFiltered([...filterList].sort((a,b) => a.name.localeCompare(b.name)))
    }else{
      const filterList = indicatorList
      setIndicatorFiltered([...filterList].sort((a,b) => a.name.localeCompare(b.name)))
    }
    setIndicatorValue(null)
  }

  const changeIndicatorHandler = (option) => {
    setIndicatorValue(option)
    setTopicValue(option.topic)
    if(option.area !== areaValue.id) {
      const areaItem = areaList.find(item => item.id === option.area)
      setAreaValue({value: areaItem.id, label: areaItem.beauty_name})
      const filterList = [...indicatorList].filter(element => element.foreign_area === areaItem.id)
      setIndicatorFiltered([...filterList].sort((a,b) => a.name.localeCompare(b.name)))
    }
  }

  const searchHandler = () => {
    const area = (areaValue.value ? areaValue.value: '')
    const topic = (topicValue ? topicValue: '')
    const indicator = (indicatorValue ? indicatorValue.value: '')
    // console.log('area: ', area)
    // console.log('topic: ', topic)
    // console.log('indicator: ', indicator)
    navigate(`/search?area=${encodeURIComponent(area)}&topic=${encodeURIComponent(topic)}&indicator=${encodeURIComponent(indicator)}`);
  }
  const enterHandler = (event) => {
      if(event.key === 'Enter'){
        searchHandler()
      }
  }

  const logoutHandler = () => {
    dispatch(logout(userInfo.refresh))
  }

  const platformHandler = () => {
    navigate('/home')
  }

  const areaSearchHandler = (id) => {
    navigate(`/search?area=${id}`)
  }

  const trendHandler = (item) => {
    var area = item.area_id
    var topic = item.topic_id
    var indicator = item.indicator_id
    navigate(`/search?area=${encodeURIComponent(area)}&topic=${encodeURIComponent(topic)}&indicator=${encodeURIComponent(indicator)}`);
  }


  return (
    <div>
      {/* ########### HEADER ######### */}
      <div className='header'style={{filter: 'none'}}>
        <div className='nav-bar'>
          <img src="/static/idb-white.svg" style={{height: '50px', width: 'auto'}} alt="" />

          <div className='d-flex align-items-center'>
            <div className='d-flex nav-items'>
              <a className='text-white api-link' href="https://dev.socrata.com/foundry/mydata.iadb.org/tbnd-z94f" target='_blank' rel='noreferrer'>Web API</a>
              {loadingUser ? (
                <Spinner animation="border" variant='light' as={'div'} style={{width: '20px', height: '20px'}}/>
              ) : userInfo ? (
                <Nav className='home-account'>
                    {userInfo && (
                        <NavDropdown className='no-chevron' menuVariant='dark' title={userInfo.name} id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={platformHandler} style={{fontSize: '16px'}} className='text-white'>
                            Account
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={logoutHandler} style={{fontSize: '16px'}} className='text-white'>
                            Sign Out
                          </NavDropdown.Item>
                        </NavDropdown>
                    )}
                </Nav>
              ) : (
                <span onClick={e => openModal('login')} className='text-white p-0' href="">
                  <span className='home-login'>Log In</span>
                  <i className="fa-solid fa-user home-login-responsive text-white"></i>
                </span>
              )
              }

              {/* <span className='text-white' href="">{userInfo.name}</span> */}


            </div>
            <div className='main-title header-title'>
              LMW-FISLAC
            </div>
          </div>
        </div>

        <div className='container d-flex flex-column'>
          <div className='my-5'>
            <h1 className='bold-title'>
              LATIN MACRO WATCH - FISLAC
            </h1>
            <p className='main-subtitle'>
              The Macro-Financial Compass for Latin America and the Caribbean
            </p>
          </div>

          <div className='my-5 header-buttons'>
            <button onClick={e => openModal('register')} className='main-button'>Create an Account</button>
            <LinkContainer to='/search'>
              <button className='main-button bg-transparent text-white ms-4'>Explore</button>
            </LinkContainer>
          </div>

          <div className='my-5'>
            <div className='header-tag'>
              <span style={{fontWeight: '500', color: '#fff'}}>Trending: </span>
              {loadingGlobal && (
                <Spinner animation="border" variant='light' as={'div'} style={{width: '20px', height: '20px'}}/>
              )}
              {list && list.slice(0,5).map((item, index) => (
                <span key={index}>
                  <span onClick={e => trendHandler(item)} className='home-search-trend'>{item.indicator}</span>
                  <span className='text-white'>{(index <= 3) && ', '}</span>
                </span>
              ))}
            </div>

            <div className='header-input'>
              <Dropdown style={{height: '100%'}}>
                <Dropdown.Toggle className='header-input-button'>
                  {areaValue.label}
                  <i className="ms-3 fa-solid fa-chevron-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{fontSize: '14px'}}>
                  <Dropdown.Item  onClick={e => setAreaValue({id: null, label: 'All Categories'})}>
                    All Categories
                  </Dropdown.Item>
                  {areaList?.map(item => (
                    <Dropdown.Item key={item.id} onClick={e => changeCategoryHandler(item)}>
                      {item.beauty_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div className='header-input-text'>
                {/* <input type="text" placeholder='Search from more than 7800 Series' /> */}
                <Select
                    className='home-select'
                    classNamePrefix="home-select"
                    options={indicatorOptions}
                    placeholder={'Search from more than 7800 Series'}
                    value={indicatorValue}
                    onChange={option => changeIndicatorHandler(option)}
                    onKeyDown={enterHandler}
                  />
              </div>
              <div className='header-input-icon'>
                <i onClick={searchHandler} style={{cursor: 'pointer'}} className="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>

          </div>

        </div>
      </div>

      {/* ########### TRANSFORMATION CARD ######### */}
      <div>
        <div className='container py-5'>
          <Row className='py-5'>
            <Col md={6} sm={12} className='trans-card text-center justify-content-center'>
              <h1 className='normal-title'>Transforming <br />Macro-Financial <br /> Decision Making</h1>
            </Col>
            <Col md={6} sm={12} className='trans-card'>
              <p className='normal-p'>The <b>Latin Macro Watch - FISLAC</b>, developed by the IDB, equips policymakers in Latin America and the Caribbean with a <b>comprehensive toolkit</b> for advanced macro-financial analysis and strategic decision making</p>
            </Col>
          </Row>
        </div>
      </div>


      {/* ########### WHY CARD ######### */}
      <div className='py-5' style={{backgroundColor: '#F1F5FF'}}>
        <div className='container'>
          <h1 className='normal-title text-center py-5'>Why LMW-FISLAC?</h1>
          <Row>
            <Col>
              <div className='why-content'>
                <div><img src="/static/traceable.svg" alt='' /></div>
                <div>
                  <h3>Traceable</h3>
                  <p>Track data origins and changes with clear documentation.</p>
                </div>
              </div>
            </Col>
            <Col>
            <div className='why-content'>
                <div><img src="/static/dynamic.svg" alt='' /></div>
                <div>
                  <h3>Dynamic</h3>
                  <p>Get real-time updates and visualizations for informed decisions.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='why-content'>
                <div><img src="/static/expandable.svg" alt='' /></div>
                <div>
                  <h3>Expandable</h3>
                  <p>Easily integrate additional data sources to expand your analysis.</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='why-content'>
                <div className='px-3'><img src="/static/low-cost-2.svg" alt='' /></div>
                <div>
                  <h3>Accessible</h3>
                  <p>Access advanced tools for macro-financial analysis.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex justify-content-center'>
              <div className='why-content'>
                <div><img src="/static/flexible.svg" alt='' /></div>
                <div>
                  <h3>Flexible</h3>
                  <p>Adapt the platform to various analytical approaches and needs.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>


      {/* ########### ABOUT CARD ######### */}

      <div className='container py-5'>
          <h1 className='normal-title text-center py-5'>About the dataset</h1>
          <p className='text-center'>The Latin Macro Watch (LMW) is a <b>macroeconomic and financial database</b> that offers indicators on economic activity, fiscal and external accounts, financial markets, and forecasts. Historical data at monthly, quarterly, and annual frequencies will be available starting in 1990 and cover the 26 borrowing members of the Inter-American Development Bank. </p>
          <LMWDataGet />
      </div>

      {/* ########### STRUCTURE CARD ######### */}
      <div className='pb-5'>
        <div className='structure-container pb-5'>
          <h1 className='normal-title text-center py-5 fs-2'>LMW Structure: Six different data aggrupations</h1>
          <Row className='p-4 m-0'>
            <Col>
              <div onClick={e => areaSearchHandler(6)} className='structure-content'>
                <img src="/static/archive.svg" alt=''/>
                <div>
                  <h1 className='normal-title fs-4'>Economic Activity and Employment</h1>
                  <p>
                  National accounts, production and employment
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              
            <div onClick={e => areaSearchHandler(5)} className='structure-content'>
              <img src="/static/shopping-cart.svg" alt=''/>
              <div>
                <h1 className='normal-title fs-4'>Prices and Wages</h1>
                <p>
                  CPI, WPI, RER, import and export price index wage
                </p>
              </div>
            </div>
            
            </Col>
            <Col>
              <div onClick={e => areaSearchHandler(3)} className='structure-content'>
                <img src="/static/activity.svg" alt=''/>
                <div>
                  <h1 className='normal-title fs-4'>Fiscal Accounts and Public Debt</h1>
                  <p>
                    Public debt, revenues and expenditure
                  </p>
                </div>
              </div></Col>
          </Row>


          <Row className='p-4 m-0'>
            <Col>
              <div onClick={e => areaSearchHandler(2)} className='structure-content'>
                <img src="/static/truck.svg" alt=''/>
                <div>
                  <h1 className='normal-title fs-4'>External Accounts</h1>
                  <p>
                    Current account, Balance of payments, external debt
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              <div onClick={e => areaSearchHandler(4)} className='structure-content'>
                <img src="/static/dollar-sign.svg" alt=''/>
                <div>
                  <h1 className='normal-title fs-4'>Money and Banking</h1>
                  <p>
                    Money aggregates, deposits and credits
                  </p>
                </div>
              </div></Col>
            <Col>
              <div onClick={e => areaSearchHandler(1)} className='structure-content'>
                <img src="/static/trending-up.svg" alt=''/>
                <div>
                  <h1 className='normal-title fs-4'>Financial Markets</h1>
                  <p>
                    Interest rates and stock market index
                  </p>
                </div>
              </div></Col>
          </Row>
        </div>
      </div>

      {/* ########### API CARD ######### */}
      <div className='api-card py-5 mt-5'>
        <div className='structure-container py-5'>
          <Row className='px-4 m-0'>
            <Col className='d-flex flex-column justify-content-center p-0'>
              <h1 className='normal-title text-center'>LMW API</h1>
              <div className='pt-4'>
                <p className='text-center px-3'>The Socrata Open Data Access (SODA) API provides access to the LMW dataset, including the ability to filter, query and aggregate data. For additional information, please refer to the API documents.</p>
                <a className='d-flex justify-content-center' href='https://dev.socrata.com/foundry/mydata.iadb.org/tbnd-z94f' target='_blank' rel='noreferrer'>
                  <button className='main-button text-white' style={{backgroundColor: '#212529'}}>API Documentation</button>
                </a>
              </div>

              <div className='py-5'>
                <p className='px-2 m-0' style={{fontSize: '16px'}}>Try it now (API endpoint):</p>
                <div className='text-code'>
                  <i onClick={() => copyText('api-text')} style={{cursor: 'pointer'}} className="fa-regular fa-clone"></i>
                  &nbsp; <span id='api-text' style={{textDecoration: 'underline'}}>https://mydata.iadb.org/resource/tbnd-z94f.json?lmwuuid=10204011515A0120011231</span>
                </div>
              </div>
            </Col>
            <Col  className='api-image p-0 d-flex align-items-center'>
              <img src="/static/api-code-box-answ.png" alt=''/>
            </Col>
          </Row>
        </div>
      </div>
      

      <PreFooter />
      <Footer />


      {loginModal && (
        <LoginModal
          closeCallback={() => {setLoginModal(false)}}
          registerCallback={e => {setLoginModal(false); setRegisterModal(true)}}
          forgotCallback={e => {
            dispatch({
              type: CHANGE_FORGOT_PASSWORD_RESET,
            });
            setLoginModal(false);
            setForgotModal(true)
          }} />
      )}

      {registerModal && (
        <RegisterModal closeCallback={() => {setRegisterModal(false)}} loginCallback={e => {setRegisterModal(false); setLoginModal(true)}} />
      )}


      {forgotModal && (
        <ForgotModal closeCallback={() => {setForgotModal(false)}} />
      )}

    </div>
  )
}

export default MainScreen
