import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { register } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import GooglePopup from '../components/GooglePopup'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import '../styles/modals.css'

function RegisterModal({closeCallback, loginCallback}) {

    const dispatch = useDispatch()

    const [registerName, setRegisterName] = useState('')
    const [registerEmail, setRegisterEmail] = useState('')
    const [registerPassword, setRegisterPassword] = useState('')
    const [registerPassword2, setRegisterPassword2] = useState('')

    const [emailActive, setEmailActive] = useState(false)
    const [passwordActive, setPasswordActive] = useState(false)
    const [password2Active, setPassword2Active] = useState(false)

    const userRegister = useSelector(state => state.userRegister)
    const {loading, error, success} = userRegister

    useEffect(() => {
        setRegisterName('')
        setRegisterEmail('')
        setRegisterPassword('')
        setRegisterPassword2('')
    }, [userRegister])

    const registerHandler = () => {
        dispatch(register(registerName, registerEmail, registerPassword))
    }

    const keyPressHandler = (event) => {
        if(event.key == 'Enter'){
            registerHandler()
        }
    }

    function openGoogleAuthPopup() {
        // Abrir la ventana emergente para la autenticación de Google
        const width = 500;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        window.location.href = `${window.location.origin}/api/user/accounts/google/login/`;

        // const popup = window.open(
        //   'http://127.0.0.1:8000/api/user/accounts/google/login/',
        //   'google-auth',
        //   `width=${width},height=${height},top=${top},left=${left}`
        // );

        // if (!popup) {
        //   alert("La ventana emergente ha sido bloqueada por el navegador.");
        //   return;
        // }

    }


  return (
        <div className='modal-container'>
            <div className='modal-window'>
                <div className='login-header'>
                <div className='d-flex justify-content-between'>
                    <span></span>
                <h1 className='normal-title text-center text-white py-5'>Create Account</h1>
                <i onClick={e => closeCallback()} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-white p-3 fs-4"></i>
                </div>
                </div>


                <div className='pb-5'>

                <p className='login-signup py-3'>Already have an account? <span onClick={loginCallback}>Log in</span></p>

                <Row className='px-5'>
                    <Col style={{borderRight: '1px solid #EAEAEA'}}>
                        {error && (
                            <Message variant={'danger'}>
                                {error}
                            </Message>
                        )}
                        {success && (
                            <Message variant={'success'}>
                                {'Account successfully created, please check your email inbox to activate it.'}
                            </Message>
                        )}

                        {loading ? (
                            <div className='d-flex justify-content-center align-items-center h-100 mx-5 px-5'>
                                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                            </div>
                        ): (
                            <>
                                <div className='py-3'>
                                    <p className='login-label'>Name:</p>
                                    <div className='login-input'>
                                    <i style={{color: '#898989'}} className="fa-solid fa-user me-2"></i>
                                    <input value={registerName} onChange={e => setRegisterName(e.target.value)} type="text" placeholder='Your name' />
                                    </div>
                                </div>

                                <div className='py-3'>
                                    <p className='login-label'>E-mail:</p>
                                    <div className='login-input'>
                                        <i style={{color: '#898989'}} className="fa-solid fa-user me-2"></i>
                                        <input onFocus={e => setEmailActive(true)} onBlur={e => setEmailActive(false)} value={registerEmail} onChange={e => setRegisterEmail(e.target.value)} type="text" placeholder='Your email' />
                                    </div>
                                    {emailActive && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(registerEmail) && (
                                            <p className={`login-validator text-success`}>Valid email</p>
                                    )}
                                    {emailActive && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(registerEmail) && registerEmail.length > 0 && (
                                            <p className={`login-validator text-danger`}>Invalid email</p>
                                    )}
                                </div>

                                <div className='py-3'>
                                    <p className='login-label'>Password:</p>
                                    <div className='login-input'>
                                        <i style={{color: '#898989'}} className="fa-solid fa-key me-2"></i>
                                        <input onFocus={e => setPasswordActive(true)} onBlur={e => setPasswordActive(false)} value={registerPassword} onChange={e => setRegisterPassword(e.target.value)} type="password" placeholder='Your password' />
                                    </div>
                                    {passwordActive && (
                                        <>
                                            <div className='login-validator-bar mt-2'>
                                                <div style={{width:
                                                    (registerPassword.length >= 8 && /[A-Z]/.test(registerPassword) && /\d/.test(registerPassword))  ? '100%':
                                                    (registerPassword.length >= 8 && /[A-Z]/.test(registerPassword)) ? '66%':
                                                    (registerPassword.length >= 8 && /\d/.test(registerPassword)) ? '66%':
                                                    ((/[A-Z]/.test(registerPassword)) && /\d/.test(registerPassword)) ? '66%':
                                                    (registerPassword.length >= 8) ? '33%':
                                                    (/[A-Z]/.test(registerPassword)) ? '33%':
                                                    (/\d/.test(registerPassword)) ? '33%': '0'

                                                }}></div>
                                            </div>
                                            <p className={`login-validator ${(registerPassword.length >= 8) ? 'achieved': ''}`}>At least 8 characters</p>
                                            <p className={`login-validator ${(/[A-Z]/.test(registerPassword)) ? 'achieved': ''}`}>At least one capital letter</p>
                                            <p className={`login-validator ${(/\d/.test(registerPassword)) ? 'achieved': ''}`}>At least one number</p>
                                        </>
                                    )}
                                </div>

                                <div className='py-3'>
                                    <p className='login-label'>Repeat the Password:</p>
                                    <div className='login-input'>
                                        <i style={{color: '#898989'}} className="fa-solid fa-key me-2"></i>
                                        <input onFocus={e => setPassword2Active(true)} onBlur={e => setPassword2Active(false)} onKeyDown={keyPressHandler} value={registerPassword2} onChange={e => setRegisterPassword2(e.target.value)} type="password" placeholder='Your password again' />
                                    </div>
                                    {password2Active && registerPassword != registerPassword2 && (
                                            <p className={`login-validator text-danger`}>Passwords do not match</p>
                                    )}
                                    {password2Active && registerPassword == registerPassword2 &&  registerPassword.length > 0 && (
                                            <p className={`login-validator text-success`}>Passwords match</p>
                                    )}
                                </div>


                                <div onClick={registerHandler} className={`login-button mt-5 ${
                                    (
                                        registerPassword.length >= 8 &&
                                        (/[A-Z]/.test(registerPassword)) &&
                                        /\d/.test(registerPassword) &&
                                        registerPassword == registerPassword2 &&
                                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(registerEmail) &&
                                        registerName.length > 3
                                    ) ? '':
                                    'button-disabled'
                                }`}>Create Account</div>

                            </>
                        )}


                    </Col>
                    <Col className='d-flex align-items-center flex-column justify-content-center p-5'>
                    <p style={{color: '#8B8B8B'}}>Other sign up methods:</p>
                    {/* <div onClick={openGoogleAuthPopup} className='login-card button-disabled'> */}
                    <div onClick={openGoogleAuthPopup} className='login-card'>
                        <img src="/static/Google.svg"/>
                        <p className='p-1 text-nowrap'>Access with Google</p>
                    </div>
                    </Col>
                </Row>
                <p style={{textAlign: 'left'}} className='login-signup px-5 pt-4'>By providing your registration details, you acknowledge that you have read and understand the IDB - Latin Macro Watch Privacy Notice & Policy.</p>
                </div>
            </div>
        </div>
  )
}

export default RegisterModal
