import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import Select  from 'react-select'
import { Spinner } from 'react-bootstrap'
import {
    getFolders,
    getSeries,
    getSerieWidget,
} from '../actions/platformActions'
import { CREATE_FOLDER_RESET, CREATE_TRACKER_RESET } from '../constants/platformConstants'
import Message from './Message'

function EditWidgetSeries({closeCallback, updateCallback, wid}) {

    const dispatch = useDispatch()
    const [folderValue, setFolderValue] = useState(null)
    const [seriesValue, setSerieValue] = useState(null)
    const [folderOptions, setFolderOptions] = useState([])
    const [seriesOptions, setSeriesOptions] = useState([])

    useEffect(() => {
        // dispatch({
        //     type: CREATE_FOLDER_RESET
        // })
        dispatch(getFolders())
    }, [])

    const folderInfo = useSelector(state => state.listFolder)
    const {loading, error, folderList} = folderInfo

    useEffect(() => {
        if(folderList){
            setFolderOptions(folderList.map(item => {
                return {
                    value: item.id,
                    label: item.folder_name,
                }
            }))
        }
    }, [folderInfo])

    // const trackerInfo = useSelector(state => state.seriesTracker)
    // const {loading: loadingTracker, error: errorTracker, series} = trackerInfo

    const seriesInfo = useSelector(state => state.seriesList)
    const {loading: loadingSeries, error: errorSeries, listSeries} = seriesInfo

    useEffect(() => {
        if(listSeries){
            // console.log("listSeries: ", listSeries)
            setSeriesOptions(listSeries.map(item => {
                return {
                    value: item.id,
                    label: item.series_name
                }
            }))
        }
    }, [seriesInfo])


    const selectFolderHandler = (option) => {
        setFolderValue(option)
        dispatch(getSeries(option.value))
    }

    const selectSerieHandler = (option) => {
        setSerieValue(option)
        dispatch(getSerieWidget(option.value))
    }

    
    const getInfo = useSelector(state => state.serieWidget)
    const {loading: loadingGet, error: errorGet, result} = getInfo
    
    const addSerieHandler = () => {
        console.log("serieId: ", seriesValue.value)
        
    }
    // useEffect(() => {
    //     if(result){
    //         console.log("AY OAOIUT: ", result)
    //     }
    // }, [getInfo])

    const updateHandler = () => {
        const jsonContent = result[0]
        updateCallback(wid, jsonContent)
        closeCallback(true)
    }

  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

            <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Add Series</h1>

           <p className='p-paragraph text-center'>
                Select one of your favorite series to add it to the tracker
            </p>
        

            <span className='p-label-2 w-100'>Select a folder:</span>

            <Select 
                className="custom-select-container"
                classNamePrefix="custom-select"
                placeholder={'Select folders'}
                value={folderValue}
                options={folderOptions}
                onChange={option => selectFolderHandler(option)}
            />

            <span className='p-label-2 text-start w-100 pt-4'>Select one of you favorites:</span>
            <Select 
                className={`custom-select-container ${!folderValue ? 'button-disabled': ''} `}
                classNamePrefix="custom-select"
                placeholder={'Select folders'}
                value={seriesValue}
                options={seriesOptions}
                onChange={option => selectSerieHandler(option)}
            />

            <div onClick={updateHandler} style={{width: '100%'}} className={`login-button ${!result || !folderValue || !seriesValue ? 'button-disabled': ''} mt-3`}>
                {loadingGet ? (
                    <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                ): (
                    'Add Serie'
                )}
            </div>

            {/* {loadingCreate ? (
                <div style={{width: '100%'}} className='login-button mt-3'>
                    <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>
            ): (
                <div onClick={addSerieHandler} style={{width: '100%'}} className='login-button mt-3'>Add Serie</div>
            )}

            {error && (
                <Message variant={'danger'}>{error}</Message>
            )} */}
           
          
            
            {/* {loading ? (
              <div className='d-flex justify-content-center align-items-center pt-3'>
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
            ): error ? (
              <>
                <Message variant='danger'>{error}</Message>
                <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
              </>
            ): (
              <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
            )} */}

              
        </div>
      </div>
  )
}

export default EditWidgetSeries
