import '../styles/help.css'
import React from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import HelpCard from '../components/HelpCard'
import HelpQuestion from '../components/HelpQuestion'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import { Container } from 'react-bootstrap'

function HelpScreen() {

  const cardMeta = [
    {
      type: 'Favorites',
      date: 'Created on January 10 of 2024',
      title: 'How can I add a series to my favorites?',
      text: 'Learn how to quickly add your preferred series to your favorites for easy access.',
      icon: 'fa-solid fa-star'
    },
    {
      type: 'General',
      date: 'Created on June 12 of 2024',
      title: 'How can I download specific series?',
      text: 'Find out how to download the data series you need directly to your device.',
      icon: 'fa-solid fa-download'
    },
    {
      type: 'Dashboard',
      date: 'Created on April 2 of 2024',
      title: 'How to create a dashboard?',
      text: 'Step-by-step guide on building customized dashboards to visualize your data.',
      icon: 'fa-solid fa-chart-line'
    },
    {
      type: 'Favorites',
      date: 'Created on March 21 of 2024',
      title: 'How can I remove a series from a folder in Favorites?',
      text: 'Simple instructions to help you remove series from your favorites folder.',
      icon: 'fa-solid fa-folder-open'
    },
  ]

  const questionMeta = [
    {
      title: 'What is LMW-FISLAC?',
      text: 'LMW-FISLAC is a macroeconomic and financial database developed by the Inter-American Development Bank (IDB) for Latin America and the Caribbean.'
    },
    {
      title: 'Who can use FISLAC?',
      text: 'FISLAC is designed for policymakers and government officials in Latin American and Caribbean countries.'
    },
    {
      title: 'What kind of data does LMW-FISLAC provide?',
      text: 'It offers indicators on economic activity, fiscal and external accounts, financial markets, and forecasts.'
    },
    {
      title: 'How often is the data on LMW-FISLAC updated?',
      text: 'The data is regularly updated to ensure accuracy and relevance for policy analysis and decision-making.'
    },
    {
      title: 'How many countries are covered by LMW-FISLAC?',
      text: 'The database covers 26 countries of Latin America and the Caribbean region.'
    },
    {
      title: 'What is the time range of the data available in LMW-FISLAC?',
      text: 'Historical data is available from 1990 to 2024.'
    },
    {
      title: 'What are the main categories of data in LMW-FISLAC?',
      text: 'The main categories include Economic Activity and Employment, Prices and Wages, Fiscal Accounts and Public Debt, External Accounts, Money and Banking, and Financial Markets.'
    },
    {
      title: 'Can I access LMW-FISLAC data in real-time?',
      text: 'Yes, the platform provides real-time updates and visualizations for informed decision-making.'
    },
    {
      title: 'Is there an API available for accessing LMW-FISLAC data?',
      text: 'Yes, the Socrata Open Data Access (SODA) API provides access to the dataset.'
    },
    {
      title: 'Is LMW-FISLAC data free to access?',
      text: 'Yes, the data is publicly accessible.'
    },
    {
      title: 'What is the purpose of LMW-FISLAC?',
      text: 'The purpose is to equip policymakers with a comprehensive toolkit for advanced macro-financial analysis and strategic decision-making.'
    },
    {
      title: 'How can I cite data from LMW-FISLAC?',
      text: 'You can cite it as: Inter-American Development Bank 2022. Latin American Watch Dataset. Washington, DC: Inter-American Development Bank.'
    }
  ]

  return (
    <div>
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7'}}>
            <SideBar />
            <div className='help-main-container' style={{flex: '1'}}>
              <div className='help-header'>
                <h1 className='help-title pb-3'>How can we help?</h1>
                <div className='help-input-container'>
                  <input placeholder='Search using keywords ...' className='help-input' type="text" />
                  <i style={{cursor: 'pointer'}} className="fa-solid fa-magnifying-glass"></i>
                </div>
                <p className='help-tags py-2'><span>Popular searchs:</span> How to create a Dashboard?, What are Favorites? </p>
              </div>
              <Container className='py-5'>
                <p className='help-subtitle'>Getting started</p>
                <p className='help-paragraph'>What we recommend for getting started and make the most out of Latin Macro Watch. Explore <span style={{color: '#3E5BC0', textDecoration: 'underline', cursor: 'pointer'}}>more topics here.</span></p>

                
                <div className='d-flex flex-wrap justify-content-center py-4'>
                  <HelpCard meta={cardMeta[0]} />
                  <HelpCard meta={cardMeta[1]} />
                  <HelpCard meta={cardMeta[2]} />
                  <HelpCard meta={cardMeta[3]} />
                </div>

                

                <p className='help-subtitle'>Frequency Asked Questions (FAQs)</p>
                <p className='help-paragraph'>Find quick answers to the most common questions about our platform. Get the information you need to make the most out of your experience.</p>

                <div className='d-flex flex-column justify-content-center align-items-center'>
                  {questionMeta.map((item, index) => (
                    <HelpQuestion key={index} meta={item} />
                  ))}
                  {/* <HelpQuestion meta={questionMeta[1]} />
                  <HelpQuestion meta={questionMeta[2]} />
                  <HelpQuestion meta={questionMeta[3]} />
                  <HelpQuestion meta={questionMeta[4]} />
                  <HelpQuestion meta={questionMeta[5]} /> */}
                </div>
              </Container>
            </div>
        </div>
        
        <SideResponsiveOpen />
        <Footer />
    </div>
  )
}

export default HelpScreen
