import { act } from 'react'
import { logout } from '../actions/userActions'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    GOOGLE_LOGIN,
    GOOGLE_RESET,

    USER_UPDATE_DATA,

    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,

    USER_REGISTER_SUCCESS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAIL,

    USER_PASSWORD_CHANGE_REQUEST,
    USER_PASSWORD_CHANGE_SUCCESS,
    USER_PASSWORD_CHANGE_FAIL,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_RESET,

    UPDATE_NAME_REQUEST,
    UPDATE_NAME_SUCCESS,
    UPDATE_NAME_FAIL,
    UPDATE_NAME_RESET,

    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_RESET,

    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_FAIL,
    UPDATE_IMAGE_RESET,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_RESET,

    CHANGE_FORGOT_PASSWORD_REQUEST,
    CHANGE_FORGOT_PASSWORD_SUCCESS,
    CHANGE_FORGOT_PASSWORD_FAIL,
    CHANGE_FORGOT_PASSWORD_RESET,

} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }


        case USER_LOGOUT_REQUEST:
            return { loading: true }

        case USER_LOGOUT_SUCCESS:
            return {loading: false, logout: true }

        case USER_LOGOUT_FAIL:
            return {loading: false, error: action.payload}


        case USER_UPDATE_DATA:
            return {... state, userInfo: action.payload}


        default:
            return state
    }
 }

 export const googleLoginReducer = (state = {}, action) => {
    switch(action.type) {
        case GOOGLE_LOGIN:
            return { success: true }

        case GOOGLE_RESET:
            return {}

        default:
            return state
    }
 }

export const userRegisterReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, success: true }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const userChangePasswordReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_PASSWORD_CHANGE_REQUEST:
            return { loading: true }

        case USER_PASSWORD_CHANGE_SUCCESS:
            return { loading: false, success: true }

        case USER_PASSWORD_CHANGE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const userDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true }

        case USER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case USER_DELETE_RESET:
            return {}

        default:
            return state
    }
}

export const updateNameReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_NAME_REQUEST:
            return { loading: true }

        case UPDATE_NAME_SUCCESS:
            return { loading: false, success: true }

        case UPDATE_NAME_FAIL:
            return { loading: false, error: action.payload }

        case UPDATE_NAME_RESET:
            return {}

        default:
            return state
    }
}

export const updatePasswordReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_PASSWORD_REQUEST:
            return { loading: true }

        case UPDATE_PASSWORD_SUCCESS:
            return { loading: false, success: true }

        case UPDATE_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case UPDATE_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}

export const updateImageReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_IMAGE_REQUEST:
            return { loading: true }

        case UPDATE_IMAGE_SUCCESS:
            return { loading: false, success: true }

        case UPDATE_IMAGE_FAIL:
            return { loading: false, error: action.payload }

        case UPDATE_IMAGE_RESET:
            return {}

        default:
            return state
    }
}


export const forgotPasswordReducer = (state = {}, action) => {
    switch(action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return { loading: true }

        case FORGOT_PASSWORD_SUCCESS:
            return { loading: false, success: true }

        case FORGOT_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case FORGOT_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}


export const changeForgotPasswordReducer = (state = {}, action) => {
    switch(action.type) {
        case CHANGE_FORGOT_PASSWORD_REQUEST:
            return { loading: true }

        case CHANGE_FORGOT_PASSWORD_SUCCESS:
            return { loading: false, success: true }

        case CHANGE_FORGOT_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case CHANGE_FORGOT_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}