import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import '../styles/modals.css'
import axios from 'axios'

function ChartTypeModal({closeCallback, chartCallback, id, type}) {
    const [chartType, setChartType] = useState(type)

  return (
    <div className='modal-container'>
        <div style={{maxWidth: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>
            <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
            {/* <img src="/static/forgot.svg" style={{height: '5em', width: 'auto'}} /> */}
            <h1 style={{color: '#313A52'}} className='bold-title fs-1 p-3'>Type of Chart</h1>
            <p className='chart-p'>Select one of the following types:</p>
            <div className='d-flex'>
                <div className='pe-3'>
                    <i onClick={e => setChartType('line')} className={`fa-solid fa-chart-line ${(chartType == 'line')? 'active': 'inactive'}`} style={{fontSize: '50px', cursor: 'pointer'}}></i>
                    <p className={`chart-text ${(chartType == 'line')? 'active': 'inactive'}`}>Lines</p>
                </div>
                <div className='px-3'>
                    <i onClick={e => setChartType('column')} className={`fa-solid fa-chart-column ${(chartType == 'column')? 'active': 'inactive'}`} style={{fontSize: '50px', cursor: 'pointer'}}></i>
                    <p className={`chart-text ${(chartType == 'column')? 'active': 'inactive'}`}>Bars</p>
                </div>
                <div className='px-3'>
                    <i onClick={e => setChartType('area')} className={`fa-solid fa-chart-area ${(chartType == 'area')? 'active': 'inactive'}`} style={{fontSize: '50px', cursor: 'pointer'}}></i>
                    <p className={`chart-text ${(chartType == 'area')? 'active': 'inactive'}`}>Area</p>
                </div>
                <div className='ps-3'>
                    <i className="fa-solid fa-chart-pie" style={{fontSize: '50px', color: '#D1D1D1'}}></i>
                    <p className='chart-text'>Pie</p>
                </div>
            </div>

            <button onClick={e => chartCallback(chartType, id)} style={{width: '100%'}} className='login-button mt-4'>Select</button>
                
        </div>
    </div>

  )
}

export default ChartTypeModal
