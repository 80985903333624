import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createDashboard } from '../actions/platformActions'
import { Spinner } from 'react-bootstrap'
import Message from '../components/Message'
import { useDispatch, useSelector } from 'react-redux';


function NoDashboardCard ({isEmpty}){

  const dispatch = useDispatch()
  const navigate = useNavigate();

    const handleClick = () => {
      dispatch(createDashboard())
    };

    const createInfo = useSelector(state => state.createDashboard)
    const {loading, error, dashboard} = createInfo

    useEffect(() => {
      if(dashboard){
        console.log("dashboard: ", dashboard)
        navigate(`/dashboard/${dashboard.code_id}`)
      }
    }, [createInfo])

    return (
      <div className="card mb-4 pt-1" style={{ maxWidth: '720px', backgroundColor: '#F4F5F7'}}>
        <div className="row g-0">
          <div className="col-md-5">
            <img
              src="/static/NoDashboards.svg"
              className="img-fluid w-100 h-100"
              alt="Imagen de ejemplo"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="col-md-7 d-flex align-items-center">
            <div className="card-body">
              <h5 className="card-title fw-bold mb-4" style={{ fontSize: '24px' }}>
                {isEmpty ? (
                  'Get started with Dashboards'
                ): (
                  'Explore Your Dashboards'
                )}

              </h5>
              <p className="card-text mb-4" style={{ fontSize: '16px' }}>
                {isEmpty ? (
                  'You don’t have dashboards yet. Create your first dashboard here.'
                ): (
                  'You have dashboards available. Dive into your data insights or create new ones to stay on top of your metrics.'
                )}
              </p>
              <div className="d-flex justify-content-between">
                {loading ? (
                  <button className="btn btn-dark text-white fw-bold flex-grow-1 me-2">
                    <Spinner variant='light' animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                  </button>
                ) : error ? (
                  <>
                    <Message variant='danger'>{error}</Message>
                    <button onClick={handleClick} className="btn btn-dark text-white fw-bold flex-grow-1 me-2">Create Dashboard</button>
                  </>
                ): (
                  <button onClick={handleClick} className="btn btn-dark text-white fw-bold flex-grow-1 me-2">Create Dashboard</button>
                )}
                <button className="btn btn-outline-dark fw-bold flex-grow-1">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default NoDashboardCard;