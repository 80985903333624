export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL'
export const CREATE_FOLDER_RESET = 'CREATE_FOLDER_RESET'

export const FOLDER_LIST_REQUEST = 'FOLDER_LIST_REQUEST'
export const FOLDER_LIST_SUCCESS = 'FOLDER_LIST_SUCCESS'
export const FOLDER_LIST_FAIL = 'FOLDER_LIST_FAIL'

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_FAIL = 'DELETE_FOLDER_FAIL'
export const DELETE_FOLDER_RESET = 'DELETE_FOLDER_RESET'

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST'
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_FAIL = 'UPDATE_FOLDER_FAIL'
export const UPDATE_FOLDER_RESET = 'UPDATE_FOLDER_RESET'


export const GET_SERIES_REQUEST = 'GET_SERIES_REQUEST'
export const GET_SERIES_SUCCESS = 'GET_SERIES_SUCCESS'
export const GET_SERIES_FAIL = 'GET_SERIES_FAIL'

export const DELETE_SERIES_REQUEST = 'DELETE_SERIES_REQUEST'
export const DELETE_SERIES_SUCCESS = 'DELETE_SERIES_SUCCESS'
export const DELETE_SERIES_FAIL = 'DELETE_SERIES_FAIL'
export const DELETE_SERIES_RESET = 'DELETE_SERIES_RESET'

export const FAVORITE_COUNT_REQUEST = 'FAVORITE_COUNT_REQUEST'
export const FAVORITE_COUNT_SUCCESS = 'FAVORITE_COUNT_SUCCESS'
export const FAVORITE_COUNT_FAIL = 'FAVORITE_COUNT_FAIL'
export const FAVORITE_COUNT_RESET = 'FAVORITE_COUNT_RESET'

export const DASHBOARD_COUNT_REQUEST = 'DASHBOARD_COUNT_REQUEST'
export const DASHBOARD_COUNT_SUCCESS = 'DASHBOARD_COUNT_SUCCESS'
export const DASHBOARD_COUNT_FAIL = 'DASHBOARD_COUNT_FAIL'
export const DASHBOARD_COUNT_RESET = 'DASHBOARD_COUNT_RESET'

export const GET_SERIES_TRACKER_REQUEST = 'GET_SERIES_TRACKER_REQUEST'
export const GET_SERIES_TRACKER_SUCCESS = 'GET_SERIES_TRACKER_SUCCESS'
export const GET_SERIES_TRACKER_FAIL = 'GET_SERIES_TRACKER_FAIL'
export const GET_SERIES_TRACKER_RESET = 'GET_SERIES_TRACKER_RESET'


export const CREATE_TRACKER_REQUEST = 'CREATE_TRACKER_REQUEST'
export const CREATE_TRACKER_SUCESS = 'CREATE_TRACKER_SUCESS'
export const CREATE_TRACKER_FAIL = 'CREATE_TRACKER_FAIL'
export const CREATE_TRACKER_RESET = 'CREATE_TRACKER_RESET'

export const DELETE_TRACKER_REQUEST = 'DELETE_TRACKER_REQUEST'
export const DELETE_TRACKER_SUCESS = 'DELETE_TRACKER_SUCESS'
export const DELETE_TRACKER_FAIL = 'DELETE_TRACKER_FAIL'
export const DELETE_TRACKER_RESET = 'DELETE_TRACKER_RESET'

export const EDIT_TRACKER_REQUEST = 'EDIT_TRACKER_REQUEST'
export const EDIT_TRACKER_SUCESS = 'EDIT_TRACKER_SUCESS'
export const EDIT_TRACKER_FAIL = 'EDIT_TRACKER_FAIL'
export const EDIT_TRACKER_RESET = 'EDIT_TRACKER_RESET'


export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST'
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS'
export const CREATE_DASHBOARD_FAIL = 'CREATE_DASHBOARD_FAIL'
export const CREATE_DASHBOARD_RESET = 'CREATE_DASHBOARD_RESET'

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL'
export const GET_DASHBOARD_RESET = 'GET_DASHBOARD_RESET'

export const UPDATE_DASHBOARD_NAME_REQUEST = 'UPDATE_DASHBOARD_NAME_REQUEST'
export const UPDATE_DASHBOARD_NAME_SUCCESS = 'UPDATE_DASHBOARD_NAME_SUCCESS'
export const UPDATE_DASHBOARD_NAME_FAIL = 'UPDATE_DASHBOARD_NAME_FAIL'
export const UPDATE_DASHBOARD_NAME_RESET = 'UPDATE_DASHBOARD_NAME_RESET'


export const UPDATE_DASHBOARD_DESCRIPTION_REQUEST = 'UPDATE_DASHBOARD_DESCRIPTION_REQUEST'
export const UPDATE_DASHBOARD_DESCRIPTION_SUCCESS = 'UPDATE_DASHBOARD_DESCRIPTION_SUCCESS'
export const UPDATE_DASHBOARD_DESCRIPTION_FAIL = 'UPDATE_DASHBOARD_DESCRIPTION_FAIL'
export const UPDATE_DASHBOARD_DESCRIPTION_RESET = 'UPDATE_DASHBOARD_DESCRIPTION_RESET'

export const SAVE_DASHBOARD_REQUEST = 'SAVE_DASHBOARD_REQUEST'
export const SAVE_DASHBOARD_SUCCESS = 'SAVE_DASHBOARD_SUCCESS'
export const SAVE_DASHBOARD_FAIL = 'SAVE_DASHBOARD_FAIL'
export const SAVE_DASHBOARD_RESET = 'SAVE_DASHBOARD_RESET'


export const GET_WIDGETS_REQUEST = 'GET_WIDGETS_REQUEST'
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS'
export const GET_WIDGETS_FAIL = 'GET_WIDGETS_FAIL'
export const GET_WIDGETS_RESET = 'GET_WIDGETS_RESET'


export const GET_INDICATOR_UNITS_REQUEST = 'GET_INDICATOR_UNITS_REQUEST'
export const GET_INDICATOR_UNITS_SUCCESS = 'GET_INDICATOR_UNITS_SUCCESS'
export const GET_INDICATOR_UNITS_FAIL = 'GET_INDICATOR_UNITS_FAIL'
export const GET_INDICATOR_UNITS_RESET = 'GET_INDICATOR_UNITS_RESET'

export const GET_INDICATOR_TIMEFRAME_REQUEST = 'GET_INDICATOR_TIMEFRAME_REQUEST'
export const GET_INDICATOR_TIMEFRAME_SUCCESS = 'GET_INDICATOR_TIMEFRAME_SUCCESS'
export const GET_INDICATOR_TIMEFRAME_FAIL = 'GET_INDICATOR_TIMEFRAME_FAIL'
export const GET_INDICATOR_TIMEFRAME_RESET = 'GET_INDICATOR_TIMEFRAME_RESET'

export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST'
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS'
export const DELETE_WIDGET_FAIL = 'DELETE_WIDGET_FAIL'
export const DELETE_WIDGET_RESET = 'DELETE_WIDGET_RESET'

export const GET_WIDGET_SERIE_DATA_REQUEST = 'GET_WIDGET_SERIE_DATA_REQUEST'
export const GET_WIDGET_SERIE_DATA_SUCCESS = 'GET_WIDGET_SERIE_DATA_SUCCESS'
export const GET_WIDGET_SERIE_DATA_FAIL = 'GET_WIDGET_SERIE_DATA_FAIL'
export const GET_WIDGET_SERIE_DATA_RESET = 'GET_WIDGET_SERIE_DATA_RESET'

export const GET_DASHBOARD_LIST_REQUEST = 'GET_DASHBOARD_LIST_REQUEST'
export const GET_DASHBOARD_LIST_SUCCESS = 'GET_DASHBOARD_LIST_SUCCESS'
export const GET_DASHBOARD_LIST_FAIL = 'GET_DASHBOARD_LIST_FAIL'
export const GET_DASHBOARD_LIST_RESET = 'GET_DASHBOARD_LIST_RESET'

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST'
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS'
export const DELETE_DASHBOARD_FAIL = 'DELETE_DASHBOARD_FAIL'
export const DELETE_DASHBOARD_RESET = 'DELETE_DASHBOARD_RESET'

export const GET_DASHBOARD_HOME_REQUEST = 'GET_DASHBOARD_HOME_REQUEST'
export const GET_DASHBOARD_HOME_SUCCESS = 'GET_DASHBOARD_HOME_SUCCESS'
export const GET_DASHBOARD_HOME_FAIL = 'GET_DASHBOARD_HOME_FAIL'
export const GET_DASHBOARD_HOME_RESET = 'GET_DASHBOARD_HOME_RESET'

export const VIEW_COUNT_REQUEST = 'VIEW_COUNT_REQUEST'
export const VIEW_COUNT_SUCCESS = 'VIEW_COUNT_SUCCESS'
export const VIEW_COUNT_FAIL = 'VIEW_COUNT_FAIL'
export const VIEW_COUNT_RESET = 'VIEW_COUNT_RESET'

export const UPDATE_DASHBOARD_IMAGE_REQUEST = 'UPDATE_DASHBOARD_IMAGE_REQUEST'
export const UPDATE_DASHBOARD_IMAGE_SUCCESS = 'UPDATE_DASHBOARD_IMAGE_SUCCESS'
export const UPDATE_DASHBOARD_IMAGE_FAIL = 'UPDATE_DASHBOARD_IMAGE_FAIL'
export const UPDATE_DASHBOARD_IMAGE_RESET = 'UPDATE_DASHBOARD_IMAGE_RESET'

export const GET_RECENT_SEARCH_REQUEST = 'GET_RECENT_SEARCH_REQUEST'
export const GET_RECENT_SEARCH_SUCCESS = 'GET_RECENT_SEARCH_SUCCESS'
export const GET_RECENT_SEARCH_FAIL = 'GET_RECENT_SEARCH_FAIL'
export const GET_RECENT_SEARCH_RESET = 'GET_RECENT_SEARCH_RESET'







