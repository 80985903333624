import React, {useEffect, useState} from 'react'
import { roundy } from './Utilities'
import Chart from 'react-apexcharts';
import EditWidgetText from '../components/EditWidgetText'

function TextWidget({editFlag, wid, data, openCallback, deleteCallback}) {
    
    const [body, setBody] = useState(data.body)
    const [background, setBackground] = useState(data.background)

    // console.log("body: ", data)

  return (
    <div style={{backgroundColor: (background) ? 'transparent': '', boxShadow: (background) ? 'none': ''}} className='dwidget-container'>
        {/* <div className='d-flex justify-content-end'>
        
            <div>
                <i style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                <i style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-circle-xmark px-2 pt-2"></i>
            </div>
        </div>   */}
        <div style={{position: 'relative'}} className='h-100'>
            {editFlag && (
                <div style={{position: 'absolute', right: '0'}}>
                    <i onMouseDown={e => openCallback(wid, data)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                    <i onMouseDown={e => deleteCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-circle-xmark px-2 pt-2"></i>
                </div>
            )}
            <div className='d-flex justify-content-center align-items-center h-100'>
                {data.body ? (
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                ): (
                    <div onMouseDown={e => openCallback(wid, data)} style={{color: '#8b8b8b', cursor: 'pointer'}} className='text-center'>
                        Click edit button <br />
                        to edit the text
                        
                        {/* {editFlag && (
                            <i  style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                        )} */}
                    </div>
                )}
            </div>
        </div>
      
    

    </div>
  )
}

export default TextWidget
