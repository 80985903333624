import React from 'react'

function SideResponsiveOpen() {

    const openHandler = () => {
        var sideObject = document.getElementById('side-bar')
        var menuButton = document.getElementById('side-responsive-open')
    // sideObject.style.display = 'none'
        if (sideObject.classList.contains('hidden')) {
            // Si está oculto, lo mostramos
            sideObject.classList.remove('hidden');
        } else {
            // Si está visible, lo ocultamos
            sideObject.classList.add('hidden');
        }
                
        
    }
  return (
    <div id='side-responsive-open' className='side-responsive-open'>
        <i onClick={openHandler} className="fa-solid fa-bars"></i>
    </div>
  )
}

export default SideResponsiveOpen
