import '../styles/help.css'
import React from 'react'

function HelpCard({meta}) {
  return (
    <div className='help-card-container'>
        <div className='help-card-header'>
        <i className="fa-solid fa-arrow-up-right-from-square"></i>
        <p>{meta.type}</p>
        </div>
        <div className='help-card-body'>
        <p className='help-card-date'>{meta.date}</p>
        <div className='d-flex align-items-center'>
            <i className={`${meta.icon} help-card-icon`}></i>
            <div>
            <p className='help-card-title'>{meta.title}</p>
            <p className='help-card-paragraph'>{meta.text}</p>
            </div>
        </div>
        </div>
    </div>
  )
}

export default HelpCard
