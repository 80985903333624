import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../styles/modals.css'
import Message from './Message'
import { Spinner } from 'react-bootstrap'
import { updateDashboardDescription } from '../actions/platformActions'

function EditDescriptionDashboardModal({description, closeCallback, id}) {
  
    const dispatch = useDispatch()
    const [descriptionValue, setDescriptionValue] = useState(description)


  const updateDescriptionHandler = () => {
    
    dispatch(updateDashboardDescription(id, descriptionValue))
  }

  const updateInfo = useSelector(state => state.updateDashboardDescription)
  const {loading, error, description: success} = updateInfo

  useEffect(() => {
    if(success){
        closeCallback(true)
    }
  }, [updateInfo])

  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

          <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Edit Description</h1>

           <p className='p-paragraph text-center'>Update or change the existing description of the dashboard</p>
            
            <div className='d-flex flex-column w-100'>
                <span className='p-label-2'>Description:</span>
                <input value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value)} className='p-input my-2' placeholder='Enter description' type="text" />
            </div>
            {loading ? (
                <div style={{width: '100%'}} className='login-button mt-3'>
                    <Spinner variant='light' animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>    
            ): error ? (
                <>
                    <Message variant={'danger'}>{error}</Message>
                    <div onClick={updateDescriptionHandler} style={{width: '100%'}} className='login-button mt-3'>Update Description</div>
                </>
            ): (
                <div onClick={updateDescriptionHandler} style={{width: '100%'}} className='login-button mt-3'>Update Description</div>
            )}
              
        </div>
      </div>
  )
}

export default EditDescriptionDashboardModal
