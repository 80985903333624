import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { REFRESH_TOKEN, ACCESS_TOKEN } from "../constants/userConstants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { refreshUser } from '../actions/userActions'
import { Spinner } from 'react-bootstrap'


function ProtectedRoute({ isMandatory, children }) {

    const dispatch = useDispatch()
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        auth().catch(() => setIsAuthorized(false))
    }, [])

    const refreshToken = async () => {
        const userInfo = localStorage.getItem('userInfo');
        let userObject = JSON.parse(userInfo)
        const rToken = userObject.refresh

        try {
            // const {data} = await axios.patch(
            //     '/api/user/update-first-name/',
            //     {
            //         first_name: name,
            //     },
            //     config
            // )
            const res = await axios.post(
                'api/user/token/refresh/',
                {
                    refresh: rToken,
                }
            );

            if (res.status === 200) {
                const responseUser = res.data
                // console.log("RESPONSE: ". responseUser)
                dispatch(refreshUser(responseUser))
                // userObject.access = res.data.access
                // localStorage.setItem('userInfo', JSON.stringify(userObject))
                setIsAuthorized(true)
            } else {
                // console.log("holandas")
                setIsAuthorized(false)
                localStorage.removeItem('userInfo')
            }
        } catch (error) {
            console.log(error);
            setIsAuthorized(false);
            localStorage.removeItem('userInfo')
        }
    };

    const auth = async () => {
        const userInfo = localStorage.getItem('userInfo');
        let userObject = JSON.parse(userInfo)
        const token = userObject.access

        if (!token) {
            setIsAuthorized(false);
            return;
        }
        const decoded = jwtDecode(token);
        const tokenExpiration = decoded.exp;
        const now = Date.now() / 1000;

        if (tokenExpiration < (now)) {
            console.log("token close to expired")
            console.log("wait ...")
            await refreshToken();
        } else {
            console.log("token not expired")
            setIsAuthorized(true);
        }
    };

    if (isAuthorized === null) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
            </div>
        )
    }

    if(isMandatory){
        return isAuthorized ? children :
        <Navigate to={'/'} />;
    }
    else{
        return children
    }

}

export default ProtectedRoute;