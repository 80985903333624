import '../styles/viewer.css'
import React, {useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Spinner, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import * as XLSX from 'xlsx';
import Chart from 'react-apexcharts';
import Select from 'react-select'
import ChartTypeModal from '../components/ChartTypeModal'
import Message from '../components/Message'
import AddFolderModal from '../components/AddFolderModal'
import AddLabelModal from "../components/AddLabelModal"
import LoginModal from "../components/LoginModal"
import RegisterModal from "../components/RegisterModal"
import ForgotModal from "../components/ForgotModal"

import {
    getFolders,
  } from '../actions/platformActions'

import {
    getData,
    updateSeries,
} from '../actions/viewerActions'
import { roundy } from '../components/Utilities';
import { SAVE_SERIES_RESET, UPDATE_SERIES_RESET, } from '../constants/viewerConstants'

function ViewerModal({meta, closeCallback, isSaved = null}) {
    // isSaved = {id: idSerie, folder: idFolder}
    // console.log("meta: ", meta)

    const dispatch = useDispatch()
    const [menuOption, setMenuOption] = useState('settings');

    const [addFolderModal, setAddFolderModal] = useState(false)

    const [addLabelModal, setAddLabelModal] = useState(false)
    const [labelId, setLabelId] = useState(null)

    const [metaSender, setMetaSender] = useState(meta)

    const [chartModal, setChartModal] = useState(false)
    const [chartType, setChartType] = useState(null)
    const [chartId, setChartId] = useState(null)

    // const [unitValue, setUnitValue] = useState({value: meta.units[0], label: meta.units[0]})
    const [nameDisabled, setNameDisabled] = useState((isSaved ? true: false))
    const [nameValue, setNameValue] = useState((isSaved ? isSaved.name : null))
    const [unitValue, setUnitValue] = useState(meta.chart.unit)
    const [government, setGovernment] = useState(null)

    const [sourceValue, setSourceValue] = useState('')
    // const [frequencyValue, setFrequencyValue] = useState({value: meta.frequency, label: meta.frequency})
    const [frequencyValue, setFrequencyValue] = useState(meta.chart.frequency)
    const [countryValue, setCountryValue] = useState([])
    // const [countryValue, setCountryValue] = useState(meta.chart.country)
    const [countryList, setCountryList] = useState(null)
    const [regionList, setRegionList] = useState(null)
    // const [legendValue, setLegendValue] = useState(true)
    const [legendValue, setLegendValue] = useState(meta.chart.legend)

    const [definitionValue, setDefinitonValue] = useState(meta.units_definitions[meta.units[0]])
    // const [xAnnotationValue, setXannotationValue] = useState([{id: 0, value: '', label: ''}])
    const [xAnnotationValue, setXannotationValue] = useState(meta.chart.xaxis)
    const [xAnnotationChart, setXannotationChart] = useState([])
    // const [yAnnotationValue, setYannotationValue] = useState([{id: 0, value: '', label: ''}])
    const [yAnnotationValue, setYannotationValue] = useState(meta.chart.yaxis)
    const [yAnnotationChart, setYannotationChart] = useState([])
    // const [metricValue, setMetricValue] = useState([{id: 0, value: '', label: ''}])
    const [metricValue, setMetricValue] = useState(meta.chart.metrics)
    // const [highlightValue, setHighlightValue] = useState([{
    //     id: 0,
    //     x1: {value: '', label: ''},
    //     x2: {value: '', label: ''},
    // }])
    const [highlightValue, setHighlightValue] = useState(meta.chart.highlight)

    const defaultColors =  [
        '#1F6498',
        '#3B8C6E',
        '#333333',
        '#BD2A2E',
        '#0C66E4',
        '#42515C',
        '#D9B343',
        '#164773',
        '#D94F04',
        '#26AEC0',
        '#89D99D',
        '#F7CD82',
        '#F79E6B',
        '#1E5959',
        '#323050',
        '#21445B',
        '#0B2B40',
        '#2D2D2D',
        '#9D9D9D',
        '#5D8A66'
    ];

    const [loginModal, setLoginModal] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)
    const [forgotModal, setForgotModal] = useState(false)


    const userLogin = useSelector(state => state.userLogin)
    const {loading: loadingUser, error: errorUser, userInfo} = userLogin
    // const [yAnnotationChart, setYannotationChart] = useState([])

    const [xdata, setXdata] = useState([])
    const [ydata, setYdata] = useState([])

    const [table, setTable] = useState([])

    useEffect(() => {
        dispatch({
            type: UPDATE_SERIES_RESET,
        })
        const area = meta.area
        const topic = meta.topic
        const indicator = meta.indicator
        dispatch(getData(area, topic, indicator))
        dispatch(getFolders())
    }, [])

    const dataInfo = useSelector(state => state.dataList)
    const {loading, error, data} = dataInfo


    useEffect(() => {
        if(data != undefined) {
            let countries = Object.keys(data.data_dict.Countries)
            let regions = Object.keys(data.data_dict.Regions)

            let userCountries = meta.chart.country.map(item => item.value)

            // console.log("****meta: ", meta)

            if(meta.chart.country.length == 0){
                const item = countries[0]
                setCountryValue([
                    {
                        id: 0,
                        value: item,
                        label: item,
                        color: defaultColors[0],
                        type: 'line',
                        countryType: 'country'
                    }
                ])
                // setCountryValue([
                //     {
                //         id: 0,
                //         value: '',
                //         label: '',
                //         color: defaultColors[0],
                //         type: '',
                //         countryType: ''
                //     }
                // ])
            }else{
                setCountryValue(meta.chart.country.map((item, index) => {
                    return {
                        id: index,
                        value: item.value,
                        label: item.label,
                        color: item.color,
                        type: item.type,
                        countryType: item.countryType
                    }
                }))
            }

            // if(userCountries.length == 0){

            //     setCountryValue([
            //         {
            //             id: 0,
            //             value: countries[0],
            //             label: countries[0],
            //             color: defaultColors[0],
            //             type: 'line',
            //             countryType: 'country'
            //         }
            //     ])
            // }else{

            //     let finalCountry = [...countries, ...regions].filter(item => userCountries.includes(item))


            //     setCountryValue(finalCountry.map((item, index) => {
            //             return {
            //                 id: index,
            //                 value: item,
            //                 label: item,
            //                 color: defaultColors[index],
            //                 type: 'line',
            //                 countryType: (countries.includes(item)) ? 'country':
            //                             (regions.includes(item)) ? 'region': ''
            //             }
            //         }
            //         )
            //     )
            // }
            setCountryList(countries)
            setRegionList(regions)
        }
    }, [data])

    useEffect(() => {
        setDefinitonValue(meta.units_definitions[unitValue.value])
    }, [unitValue])

    // const countryOptions = countryList?.map(item => {
    //     return {value: item, label: item}
    // })

    const countryOptions = [
        {
            label: 'Countries',
            options: countryList?.map(item => {
                    return {value: item, label: item, type: 'country'}
                })
        },
        {
            label: 'Regions',
            options: regionList?.map(item => {
                return {value: item, label: item, type: 'region'}
            })
        }
    ]
    const xAnnotationsOptions = xdata?.map(item => {
        return {value: item, label: item}
    })
    const yAnnotationsOptions = ydata?.map(item => {
        return {value: item, label: item}
    })
    const unitOptions = meta.units.map(item => {
        return {value: item, label: item}
    })
    const metricOptions = [
        {value: 'mean', label: 'Mean'},
        {value: 'median', label: 'Median'},
        {value: 'p10', label: 'Percentile 10'},
        {value: 'p20', label: 'Percentile 20'},
        {value: 'p30', label: 'Percentile 30'},
        {value: 'p40', label: 'Percentile 40'},
        {value: 'p50', label: 'Percentile 50'},
        {value: 'p60', label: 'Percentile 60'},
        {value: 'p70', label: 'Percentile 70'},
        {value: 'p80', label: 'Percentile 80'},
        {value: 'p90', label: 'Percentile 90'},

    ]
    const frequencyOptions = [
        {value: 'Annual', label: 'Annual'},
        {value: 'monthly', label: 'Monthly'},
        {value: 'Quarterly', label: 'Quarterly'},
    ]
    const governmentOptions = [

        {value: 'label1', label: 'Label 1'},
        {value: 'label2', label: 'Label 2'},
    ]



    const chartOptions = {
        chart: {
            id: 'basic-line-chart',
            toolbar: {
                show: true,
            }
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: ydata.map(item => item.color),
        plotOptions: {
            bar: {
                columnWidth: '100%',
                barHeight: '100%',
                borderRadius: 1,


            },
            area: {
                fillTo: 'origin'
            },
            line: {
                isSloperChart: true,
            }
        },
        xaxis: {
            labels: {
                rotate: -90,
                rotateAlways: true,
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                }
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 10,
            // type: 'datetime',
            categories: xdata.map(item => item.split('-')[0] + ' - ' + item.split('-')[1]),
        },
        yaxis: {
            title: {
                text: unitValue.label,
                style: {
                    color: '#333333',
                    fontSize: '12px',
                    fontFamily: 'Open Sans',
                    fontWeigth: 600
                }
            },
            labels: {
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                },
                formatter: function(value) {
                    return roundy(value)
                }
            }
        },
        fill: {
            opacity: 0.2,
            type: ydata.map(item => item.type == 'area' ? 'solid': 'gradient'),
            gradient: {
                opacityFrom: 1,
                shadeIntensity: 0,
            }
        },
        grid:
        {
            show: false,
        },
        legend: {
            show: legendValue,
            position: 'top',
            // horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeigth: '300',
            // floating: true,
            labels: {
                colors: ['#333333']
            }
        },
        annotations: {
            yaxis: yAnnotationChart,
            xaxis: xAnnotationChart

            // xaxis: [
            //     {
            //         x: '2006 - 03',
            //         x2:'2008 - 03',
            //         strokeDashArray: 4,
            //         borderColor: '#B6B6B6',
            //         fillColor: '#B6B6B6',
            //         opacity: 0.2
            //     }
            // ],
        }
    };

    const series = (ydata.length > 0) ? ydata : [{name: '', data: []}]

    const menuHandler = (type) => {
        setMenuOption(type)
    }

    const folderInfo = useSelector(state => state.listFolder)
    const {loading: loadingFolder, error: errorFolder, folderList} = folderInfo

    const folderOptions = folderList?.map(item => ({
        value: item.id,
        label: item.folder_name,
    }))
    const addFolderHandler = () => {
        if(userInfo){
            // dispatch(getFolders())
            dispatch({
                type: SAVE_SERIES_RESET,
            })
            let finalMeta = JSON.parse(JSON.stringify(metaSender))
            finalMeta.chart = {
                unit: unitValue,
                frequency: frequencyValue,
                country: countryValue,
                legend: legendValue,
                metrics: metricValue,
                xaxis: xAnnotationValue,
                yaxis: yAnnotationValue,
                highlight: highlightValue
            }
            setMetaSender(finalMeta)
            setAddFolderModal(true)
        }else{
            setLoginModal(true)
        }
    }
    const favoriteHandler = () => {
        console.log("asdg")
    }

    const updateHandler = () => {
        if(userInfo){
            // dispatch(getFolders())
            let finalMeta = JSON.parse(JSON.stringify(metaSender))
            finalMeta.chart = {
                unit: unitValue,
                frequency: frequencyValue,
                country: countryValue,
                legend: legendValue,
                metrics: metricValue,
                xaxis: xAnnotationValue,
                yaxis: yAnnotationValue,
                highlight: highlightValue
            }
            setMetaSender(finalMeta)
            // let Id = folderList?.filter(item => item.folder_name = 'Main Folder')[0].id
            const Id = isSaved.id
            dispatch(updateSeries(Id, finalMeta, nameValue))
        }else{
            setLoginModal(true)
        }
    }

    const updateInfo = useSelector(state => state.updateSeries)
    const {loading: loadingUpdate, error: errorUpdate, success} = updateInfo


    const shareHandler = () => {
        console.log("shareHandler")
    }
    const closeHandler = () => {
        closeCallback()
    }

    const closeFolderModal = () => {
        setAddFolderModal(false)
    }
    const closeLabelModal = () => {
        setAddLabelModal(false)
    }



    const changeUnitHandler = (option) => {
        setUnitValue(option)
    }

    const addCountryHandler = () => {
        if(countryValue.length > 0){
            setCountryValue(prevCountries => [...prevCountries, { id: prevCountries[prevCountries.length -1].id + 1, value: '', label: '', color: defaultColors[(prevCountries[prevCountries.length -1].id + 1) % 20], type: 'line', countryType: ''}])
        }else{
            setCountryValue(prevCountries => [...prevCountries, { id: 0, value: '', label: '', color: defaultColors[0], type: 'line', countryType: ''}])
        }
    }
    const addXannotationHandler = () => {
        if(xAnnotationValue.length > 0){
            setXannotationValue(prevAnnotation => [...prevAnnotation, { id: prevAnnotation[prevAnnotation.length - 1].id + 1, value: '', label: '' }])
        }
        else{
            setXannotationValue(prevAnnotation => [...prevAnnotation, { id: 0, value: '', label: '' }])
        }
    }
    const addYannotationHandler = () => {
        if(yAnnotationValue.length > 0){
            setYannotationValue(prevAnnotation => [...prevAnnotation, { id: prevAnnotation[prevAnnotation.length - 1].id + 1, value: '', label: '' }])
        }else{
            setYannotationValue(prevAnnotation => [...prevAnnotation, { id: 0, value: '', label: '' }])
        }
    }
    const addHighlightHandler = () => {
        if(highlightValue.length > 0){
            setHighlightValue(prevHighlight => [...prevHighlight, { id: prevHighlight[prevHighlight.length - 1].id + 1, x1: {value: '', label: ''}, x2: {value: '', label: ''} }])
        }else{
            setHighlightValue(prevHighlight => [...prevHighlight, { id: 0, x1: {value: '', label: ''}, x2: {value: '', label: ''} }])
        }
    }
    const addMetricHandler = () => {
        if(metricValue.length > 0){
            setMetricValue(prevMetric => [...prevMetric, { id: prevMetric[prevMetric.length - 1].id + 1, value: '', label: '' }])
        }else{
            setMetricValue(prevMetric => [...prevMetric, { id: 0, value: '', label: '' }])
        }
    }

    const closeChartModal = () => {
        setChartModal(false)
    }

    useEffect(()=> {
        if(countryValue.length > 0 && data){
            let x = []
            const tempCountry = countryValue.filter(item => item.value != '')
            // console.log("TEMPCOUNTRY: ", tempCountry)
            tempCountry.forEach(item => {
                let xtemp = []

                if(item.countryType == 'country'){
                    if(!data?.data_dict.Countries[item.value]?.data){
                        return
                    }
                    xtemp = data?.data_dict.Countries[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                }
                else if(item.countryType == 'region'){
                    if(!data?.data_dict.Regions[item.value]?.data){
                        return
                    }
                    xtemp = data?.data_dict.Regions[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                }
                // console.log('xtemp: ', xtemp)
                // if(xtemp){
                    x = [...x, ...xtemp]
                // }
            })
            x = [...new Set(x)].sort()

            setXdata(x)

            let y = tempCountry.filter(item =>
                (item.countryType == 'country' && data?.data_dict.Countries[item.value]?.data != undefined) ||
                (item.countryType == 'region' && data?.data_dict.Regions[item.value]?.data != undefined)).map(item => {
                return {
                    name: item.label,
                    data: x.map(e =>
                        (item.countryType == 'region') ?
                            ((data.data_dict.Regions[item.label].data?.filter(a => a.frequency == frequencyValue.value && a.unit == unitValue.value).find(b => b.date == e)) ?
                                (data.data_dict.Regions[item.label].data?.filter(a => a.frequency == frequencyValue.value && a.unit == unitValue.value).find(b => b.date == e)).value:
                                null)
                            : ((data.data_dict.Countries[item.label].data?.filter(a => a.frequency == frequencyValue.value && a.unit == unitValue.value).find(b => b.date == e)) ?
                                (data.data_dict.Countries[item.label].data?.filter(a => a.frequency == frequencyValue.value && a.unit == unitValue.value).find(b => b.date == e)).value:
                                null)),
                    type: item.type,
                    color: item.color,
                }
            })
            setYdata(y)

            let sourceSet = new Set()
            let source = tempCountry.map(item => {
                if(item.countryType == 'region') {
                    setSourceValue('Multiple sources')
                    return
                }else{
                    let tempArray = data.data_dict.Countries[item.label]?.sources[unitValue.label]

                    if(!tempArray){
                        return
                    }
                    if(tempArray){
                        tempArray.forEach(src => {
                            sourceSet.add(src)
                        })
                    }
                }
            })
            let sourceList = Array.from(sourceSet)
            // console.log("sourceList: ", sourceList)
            let sourceString = sourceList.join(', ')
            // console.log("sourceString: ", sourceString)
            setSourceValue(sourceString)


            let xanntemp = xAnnotationValue.map(item => {
                return {
                    x: item.value.split('-')[0] + ' - ' + item.value.split('-')[1],
                    // x2: '2022 - 01',

                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1
                }
            })

            let highlighttemp = highlightValue.map(item => {
                return {
                    x: item.x1.value.split('-')[0] + ' - ' + item.x1.value.split('-')[1],
                    x2: item.x2.value.split('-')[0] + ' - ' + item.x2.value.split('-')[1],
                    // x2: '2022 - 01',

                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 0.5
                }
            })

            let d =  [...xanntemp, ...highlighttemp]
            setXannotationChart(d)

            let yanntemp = yAnnotationValue.map(item => {
                return {
                    y: item.value,
                    // x2: '2022 - 01',

                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1,
                    label: {
                        text: item.text,
                        position: 'left',
                        offsetX: 25,
                        style: {
                            background: '#2aac9c',
                            color: '#ffffff',
                            fontFamily: 'Open Sans',
                        }
                    }
                }
            })

            let metrictemp = metricValue.filter(item => item.value != '').map(item => {

                let met = 0
                countryValue.filter(e => e.value != '').forEach(country => {
                    if(country.countryType == 'country'){
                        let gg = data.data_dict.Countries[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
                        met += parseFloat(gg)
                    }
                    else if(country.countryType == 'region'){
                        let gg = data.data_dict.Regions[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
                        met += parseFloat(gg)
                    }
                })
                // console.log('met', met)

                let metricY = null
                if(met){
                    metricY = met / countryValue.filter(e => e.value != '').length
                }

                if(metricY){

                    return {
                        y: metricY,
                        strokeDashArray: 1,
                        borderColor: '#333333',
                        fillColor: '#333333',
                        opacity: 1,
                        label: {
                            text: item.label,
                            style: {
                                background: '#577bab',
                                color: '#ffffff',
                                fontFamily: 'Open Sans',
                                // padding: {
                                //     top: 2,
                                //     bottom: 5,
                                // }
                            }
                        }
                    }
                }
            })

            let yfinal =  [...yanntemp, ...metrictemp]

            setYannotationChart(yfinal)

            let u = []
            for(let i = 0; i < x.length; i++){
                let temp = {}
                temp.date = x[i]
                y.forEach(e => {
                    temp[e.name] = e.data[i]
                })
                u.push(temp)
            }
            setTable(u)
        }
    }, [countryValue, frequencyValue, unitValue, xAnnotationValue, yAnnotationValue, highlightValue, metricValue, data])

    const changeCountryHandler = async (option, id) => {
        // console.log("id: ", id)
        // console.log("value: ", option.value)
        // console.log("label: ", option.label)
        setCountryValue(prevCountries =>
                 prevCountries.map(item => item.id == id ? {...item, value: option.value, label: option.label, countryType: option.type} : item)
            )

    }
    const changeXannotationsHandler = (option, id) => {
        setXannotationValue(prevAnnotation =>
            prevAnnotation.map(item => item.id == id ? {...item, value: option.value, label: option.label}: item)
            )
    }
    const changeYannotationsHandler = (option, id) => {
        setYannotationValue(prevAnnotation =>
            prevAnnotation.map(item => item.id == id ? {...item, value: option, label: option}: item)
        )
    }
    const changeMetricHandler = (option, id) => {
        setMetricValue(prevMetric =>
            prevMetric.map(item => item.id == id ? {...item, value: option.value, label: option.label}: item)
        )
    }
    const changeHighlightHandler = (option, id, xn) => {
        if(xn == 'x1'){
            setHighlightValue(prevHighlight =>
                prevHighlight.map(item => item.id == id ? {...item,  x1: {value: option.value, label: option.label}}: item)
            )
        }
        if(xn == 'x2'){
            setHighlightValue(prevHighlight =>
                prevHighlight.map(item => item.id == id ? {...item,  x2: {value: option.value, label: option.label}}: item)
            )
        }
    }
    const changeColorHandler = (color, id) => {
        setCountryValue(prevCountries =>
            prevCountries.map(item => item.id == id ? {... item, color : color}: item)
       )
    }
    const changeChartTypeHandler = (type, id) => {
        setCountryValue(prevCountries =>
            prevCountries.map(item => item.id == id ? {... item, type : type}: item)
        )
        setChartModal(false)
    }
    const deleteCountryHandler = (id) => {
        let updatedItems = countryValue.filter(item => item.id != id)
        updatedItems = updatedItems.map((item, index) =>  ({...item, id: index}))
        // updatedItems.map(item => (item.id > id) ? ({...item, id: item.id - 1}) : item)
        setCountryValue(updatedItems)
    }
    const deleteXannotationHandler = (id) => {
        let updatedItems = xAnnotationValue.filter(item => item.id != id)
        updatedItems = updatedItems.map((item, index) =>  ({...item, id: index}))
        // updatedItems.map(item => (item.id > id) ? ({...item, id: item.id - 1}) : item)
        setXannotationValue(updatedItems)
    }
    const deleteYannotationHandler = (id) => {
        let updatedItems = yAnnotationValue.filter(item => item.id != id)
        updatedItems = updatedItems.map((item, index) =>  ({...item, id: index}))
        // updatedItems.map(item => (item.id > id) ? ({...item, id: item.id - 1}) : item)
        setYannotationValue(updatedItems)
    }
    const deleteHighlightHandler = (id) => {
        let updatedItems = highlightValue.filter(item => item.id != id)
        updatedItems = updatedItems.map((item, index) =>  ({...item, id: index}))
        // updatedItems.map(item => (item.id > id) ? ({...item, id: item.id - 1}) : item)
        setHighlightValue(updatedItems)
    }
    const deleteMetricHandler = (id) => {
        let updatedItems = metricValue.filter(item => item.id != id)
        updatedItems = updatedItems.map((item, index) =>  ({...item, id: index}))
        // updatedItems.map(item => (item.id > id) ? ({...item, id: item.id - 1}) : item)
        setMetricValue(updatedItems)
    }



    const exportHandler = (type) => {
        let finalData = []
        for(let i = 0; i < xdata.length; i++){
            let object = {}
            object.date = xdata[i]
            ydata.forEach(item => {
                object[item.name] = item.data[i]
            })
            finalData.push(object)
        }

        const worksheet = XLSX.utils.json_to_sheet(finalData);

        // Crea un libro de trabajo (workbook) y agrega la hoja de trabajo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        if(type == 'excel'){
            XLSX.writeFile(workbook, `${meta.indicator}.xlsx`);
        } else if(type == 'csv'){
            XLSX.writeFile(workbook, `${meta.indicator}.csv`, {bookType: 'csv'});
        }
        // Genera un archivo Excel y lo descarga
    }

    const openLabelModal = (id) => {
        setLabelId(id)
        setAddLabelModal(true)
    }

    const addLabelHandler = (id, value) => {

        setYannotationValue(prevAnnotations =>
            prevAnnotations.map(item => item.id == id ? {... item, text : value}: item)
       )
    }

  return (
    <div className='viewer-container'>
      <Row className='m-0 h-100 overflow-hidden'>
        <Col md={3} style={{borderRight: '1px solid #DDDDDD', height: '100%', overflowY: 'auto'}} className='p-0 overflow-auto'>
            <div style={{background: '#F2F2F2'}}>
                <div className='d-flex justify-content-between align-items-center'>
                    {userInfo && isSaved && (
                        <p className='viewer-breadcrumb m-0 px-4'>
                            <i style={{color: '#787878'}} className="fa-solid fa-chevron-right pe-2 pt-4"></i>
                            {isSaved.folder_name}
                        </p>
                    )}
                    {/* <i className="fa-solid fa-floppy-disk pt-4 pe-4"></i> */}
                </div>
                <div className='viewer-menu-header'>
                    <div onClick={e => menuHandler('settings')}>
                        <i className={`fa-solid fa-sliders ${menuOption == 'settings' && 'viewer-active'}`}></i>
                        <p className={`${menuOption == 'settings' && 'viewer-active'}`}>Settings</p>
                    </div>
                    <div onClick={e => menuHandler('lines')}>
                        <i className={`fa-solid fa-arrow-trend-up ${menuOption == 'lines' && 'viewer-active'}`}></i>
                        <p className={`${menuOption == 'lines' && 'viewer-active'}`}>Lines</p>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className='feester'>
                    <Spinner animation="border" />
                </div>
            ): error ? (
                <Message variant={'danger'}>{error}</Message>
            ): menuOption == 'settings' ? (
                <div className='px-4 pt-5'>

                        {userInfo && (
                            <div className='pb-2'>
                                <p className='custom-select-label'>Serie Name:</p>

                                <div className='d-flex align-items-center pb-2'>
                                    <input disabled={nameDisabled} value={nameValue} onChange={e => setNameValue(e.target.value)} className='viewer-input' placeholder='Series name' type="text" />
                                    <i onClick={e => setNameDisabled(!nameDisabled)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-solid fa-pen ps-2 pe-1"></i>
                                </div>
                            </div>
                        )}

                        <div className='pb-2'>
                            <p className='custom-select-label'>Select Unit:</p>
                            <Select
                                className="custom-select-container"
                                classNamePrefix="custom-select"
                                placeholder={'Select unit'}
                                value={unitValue}
                                options={unitOptions}
                                onChange={option => changeUnitHandler(option)}
                                />
                        </div>

                        {/* <div className='pb-2'>
                            <p className='custom-select-label'>Select Government Level:</p>
                            <Select
                                className="custom-select-container"
                                classNamePrefix="custom-select"
                                isMulti={true}
                                placeholder={'Select unit'}
                                value={government}
                                options={governmentOptions}
                                onChange={option => setGovernment(option)}
                                />
                        </div> */}

                        <div className='pb-2'>
                            <p className='custom-select-label'>Select Frequency:</p>
                            <Select
                                className="custom-select-container"
                                classNamePrefix="custom-select"
                                placeholder={'Select frequency'}
                                value={frequencyValue}
                                options={frequencyOptions}
                                onChange={option => setFrequencyValue(option)}
                                />
                        </div>

                        <div className='pb-2'>
                            <p className='custom-select-label'>Select Country/Region:</p>
                            {countryValue.map((item, index) => (
                                <div key={index} className='d-flex align-items-center pb-2'>
                                    <Select
                                        className="custom-select-container"
                                        classNamePrefix="custom-select"
                                        options={countryOptions}
                                        value={item}
                                        placeholder={'Select country'}
                                        onChange={option => changeCountryHandler(option, item.id)}
                                    />
                                    <i onClick={(e) => deleteCountryHandler(item.id)} style={{cursor: 'pointer'}} className="fa-regular fa-trash-can ps-2 pe-1"></i>
                                    <Form.Control
                                        type="color"
                                        className='custom-color-picker  me-1'
                                        id="exampleColorInput"
                                        // defaultValue={(item.color) ? item.color: defaultColors[index]}
                                        value={item.color}
                                        title="Choose your color"
                                        onChange={color => changeColorHandler(color.target.value, item.id)}
                                    />
                                    <i onClick={e => {setChartType(item.type); setChartId(item.id); setChartModal(true)}} style={{color: '#818181', cursor: 'pointer'}} className={`fa-solid fa-chart-${item.type}`}></i>
                                </div>
                            ))}

                        </div>

                        <div onClick={addCountryHandler} className='viewer-action'>
                            <i className="fa-regular fa-square-plus"></i> Add country-region
                        </div>

                        <div className='py-5'>
                        <Form>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Show legends"
                                className='viewer-switch'
                                checked={legendValue}
                                onChange={option => setLegendValue(option.target.checked)}
                            />

                        </Form>
                        </div>
                        {userInfo && isSaved && (
                            <div className='d-flex  justify-content-center mb-5'>
                                {loadingUpdate ? (
                                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                                ): error ? (
                                    <>
                                        <Message variant={'danger'}>{error}</Message>
                                        <div onClick={updateHandler} className='button-dark-empty w-100'>
                                            Apply Changes
                                        </div>
                                    </>
                                ): (
                                    <div className='w-100'>
                                        <div onClick={updateHandler} className='button-dark-empty w-100 mb-2'>
                                            Apply Changes
                                        </div>
                                        {success && (
                                            <Message variant={'success'}>Changes applied succesfully</Message>
                                        )}
                                    </div>
                                )}

                            </div>
                        )}

                        

                </div>
            ) : (
                <div className='px-4 pt-5'>
                        <div className='pb-2'>
                            <p className='custom-select-label'>Add Metric:</p>

                            {metricValue.map((item, index) => (
                                <div key={index} className='d-flex align-items-center pb-2'>
                                    <Select
                                        className="custom-select-container"
                                        classNamePrefix="custom-select"
                                        options={metricOptions}
                                        value={item}
                                        placeholder={'Select metric'}
                                        onChange={option => changeMetricHandler(option, item.id)}
                                    />
                                    <i onClick={(e) => deleteMetricHandler(item.id)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-regular fa-trash-can ps-2 pe-1"></i>
                                </div>
                            ))}

                            <div onClick={addMetricHandler} className='viewer-action'>
                                <i className="fa-regular fa-square-plus"></i> Add other metric
                            </div>

                        </div>

                        <div className='pb-2'>
                            <p className='custom-select-label'>x-axis Annotations:</p>

                            {xAnnotationValue.map((item, index) => (
                                <div key={index} className='d-flex align-items-center pb-2'>
                                    <Select
                                        className="custom-select-container"
                                        classNamePrefix="custom-select"
                                        options={xAnnotationsOptions}
                                        value={item}
                                        placeholder={'Select annotation'}
                                        onChange={option => changeXannotationsHandler(option, item.id)}
                                    />
                                    <i onClick={(e) => deleteXannotationHandler(item.id)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-regular fa-trash-can ps-2 pe-1"></i>
                                </div>
                            ))}

                            <div onClick={addXannotationHandler} className='viewer-action'>
                                <i className="fa-regular fa-square-plus"></i> Add other x-axis annotation
                            </div>
                        </div>

                        <div className='pb-2'>
                            <p className='custom-select-label'>y-axis Annotations:</p>

                            {yAnnotationValue.map((item, index) => (
                                <>
                                    <div key={index} className='d-flex align-items-center'>
                                        <input value={item.value} onChange={option => changeYannotationsHandler(option.target.value, item.id)}  className='viewer-input' type="number" />
                                        <i onClick={(e) => openLabelModal(item.id)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-solid fa-font ps-2"></i>
                                        <i onClick={(e) => deleteYannotationHandler(item.id)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-regular fa-trash-can ps-1 pe-1"></i>
                                    </div>
                                    <p className='viewer-label-annotation pb-2'>Label: {item.text}</p>
                                </>
                            ))}

                            <div onClick={addYannotationHandler} className='viewer-action'>
                                <i className="fa-regular fa-square-plus"></i> Add other y-axis annotation
                            </div>
                        </div>

                        <div className='pb-5'>
                            <p className='custom-select-label'>Highlight Sections:</p>
                            {highlightValue.map((item, index) => (

                                <div className='d-flex align-items-center py-2 border-bottom'>
                                    <div style={{flex: '1'}}>
                                        <Select
                                            className="custom-select-container pb-2"
                                            classNamePrefix="custom-select"
                                            placeholder={'Select highlight'}
                                            value={item.x1}
                                            options={xAnnotationsOptions}
                                            onChange={option => changeHighlightHandler(option, item.id, 'x1')}
                                            />
                                        <Select
                                            className="custom-select-container"
                                            classNamePrefix="custom-select"
                                            placeholder={'Select highlight'}
                                            value={item.x2}
                                            options={xAnnotationsOptions}
                                            onChange={option => changeHighlightHandler(option, item.id, 'x2')}
                                            />
                                    </div>
                                    <div>
                                        <i onClick={(e) => deleteHighlightHandler(item.id)} style={{cursor: 'pointer', color: '#D9D9D9'}} className="fa-regular fa-trash-can ps-2 pe-1"></i>
                                    </div>
                                </div>

                            ))}



                            <div onClick={addHighlightHandler} className='viewer-action pt-1'>
                                <i className="fa-regular fa-square-plus"></i> Add other highlighted section
                            </div>
                        </div>


                        {userInfo && isSaved && (
                            <div className='d-flex  justify-content-center mb-5'>
                                {loadingUpdate ? (
                                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                                ): error ? (
                                    <>
                                        <Message variant={'danger'}>{error}</Message>
                                        <div onClick={updateHandler} className='button-dark-empty w-100'>
                                            Apply Changes
                                        </div>
                                    </>
                                ): (
                                    <div className='w-100'>
                                        <div onClick={updateHandler} className='button-dark-empty w-100 mb-2'>
                                            Apply Changes
                                        </div>
                                        {success && (
                                            <Message variant={'success'}>Changes applied succesfully</Message>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}


                    </div>
            )}
        </Col>
        <Col md={9} style={{height: '100%' ,overflowY: 'auto'}} className='p-0'>
            <div className='viewer-header-container'>
                <div>
                    <div className='viewer-header-icons'>
                        <i onClick={e => addFolderHandler()} className="fa-solid fa-folder-plus"></i>
                        {/* {!isSaved && (
                            <i onClick={e => favoriteHandler()} className="fa-solid fa-star"></i>
                        )} */}
                        {/* <i onClick={e => shareHandler()} className="fa-solid fa-share"></i> */}
                    </div>
                </div>
                <div style={{flex: '1'}} className='text-center mt-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <h1 style={{fontSize: '40px'}} className='search-title'>{meta.indicator}</h1>
                        {userInfo && isSaved && (
                            <OverlayTrigger
                                placement={'right'}
                                overlay={
                                    <Tooltip>
                                        Serie saved in <strong className='text-white'>{isSaved.folder_name}</strong>
                                    </Tooltip>
                                }
                                >
                                <img src="/static/icons/disk.svg" className='ps-3' style={{height: '25px', width: 'auto' }} alt="" />
                            </OverlayTrigger>
                        )}
                    </div>
                    <p style={{color: '#6A6A6A'}} className='comment fw-normal'>{definitionValue}</p>
                </div>
                <i onClick={e => closeHandler()} style={{color: '#676767', fontSize: '24px', cursor: 'pointer'}} className="fa-solid fa-xmark"></i>
            </div>
            <div style={{backgroundColor: 'rgba(250, 250, 250, 0.9)'}} className='px-5 py-2'>
                <Chart options={chartOptions} series={series} height={'500px'} type="line"/>
                <p className='source'><b>Source:</b> {sourceValue}</p>
            </div>
            <div className='viewer-table-container'>
                <div className='d-flex justify-content-end pb-3'>
                    <i onClick={e => exportHandler('excel')} style={{color: '#797979', cursor: 'pointer'}} className="fa-solid fa-file-excel px-2"></i>
                    <i onClick={e => exportHandler('csv')} style={{color: '#797979', cursor: 'pointer'}} className="fa-solid fa-file-csv"></i>
                    {/* <i onClick={e => exportHandler('excel')} style={{color: '#2e7d32', cursor: 'pointer'}} className="fa-solid fa-file-excel px-2"></i>
                    <i onClick={e => exportHandler('csv')} style={{color: '#2e7d32', cursor: 'pointer'}} className="fa-solid fa-file-csv"></i> */}
                </div>
                <table className='viewer-table'>
                    <thead>
                        <tr>
                            {table.length > 0 && Object.keys(table[0]).map((item, index) => (
                                <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.map((item, index) => (
                            <tr key={index}>
                            {Object.keys(item).map(key => (
                                <td key={key} style={{background: (key == 'date') ? '#EBEDF1': '#ffffff'}}>
                                    {(key != 'date' && roundy(parseFloat(item[key]))) ? (
                                        roundy(parseFloat(item[key]))
                                    ): key != 'date' ? (
                                        ''
                                    ): (
                                        `${item[key].split('-')[0]} - ${item[key].split('-')[1]}`
                                    )}
                                </td>
                            ))}
                            </tr>
                        ))}


                    </tbody>
                </table>
            </div>
        </Col>
      </Row>

      {chartModal && (
          <ChartTypeModal
            closeCallback={closeChartModal}
            chartCallback={changeChartTypeHandler}
            type={chartType}
            id={chartId}
            />
        )}

        {addFolderModal && (
            // <AddFolderModal closeCallback={closeFolderModal} meta={metaSender} folders={folderOptions} />
            <AddFolderModal closeCallback={closeFolderModal} meta={metaSender} folders={folderOptions} name={nameValue} />
        )}
        {addLabelModal && (
            // <AddFolderModal closeCallback={closeFolderModal} meta={metaSender} folders={folderOptions} />
            <AddLabelModal closeCallback={closeLabelModal} addCallback={addLabelHandler} id={labelId} />
        )}

        {loginModal && (
          <LoginModal
            closeCallback={() => {setLoginModal(false)}}
            registerCallback={e => {setLoginModal(false); setRegisterModal(true)}}
            forgotCallback={e => {setLoginModal(false); setForgotModal(true)}}
            isViewer={true}/>
        )}

        {registerModal && (
          <RegisterModal closeCallback={() => {setRegisterModal(false)}} loginCallback={e => {setRegisterModal(false); setLoginModal(true)}} />
        )}


        {forgotModal && (
          <ForgotModal closeCallback={() => {setForgotModal(false)}} />
        )}
    </div>
  )
}

export default ViewerModal
