import React, {useState} from 'react'
import '../styles/general.css'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, NavDropdown, Spinner } from 'react-bootstrap'
import  LoginModal from "../components/LoginModal"
import  RegisterModal from "../components/RegisterModal"
import  ForgotModal from "../components/ForgotModal"
import  Loader from "../components/Loader"
import { logout } from '../actions/userActions'

function Header2() {
  const dispatch = useDispatch()

  const [loginModal, setLoginModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [forgotModal, setForgotModal] = useState(false)
  

  const userLogin = useSelector(state => state.userLogin)
  const {loading, error, userInfo} = userLogin

  const logoutHandler = () => {
    dispatch(logout(userInfo.refresh))
  }
  return (
    <header style={{borderBottomLeftRadius: '0'}} className='search-header'>
      <LinkContainer to={'/'}>
        <img className='search-header-image' src="/static/idb-white.svg"/>
      </LinkContainer>
        <div className='search-header-right'>
            <div className='search-header-title'>
                LMW-FISLAC
            </div>
        </div>

        
    </header>
  )
}

export default Header2
