import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import Message from './Message'
import Loader from './Loader'
import {
    deleteSerie,
} from '../actions/platformActions'
import '../styles/modals.css'

function DeleteSerieModal({meta, message, closeCallback}) {

    const dispatch = useDispatch()


    const deleteInfo = useSelector(state => state.deleteSerie)
    const {loading, error, success} = deleteInfo

    useEffect(() => {
        if(success){
            closeCallback(true)
        }
    }, [deleteInfo])

    const deleteHandler = async () => {
        // console.log('serieId: ', meta.id)
        // console.log('folderId: ', meta.folder)
        dispatch(deleteSerie(meta.id, meta.folder))
    }

  return (
    <div className='modal-container'>
        <div style={{maxWidth: '320px', borderRadius: '8px'}} className='modal-window p-4'>
            <p className='text-black p-paragraph'>
                {message}
            </p>
            {loading ? (
                <div className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>
            ): error ? (
                <>
                    <Message variant={'danger'}>{error}</Message> 
                    <div className='d-flex justify-content-center '>
                        <div onClick={e => closeCallback(false)} className='p-button-2 p-2 me-2 text-black bg-white'>No</div>
                        <div onClick={deleteHandler} className='p-button-2 p-2 ms-2'>Yes</div>
                    </div>
                </>
            ): (
                <div className='d-flex justify-content-center '>
                    <div onClick={e => closeCallback(false)} className='p-button-2 p-2 me-2 text-black bg-white'>No</div>
                    <div onClick={deleteHandler} className='p-button-2 p-2 ms-2'>Yes</div>
                </div>
            )}
        </div>
    </div>


  )
}

export default DeleteSerieModal
