import '../styles/home_platform.css'
import '../styles/dashboard.css'
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, useBlocker } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import EditTitleDashboardModal from '../components/EditTitleDashboardModal'
import EditDescriptionDashboardModal from '../components/EditDescriptionDashboardModal'
import EditImageDashboardModal from '../components/EditImageDashboardModal'
import Message from '../components/Message'

import EditWidgetText from '../components/EditWidgetText'
import EditWidgetIndicator from '../components/EditWidgetIndicator'
import EditWidgetSeries from '../components/EditWidgetSeries'

import SeriesWidget from '../components/SeriesWidget'
import TextWidget from '../components/TextWidget'
import IndicatorWidget from '../components/IndicatorWidget'

import DeleteWidgetModal from '../components/DeleteWidgetModal'
import DeleteDashboardModal from '../components/DeleteDashboardModal'

import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import {
  getDashboard,
  saveDashboard,
  getWidgets,
  deleteWidget,
  updateViewCount,
} from '../actions/platformActions'
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_DASHBOARD_NAME_RESET,
  UPDATE_DASHBOARD_DESCRIPTION_RESET,
  DELETE_WIDGET_RESET,
  DELETE_DASHBOARD_RESET,
  UPDATE_DASHBOARD_IMAGE_RESET,
  UPDATE_DASHBOARD_IMAGE_FAIL,
} from '../constants/platformConstants'
import ViewerModal from '../components/ViewerModal';


function DashboardDetailsScreen() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const {code} = useParams()

    const [editFlag, setEditFlag] = useState(false)

    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);


    useEffect(() => {
        if(code){
            dispatch(updateViewCount(code))
            dispatch(getDashboard(code))
            dispatch(getWidgets(code))
        }
        
        const handleScroll = () => {
          // console.log("asdasdg: ", stickyRef.current)
          // console.log("asdasdg: ", stickyRef?.current?.getBoundingClientRect().top)
          const stickyElement = stickyRef.current;
    
          if (stickyElement) {
            // Obtener el valor del top respecto al viewport
            const stickyTop = stickyElement.getBoundingClientRect().top;
    
            // Si el elemento alcanza la parte superior, activamos el estado sticky
            if (stickyTop <= 0) {
              setIsSticky(true);
            } else {
              setIsSticky(false);
            }
          }
        };
    
        // Agregamos un listener para el scroll
        window.addEventListener("scroll", handleScroll);
    
        // Limpiar el evento cuando el componente se desmonte
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };

    }, [])


    const dashboardInfo = useSelector(state => state.getDashboard)
    const {loading, error, dashboard} = dashboardInfo

    const dashboardId = dashboard?.id

    const widgetInfo = useSelector(state => state.getWidgets)
    const {loading: loadingWidgets, error: errorWidgets, widgets: widgetsList} = widgetInfo

    const saveInfo = useSelector(state => state.saveDashboard)
    const {loading: loadingSave, error: errorSave, success: successSave} = saveInfo

    const [initWidgets, setInitWidgets] = useState([])
    const [widgets, setWidgets] = useState([])

    useEffect(() => {
        if(widgetsList){
            setWidgets(widgetsList.map(item => {
                return {
                    id: item.id,
                    type: item.widget_type,
                    i: item.id,
                    x: item.x,
                    y: item.y,
                    w: item.width,
                    h: item.height,
                    content: item.content,
                }
            }))
            setInitWidgets(widgetsList.map(item => {
              return {
                  id: item.id,
                  type: item.widget_type,
                  i: item.id,
                  x: item.x,
                  y: item.y,
                  w: item.width,
                  h: item.height,
                  content: item.content,
              }
          }))

        }
    }, [widgetInfo])


    const [dashboardName, setDashboardName] = useState('');
    const [dashboardDescription, setDashboardDescription] = useState('');
    const [dashboardImage, setDashboardImage] = useState('');

    useEffect(() => {
        if(dashboard){
            setDashboardName(dashboard.dashboard_name)
            setDashboardDescription(dashboard.dashboard_description)
            setDashboardImage(dashboard.image)
        }
    }, [dashboardInfo])

    const [editTitleModal, setEditTitleModal] = useState(false)
    const [editDescriptionModal, setEditDescriptionModal] = useState(false)

    const [deleteWidgetModal, setDeleteWidgetModal] = useState(false)
    const [deleteDashboardModal, setDeleteDashboardModal] = useState(false)

    const [editImageModal, setEditImageModal] = useState(false)

    const ResponsiveReactGridLayout = WidthProvider(Responsive);

    const [editTextWidgetModal, setEditTextWidgetModal] = useState(false)
    const [editIndicatorWidgetModal, setEditIndicatorWidgetModal] = useState(false)
    const [editSeriesWidgetModal, setEditSeriesWidgetModal] = useState(false)
    const [selectedWidget, setSelectedWidget] = useState(null)
    const [selectedText, setSelectedText] = useState(null)
    const [viewActions, setViewActions] = useState(false)

    let [key, setKey] = useState('a');

    const defaultProps = {
        className: "layout",
        rowHeight: 100,
        // onLayoutChange: (layout: any, layouts: any) => {},
        cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        containerPadding: [0, 0],
        margin: [20, 20]
    };
    // const incrementChar = (c) => {
    //     setKey(key + 1);
    // };

    const incrementChar = (c) => {
        setKey(String.fromCharCode(c.charCodeAt(0) + 1))
    }




  const [layout, setLayout] = useState(widgets.map(item => {
    return {
      i: item.i,
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
    }
  }))



  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (editFlag) {
        event.preventDefault();
        event.returnValue = ''; // Esto muestra la alerta del navegador
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Limpia el evento cuando el componente se desmonta o cuando ya no haya cambios sin guardar
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [editFlag]);


  // Lógica de navegación con el sidebar (ejemplo usando navigate() manualmente)
  const handleNavigation = (path) => {
    navigate(path);
  };



  const onLayoutChange = (layout) => {
    // console.log("onchange layout: ", layout)
    // console.log("onchange widgets: ", widgets)

    // console.log("Layout ffff: ", layout)
    // console.log("Layout item: ", layoutItem)
    // let tempItem = layoutItem.md
    // if(tempItem){

    //     setWidgets(prevState => prevState.map(item => (item.i == tempItem.i) ? {...item, x: tempItem.x, y: tempItem.y, w: tempItem.w, h: tempItem.h }: item))
    // }

    // setLayout([...layout]);
    // incrementChar(key);

    // setLayout(layout);
    let editingModals = (!editTextWidgetModal && !editIndicatorWidgetModal && !editSeriesWidgetModal)
    setShouldAutoSave(JSON.stringify(widgets) != JSON.stringify(initWidgets) && editFlag && editingModals)
  };

  const onDragStartHandler = (layout, oldItem, newItem, placeholder, e, element) => {
      
      placeholder.w = 2
    }

  const dropHandler = (layout, layoutItem, event) => {

    let type = event.dataTransfer.getData('Type')
    let content = (type == 'text') ? {body: '', background: false}:
                  (type == 'series') ? {}: {}
    let tempItem = {
        id: key,
        i: key,
        type: type,
        x: layoutItem.x,
        y: layoutItem.y,
        // w: layoutItem.w,
        w: 2,
        h: layoutItem.h,
        content: content
    }

    setWidgets([...widgets, tempItem])
    // console.log(JSON.stringify(layoutItem, ["x", "y", "w", "h"], 2));
    setLayout([...layout]);
    incrementChar(key);
  };

  const closeEditHandler = () => {
    setEditTextWidgetModal(false)
  }

  const closeEditIndicatorHandler  = () => {
    setEditIndicatorWidgetModal(false)
  }

  const closeEditSeriesHandler = () => {
    setEditSeriesWidgetModal(false)
  }

  const openEditHandler = (wid, data) => {
    setSelectedWidget(wid)
    setSelectedText(data)
    setEditTextWidgetModal(true)
  }

  const openEditIndicatorHandler = (wid) => {
    setSelectedWidget(wid)
    setEditIndicatorWidgetModal(true)
  }

  const openEditSeriesHandler = (wid) => {
    setSelectedWidget(wid)
    setEditSeriesWidgetModal(true)
  }

  const updateTextHandler = (wid, text, background) => {
    // console.log("asdfasdf: ", wid, text, background)
    setWidgets(prevState => prevState.map(item => (item.i == wid) ? {...item, content: {body: text, background: background}}: item))
    setEditTextWidgetModal(false)
  }

  const updateSeriesHandler = (wid, jsonContent) => {
    setWidgets(prevState => prevState.map(item => (item.i == wid) ? {...item, content: jsonContent}: item))
    setEditTextWidgetModal(false)
  }

  const updateIndicatorHandler = (wid, content) => {
    setWidgets(prevState => prevState.map(item => (item.i == wid) ? {...item, content: content}: item))
    setEditIndicatorWidgetModal(false)
  }

  const openEditTitleHandler = () => {
      dispatch({
          type: UPDATE_DASHBOARD_NAME_RESET
      })
      setEditTitleModal(true)
  }

  const openEditDescriptionHandler = () => {
      dispatch({
          type: UPDATE_DASHBOARD_DESCRIPTION_RESET
      })
      setEditDescriptionModal(true)
  }

  const openEditImageHandler = () => {
    setEditImageModal(true)
  }

  const closeEditTitleHandler = (isEdited) => {
      if(isEdited){
          dispatch(getDashboard(code))
      }
      setEditTitleModal(false)
  }

  const closeEditDescriptionHandler = (isEdited) => {
      if(isEdited){
          dispatch(getDashboard(code))
      }
      setEditDescriptionModal(false)
  }

  const closeEditImageHandler = (isEdited) => {
    if(isEdited){
      dispatch({
        type: UPDATE_DASHBOARD_IMAGE_RESET
      })
      dispatch(getDashboard(code))
    }
    setEditImageModal(false)
}

  const onResizeHandler = (layout, oldItem, newItem) => {
      // console.log('layout: ', layout)
      // console.log('old: ', oldItem)
      // console.log('new: ', newItem)

      setWidgets(prevState => prevState.map(item => item.i == newItem.i ? {...item, x: newItem.x, y: newItem.y, w: newItem.w, h: newItem.h}: item))
  }

    const onDragHandler = (layout, oldItem, newItem) => {
      setWidgets(prevState => prevState.map(item => item.i == newItem.i ? {...item, x: newItem.x, y: newItem.y, w: newItem.w, h: newItem.h}: item))
    }

    const saveDashboardHandler = (autoSaveEnable = false) => {
      if(JSON.stringify(initWidgets) != JSON.stringify(widgets)){

        let newWidgets = widgets.filter(item => isNaN(item.id))
        newWidgets = newWidgets.map(item => {
            return {
                dashboard: dashboardId,
                widget_type: item.type,
                x: item.x,
                y: item.y,
                width: item.w,
                height: item.h,
                content: item.content,
            }
        })

        let updateWidgets = widgets.filter(item => !isNaN(item.id))
        updateWidgets = updateWidgets.map(item => {
            return {
                id: item.id,
                widget_type: item.type,
                x: item.x,
                y: item.y,
                width: item.w,
                height: item.h,
                content: item.content,
            }
        })

        // console.log('newWidgets: ', newWidgets)
        // console.log('updateWidgets: ', updateWidgets)

        dispatch(saveDashboard(newWidgets, updateWidgets))
      }
      if (autoSaveEnable){
        setEditFlag(true)
        setInitWidgets(widgets)
      }else {
        setEditFlag(false)
      }
    }

    useEffect(() => {
      if(successSave){
        dispatch({
          type: DELETE_WIDGET_RESET,
        })
        dispatch(getWidgets(code))
      }
    }, [saveInfo])



    const editDashboardHandler = () => {
      setEditFlag(true)
    }

    const deleteWidgetHandler = (wid) => {
      dispatch({
        type: DELETE_WIDGET_RESET,
      })
      setSelectedWidget(wid)
      setDeleteWidgetModal(true)
    }

    const closeDeleteWidgetHandler = (isDeleted, wid) => {
      setDeleteWidgetModal(false)
      if(isDeleted){
        setWidgets(widgets.filter(item => item.id != wid))
      }
    }

    const closeDeleteDashboardHandler = (isDeleted, did) => {
      setDeleteDashboardModal(false)
      if(isDeleted){
        navigate('/dashboard')
      }
    }

    const deleteDashboardHandler = () => {
      dispatch({
        type: DELETE_DASHBOARD_RESET,
      })
      setDeleteDashboardModal(true)
    }

    const [shouldAutoSave, setShouldAutoSave] = useState(false)

    const autoSave = async () => {
      if (shouldAutoSave) {
          try {
              saveDashboardHandler(true)
          } catch (error) {
              console.error("Error saving data inside autosave")
          }
      }
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        autoSave();  // Llama a la función de autoguardado si el if es true
      }, 120000);  // Intervalo de 2 minutos (120000 ms)

      // Limpiar el intervalo cuando el componente se desmonte
      return () => clearInterval(intervalId);
    }, [shouldAutoSave, widgets]);  // Ejecuta el autoguardado cuando los datos o la condición cambian

    

  return (
    <div >
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7'}}>
            <SideBar onNavigate={handleNavigation} editFlag={editFlag} />
            <div className='dashboard-main-container'>

              {/* title */}
              <div className='d-flex align-items-center justify-content-between pb-4'>
                <div className='d-flex align-items-center'>
                  {dashboardImage && (
                    <img onClick={openEditImageHandler} style={{cursor: 'pointer', height: '3em', width: 'auto'}} src={`/static/dashboard-icons/${dashboardImage}`} className='pe-4' />
                  )}
                  <h1 className='p-title me-4'>
                      {loading ? (
                          <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                      ) : error ? (
                          <Message variant='danget'>{error}</Message>
                      ) : (
                          dashboardName
                      )}
                  </h1>
                  {editFlag && (
                    <i onClick={openEditTitleHandler} className="fa-solid fa-pen pe-4" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                  )}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip id="help-tooltip">
                            Here goes the title.
                        </Tooltip>
                    }
                  >
                  <i className="fa-solid fa-circle-info" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                  </OverlayTrigger>

                  <div className='d-flex align-items-center' onMouseLeave={e => setViewActions(false)}>
                    <i onMouseEnter={e => setViewActions(true)}  className="fa-solid fa-ellipsis ps-4" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '20px'}}></i>
                    {viewActions && (
                      <div className='px-3'>
                        <i onClick={deleteDashboardHandler} className="fa-solid fa-trash" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                      </div>
                    )}
                  </div>

                </div>

                <div className='d-flex align-items-center'>

                  {JSON.stringify(widgets) != JSON.stringify(initWidgets) && editFlag && (
                    <>
                      <Message variant='danger'>You have unsaved changes</Message>
                      <img src="/static/icons/disk.svg" className='ps-3' style={{height: '25px', width: 'auto' }} alt="" />
                    </>
                  )}
                </div>
              </div>

              {/* Descripcion */}
              <div className='d-flex align-items-center'>
                <p className='p-paragraph mb-0 pe-4' style={{color: '#A5A5A5'}}>
                    {loading ? (
                            <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                        ) : error ? (
                            <Message variant='danget'>{error}</Message>
                        ) : (
                        dashboardDescription
                    )}
                </p>
                {editFlag && (
                  <i onClick={openEditDescriptionHandler} className="fa-solid fa-pen me-4" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                )}
                <OverlayTrigger
                  placement="top"
                  overlay={
                      <Tooltip id="help-tooltip">
                          Here goes the description.
                      </Tooltip>
                  }
                >
                <i className="fa-solid fa-circle-info me-2" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                </OverlayTrigger>
              </div>

              {/* !!!!!!!!!!!!!!!!! WIDGETS */}

              <div className='d-flex justify-content-between align-items-center pt-3 pb-3'>
                <div className='d-flex align-items-center'>
                    {editFlag && (
                      <>
                      
                        <h1 className='p-subtitle'>Widgets</h1>
                        <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="help-tooltip">
                                Drag and drop one of the following widgets into the dashboard area.
                            </Tooltip>
                        }
                        >
                        <i className="fa-solid fa-circle-info ps-3" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                        </OverlayTrigger>
                      </>  
                    )}
                </div>
                <div className='d-flex align-items-center'>
                  {!editFlag && (
                    <>
                      <i className="fa-solid fa-eye pe-2"></i>
                      <p style={{fontSize: '16px'}} className='m-0'>Lecture Mode</p>
                    </>
                  )}
                  {editFlag && (
                    <>
                      <i className="fa-solid fa-pen-to-square pe-2"></i>
                      <p style={{fontSize: '16px'}} className='m-0'>Edit Mode</p>
                    </>
                  )}
                </div>
                <div className='me-3'>
                  {editFlag ? (
                    <div style={{width: '10em'}} onClick={e => saveDashboardHandler(false)} className='btn btn-dark text-white text-nowrap'>
                      <i className="fa-solid fa-floppy-disk pe-2 text-white"></i>
                      Save Dashboard
                    </div>
                  ): (
                    <div onClick={editDashboardHandler} className='btn btn-dark text-white'>
                      <i className="fa-solid fa-pen-to-square text-white pe-2"></i>
                      Edit Dashboard
                    </div>
                  )}
                  {/* <div onClick={saveDashboardHandler} className='btn btn-dark text-white'>Save Dashboard</div> */}
                </div>
              </div>
              {editFlag && (
                <div ref={stickyRef} className={`default-widgets-container ${isSticky ? "sticky-active" : ""}`}>


                  <div
                    className="default-widget"
                    draggable={true}
                    unselectable="on"
                  // this is a hack for firefox
                  // Firefox requires some kind of initialization
                  // which we can do by adding this attribute
                  // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                    onDragStart={e => e.dataTransfer.setData("Type", "text")}
                  >
                    <Row className='w-100 h-100'>
                      <Col className='default-widget-left'>
                        <p className='default-widget-title'>Text</p>
                        <p className='default-widget-description'>Card with text for descriptions or annotations</p>
                      </Col>
                      <Col className='d-flex align-items-center justify-content-center'>
                        <p className='default-widget-content m-0'>My Text</p>
                      </Col>
                    </Row>
                  </div>


                  <div
                      className="default-widget"
                      draggable={true}
                      unselectable="on"
                    // this is a hack for firefox
                    // Firefox requires some kind of initialization
                    // which we can do by adding this attribute
                    // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                      onDragStart={e => e.dataTransfer.setData("Type", "indicator")}
                  >
                    <Row className='w-100 h-100'>
                      <Col className='default-widget-left'>
                        <p className='default-widget-title'>Indicator</p>
                        <p className='default-widget-description'>Card with main figure of one indicator</p>
                      </Col>
                      <Col className='d-flex align-items-center justify-content-center flex-column'>
                        <p className='dwidget-indicator-title'>Indicator</p>
                        {/* <p className='dwidget-indicator-country'>Argentina</p> */}
                        <p className='dwidget-indicator-value'>54</p>
                        {/* <p className='dwidget-indicator-unit'>unit - (country)</p> */}

                      </Col>
                    </Row>
                  </div>


                  <div
                      className="default-widget"
                      draggable={true}
                      unselectable="on"
                    // this is a hack for firefox
                    // Firefox requires some kind of initialization
                    // which we can do by adding this attribute
                    // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                      onDragStart={e => e.dataTransfer.setData("Type", "series")}
                  >
                    <Row className='w-100 h-100'>
                      <Col className='default-widget-left'>
                        <p className='default-widget-title'>Series</p>
                        <p className='default-widget-description'>Card with one specific indicator chart </p>
                      </Col>
                      <Col className='d-flex align-items-center justify-content-center'>
                        {/* <p className='default-widget-content'>My Text</p> */}
                        <img src="/static/dashboard-widget-series.svg" />
                      </Col>
                    </Row>
                  </div>



                </div>
              )}
              <div className="border-top my-3 pt-4">
                {loadingSave ? (
                  <div className='w-100 d-flex justify-content-center'>
                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                  </div>
                ): (

                  <ResponsiveReactGridLayout
                  id="react-grid-layout"
                  {...defaultProps}
                    className={`${editFlag && 'dashboard-edit-container'} hhuu`}
                    compactType={null}
                    // cols={{lg: 2}}
                    // breakpoints={{lg: 1200}}
                    // style={{ background: "red" }}
                    // layouts={layouts}
                    // onDragStart={(layout, oldItem, newItem, placeholder) => {
                    //   placeholder.style.backgroundColor = 'green'
                    // }}
                    measureBeforeMount={false}
                    isResizable={editFlag}
                    isDraggable={editFlag}
                    isEdited={editFlag}
                    isDroppable={editFlag}
                    // isBounded={editFlag}
                    // useCSSTransforms={mounted}
                    // compactType={compactType}
                    preventCollision={true}
                    onLayoutChange={onLayoutChange}
                    onDragStop={onDragHandler}
                    onDrag={onDragStartHandler}
                    onResizeStop={onResizeHandler}
                    // onBreakpointChange={onBreakpointChange}
                    onDrop={dropHandler}
                    // isDroppable
                    >
                        {widgets.length == 0 && (
                          <div className='d-none' key={'default'} data-grid={{x: 6, y: 1, w: 1, h: 1}}>
                          </div>
                        )}
                        {widgets.length > 0 && widgets.map(item => (
                          <div key={item.i} data-grid={{x: item.x, y: item.y, w: item.w, h: item.h, resizeHandles: ['sw','nw','se','ne']}}>
                              {item.type == 'series' ? (
                                  <SeriesWidget id='react-grid-item' editFlag={editFlag} wid={item.i} data={item.content} openCallback={openEditSeriesHandler} deleteCallback={deleteWidgetHandler} />
                              ): item.type == 'text' ? (
                                  <TextWidget id='react-grid-item' editFlag={editFlag} wid={item.i} data={item.content} openCallback={openEditHandler} deleteCallback={deleteWidgetHandler} />
                              ): (
                                  <IndicatorWidget id='react-grid-item' editFlag={editFlag} wid={item.id} data={item.content} openCallback={openEditIndicatorHandler} deleteCallback={deleteWidgetHandler} />
                              )}
                          </div>
                        ))}

                  </ResponsiveReactGridLayout>
                )}
              </div>

            </div>
        </div>

        <SideResponsiveOpen />
        <Footer />

        {editTextWidgetModal && (
            <EditWidgetText closeCallback={closeEditHandler} updateCallback={updateTextHandler} data={selectedText} wid={selectedWidget} />
        )}
        {editIndicatorWidgetModal && (
            <EditWidgetIndicator closeCallback={closeEditIndicatorHandler} updateCallback={updateIndicatorHandler} data={widgets?.find(item => item.id == selectedWidget).content} wid={selectedWidget} />
        )}
        {editSeriesWidgetModal && (
            <EditWidgetSeries closeCallback={closeEditSeriesHandler} updateCallback={updateSeriesHandler}  wid={selectedWidget}/>
        )}



        {editTitleModal && (
            <EditTitleDashboardModal closeCallback={closeEditTitleHandler} title={dashboard?.dashboard_name} id={dashboardId} />
        )}

        {editDescriptionModal && (
            <EditDescriptionDashboardModal closeCallback={closeEditDescriptionHandler} description={dashboard?.dashboard_description} id={dashboardId} />
        )}

        {editImageModal && (
            <EditImageDashboardModal closeCallback={closeEditImageHandler} image={dashboard?.image} id={dashboardId} />
        )}

        {deleteWidgetModal && (
            <DeleteWidgetModal closeCallback={closeDeleteWidgetHandler} wid={selectedWidget} did={dashboardId} />
        )}
        {deleteDashboardModal && (
            <DeleteDashboardModal closeCallback={closeDeleteDashboardHandler} did={dashboardId} />
        )}
    </div>
  )
}

export default DashboardDetailsScreen