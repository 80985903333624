import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Row, Col } from 'react-bootstrap'
import { login } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import '../styles/modals.css'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function LoginModal({closeCallback, registerCallback, forgotCallback, isViewer=false}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const {loading, error, userInfo} = userLogin

    useEffect(()=> {
        if(userInfo){
            if(isViewer){
                closeCallback()
            }else{
                navigate('/home')
            }
        }
    }, [userLogin])

    const loginHandler = () => {
        dispatch(login(loginEmail, loginPassword))
    }
    const keyPressHandler = (event) => {
        if(event.key == 'Enter'){
            loginHandler()
        }
    }

    function openGoogleAuthPopup() {
        // Abrir la ventana emergente para la autenticación de Google
        const width = 500;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        window.location.href = `${window.location.origin}/api/user/accounts/google/login/`;

        // const popup = window.open(
        //   'http://127.0.0.1:8000/api/user/accounts/google/login/',
        //   'google-auth',
        //   `width=${width},height=${height},top=${top},left=${left}`
        // );

        // if (!popup) {
        //   alert("La ventana emergente ha sido bloqueada por el navegador.");
        //   return;
        // }


      }

  return (
    <div className='modal-container'>
        <div className='modal-window'>
            <div className='login-header'>
            <div className='d-flex justify-content-between'>
                <span></span>
            <h1 className='normal-title text-center text-white py-5'>Log In</h1>
            <i onClick={e => closeCallback()} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-white p-3 fs-4"></i>
            </div>
            </div>


            <div className='pb-5'>

            <p className='login-signup py-3'>Don't have an account? <span onClick={registerCallback}>Signup Now</span></p>

            <Row className='px-5'>
                <Col style={{borderRight: '1px solid #EAEAEA'}}>
                    {error && (
                        <Message variant={'danger'}>
                            {error}
                        </Message>
                    )}

                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center h-100 mx-5 px-5'>
                            <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                        </div>
                    ): (
                        <>
                        <div className='py-3'>
                            <p className='login-label'>E-mail:</p>
                            <div className='login-input'>
                                <i style={{color: '#898989'}} className="fa-solid fa-user me-2"></i>
                                <input value={loginEmail} onChange={e => setLoginEmail(e.target.value)} type="text" placeholder='Your email' />
                            </div>
                        </div>

                        <div className='py-3'>
                        <p className='login-label'>Password:</p>
                        <div className='login-input'>
                            <i style={{color: '#898989'}} className="fa-solid fa-key me-2"></i>
                            <input onKeyDown={keyPressHandler} value={loginPassword} onChange={e => setLoginPassword(e.target.value)} type="password" placeholder='Your password' />
                        </div>
                        </div>

                        <p onClick={forgotCallback} className='login-signup pb-4 text-start'><span style={{color: '#717171'}}>Forgot Password?</span></p>

                        <div onClick={loginHandler} className={`login-button mt-5 ${
                            (
                                loginEmail.length > 0 &&
                                loginPassword.length > 0
                            ) ? '': 'button-disabled'
                        }`}>Log In</div>
                        </>
                    )}

                </Col>
                <Col className='d-flex align-items-center flex-column justify-content-center p-5'>
                    <p style={{color: '#8B8B8B', fontSize: '18px'}}>Other access methods:</p>
                    {/* <div onClickCapture={openGoogleAuthPopup} className='login-card button-disabled'> */}
                    <div onClickCapture={openGoogleAuthPopup} className='login-card'>
                        <img src="/static/Google.svg"/>
                        <p className='p-1 text-nowrap'>Access with Google</p>
                    </div>
                </Col>
            </Row>
            </div>
        </div>
    </div>


  )
}

export default LoginModal
