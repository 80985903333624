import '../styles/modals.css'
import React, { useEffect, useState, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';

import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import { Spinner, Form } from 'react-bootstrap'

function EditWidgetText({wid, data, closeCallback, updateCallback}) {

  const dispatch = useDispatch()
  
  const [text, setText] = useState(data.body)
  const [background, setBackground] = useState(data.background)

  const editorRef = useRef(null);
  
  const handleText = (content) => {
    setText(content)
  }

  // useEffect(() => {
  //   console.log('wid: ', wid)
  //   console.log('text: ', text)
  // }, [text])

  const backgroundHandler = (e) => {
    setBackground(e.target.checked)
  }

  

  return (
    <div className='modal-container'>
        <div className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

          <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Text</h1>

           <p className='p-paragraph'>
                Select the folder where you want to add this series
            </p>

            <div className='w-100'>
              <Form.Check // prettier-ignore
                type={'checkbox'}
                // id={`default-${type}`}
                className={`p-paragraph text-start mb-3`}
                label='Transparent background'
                checked={background}
                onChange={e => backgroundHandler(e)}
              />
            </div>

            <Editor
                apiKey='awvf0ud3r4xmskmpp7r3aooqw1f85ukerl49ay7vor4tzhmo'
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={data.body}
                init={{
                height: 300,
                menubar: true,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                content_style: 'body { font-family: "Open Sans"; font-size:14px; text-align: center }'
                }}
                onEditorChange={handleText}
            />
           
          
            
            
            <div onClick={e => updateCallback(wid, text, background)} style={{width: '100%'}} className='login-button mt-3'>Update Text</div>
              

              
        </div>
      </div>
  )
}

export default EditWidgetText
