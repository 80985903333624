import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import '../styles/general.css'
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux'
import { roundy } from './Utilities'
import {
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Row, Col,
    Spinner,
} from 'react-bootstrap'
import  LoginModal from "../components/LoginModal"
import  RegisterModal from "../components/RegisterModal"
import  ForgotModal from "../components/ForgotModal"
import  AddFolderModal from "../components/AddFolderModal"
import  MessageModal from "../components/MessageModal"
import {
    updateCard,
    setIndicator,
} from '../actions/searchActions'
import {
    getFolders,
} from '../actions/platformActions'
import { saveSeries } from '../actions/viewerActions'
import ViewerModal from '../components/ViewerModal'
import { SELECTED_INDICATOR } from '../constants/searchConstants'
import { SAVE_SERIES_RESET } from '../constants/viewerConstants'

function SearchCard({meta, data, countries}) {
    // console.log('metaCard: ', meta)
    // console.log('countries: ', countries)

    const dispatch = useDispatch()

    const [messageModal, setMessageModal] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalMessage, setModalMessage] = useState('')

    const [saveType, setSaveType] = useState('')

    const defaultColors =  [
        "#FF5733", // Rojo intenso
        "#33FF57", // Verde lima
        "#3357FF", // Azul vibrante
        "#FF33A1", // Rosa fuerte
        "#FFBD33", // Naranja vibrante
        "#33FFF6", // Cian
        "#A633FF", // Púrpura
        "#FF3333", // Rojo brillante
        "#33FFBD", // Verde menta
        "#5733FF", // Azul púrpura
        "#FF9633", // Naranja cálido
        "#33A1FF", // Azul claro
        "#FF3384", // Fucsia
        "#96FF33", // Verde lima claro
        "#8C33FF", // Violeta
        "#FF3380", // Magenta
        "#33FF57", // Verde brillante
        "#FF5733", // Naranja rojizo
        "#33FFD7", // Turquesa
        "#FF33E3"  // Rosa neón
    ];

    // const [viewerMeta, setViewerMeta] = useState(meta)
    // setCountryValue(prevCountries => [...prevCountries, { id: prevCountries.length, value: '', label: '', color: defaultColors[prevCountries.length], type: 'line', countryType: ''}])
    

    useEffect(()=> {
        if(userInfo){
            dispatch(getFolders())
        }
    }, [])

    const [metaCountry, setMetaCountry] = useState([])

    

    // let finalMeta = JSON.parse(JSON.stringify(meta))
    // finalMeta.chart = {
    //     // unit: {value: meta.units[0], label: meta.units[0]},
    //     unit: {value: unitValue, label: unitValue},
    //     frequency: {value: meta.frequency, label: meta.frequency},
    //     country: metaCountry,
    //     legend: true,
    //     metrics: [{id: 0, value: '', label: ''}],
    //     xaxis: [{id: 0, value: '', label: ''}],
    //     yaxis: [{id: 0, value: '', label: '', text:''}],
    //     highlight: [{
    //         id: 0,
    //         x1: {value: '', label: ''},
    //         x2: {value: '', label: ''},
    //     }]
    // }
    

    const [loginModal, setLoginModal] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)
    const [forgotModal, setForgotModal] = useState(false)
    
    const [viewerModal, setViewerModal] = useState(false)
    const [addFolderModal, setAddFolderModal] = useState(false)

    // const [metaSender, setMetaSender] = useState(finalMeta)

    const [realData, setRealData] = useState(data)
    const [unitValue, setUnitValue] = useState(meta.units[0])
    const [frequencyValue, setFrequencyValue] = useState(meta.frequency)
    const [definitionValue, setDefinitonValue] = useState(meta.units_definitions[meta.units[0]])
    const [numCountries, setNumCountries] = useState([... new Set(realData.map(item => item.country))].length)
    const [selectedContries, setSelectedCountries] = useState([... new Set(realData.map(item => item.country))])
    const [sourcesList, setSourcesList] = useState([... new Set(realData.map(item => item.source))])
    // console.log("otherFrequencies: ", meta.other_frequencies)
    const [otherFrequenciesList, setOtherFrequencyList] = useState(meta.other_frequencies.reduce((a, b) => a.length > b.length ? a: b).replace('and', ','))
    const [xdata, setXdata] = useState([])
    const [ydata, setYdata] = useState([])
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)

    let finalMeta = JSON.parse(JSON.stringify(meta))
    finalMeta.chart = {
        // unit: {value: meta.units[0], label: meta.units[0]},
        unit: {value: unitValue, label: unitValue},
        frequency: {value: meta.frequency, label: meta.frequency},
        country: metaCountry,
        legend: true,
        metrics: [{id: 0, value: '', label: ''}],
        xaxis: [{id: 0, value: '', label: ''}],
        yaxis: [{id: 0, value: '', label: '', text:''}],
        highlight: [{
            id: 0,
            x1: {value: '', label: ''},
            x2: {value: '', label: ''},
        }]
    }

    const [metaSender, setMetaSender] = useState(finalMeta)
    
    // const [ydata, setYdata] = useState()
    // Paso 1: Agrupar los datos por país
    useEffect(() => {
        const general = realData.filter(item => item.unit == unitValue)
        
    
        const groupedData = general.reduce((acc, curr) => {
            if (!acc[curr.country]) {
            acc[curr.country] = [];
            }
            acc[curr.country].push({ date: curr.date, value: curr.value });
            return acc;
        }, {});

      if(general.length > 0){
          // Paso 2: Obtener una lista de todas las dates únicas en orden ascendente
          const x = Array.from(new Set(general.map(item => item.date))).sort();
          setXdata(x)
          setMinDate(x[0].split('-')[0])
          setMaxDate(x[x.length-1].split('-')[0])
          
          // Paso 3: Construir los ydata para cada país con null en las dates sin valores
          const y = Object.entries(groupedData).map(([country, values]) => {
              const ydata = x.map(date => {
              const valueObj = values.find(item => item.date === date);
              return valueObj ? valueObj.value : null;
              });
              return { name: country, data: ydata };
          });
          
          setYdata(y)
          setNumCountries([... new Set(general.map(item => item.country))].length)
          if(countries){

              setMetaCountry(countries?.map((item, index) => {
                return { 
                    id: index,
                    value: item.label,
                    label: item.label,
                    color: defaultColors[index],
                    type: 'line',
                    countryType: 'country'}
                }
            ))
          }else{
            setMetaCountry([])
          }
      }
      else {
        setXdata([])
        setYdata([{
            name: 'no data',
            data: []
        }])
        setMinDate('')
        setMaxDate('')
        setNumCountries(0)
      }

    }, [unitValue, realData]) 

    

    const options = {
        chart: {
        id: 'basic-line-chart',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            width: 3,
        },
        colors: ['#5171E0', '#5FB2CD', '#FFB300'],
        xaxis: {
            tickAmount: 4,
            // stepSize: 0,
            labels: {
                show: true,
                rotate: 0,
                formatter: (value) => {
                    let res = `${(value) ? value?.split('-')[0]: ''}-${(value) ? value?.split('-')[1]: ''}`
                    return res
                },
                style: {
                    fontSize: '9px',
                    fontFamily: 'Open Sans',
                    // fontWeight: 400
                }
            },
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false
            },
            categories: xdata,
        },
        yaxis: {
            show: true,
            labels: {
                show: false
            },
            axisBorder: {
                show: true
            }
        },
        grid:
        {
            show: false,
        },
        legend: {
            position: 'top',
            fontFamily: 'Open Sans',
            horizontalAlign: 'left',
            markers: {
                size: 5
            }
        },
        tooltip: {
            y: {
                formatter: function(value) {
                    return roundy(value, 2)
                }
            }
        }
    };
    
    
    const series = ydata.slice(0,3)

    const changeData = useSelector(state => state.cardUpdate)
    const {cardInfo} = changeData

    useEffect(() => {
        if(cardInfo){
            setRealData(cardInfo.data_dict)
        }
    }, [changeData])


    const changeUnitHandler = (unit) => {
        setUnitValue(unit)
        setDefinitonValue(meta.units_definitions[unit])
    }

    const changeFrequencyHandler = (frequency) => {
        // let a = otherFrequenciesList
        // if(otherFrequenciesList.includes(',')){
        let a = otherFrequenciesList.split(',').filter(item => item != frequency)
        // }else if(otherFrequenciesList.includes('and')){
        //     a = otherFrequenciesList.split('and').filter(item => item != frequency)
        // }
        a.push(frequencyValue)
        let result = a.join(',')
        setFrequencyValue(frequency)
        setOtherFrequencyList(result)
        const countryParam = (countries == null ? []: countries.map(item => item.label))
        // console.log("area: ", meta.area)
        // console.log("topic: ", meta.topic)
        // console.log("indicator: ", meta.indicator)
        // console.log("countries: ", countryParam)
        // console.log("unit: ", unitValue)

        let freq = frequency.replaceAll(' ', '')
        freq = freq[0].toUpperCase() + freq.slice(1)
        dispatch(updateCard(meta.area, meta.topic, meta.indicator, countryParam, unitValue, freq))

    }

    const downloadHandler = () => {
        const sortData = realData.map(item => (
            {
                country: item.country,
                frequency: item.frequency,
                period: item.period,
                date: item.date,
                unit: item.unit,
                definition: item.definition,
                source: item.source,
                value: item.value,
                other_frequencies: item.other_frequencies
            }
        ))
        const worksheet = XLSX.utils.json_to_sheet(sortData);

        // Crea un libro de trabajo (workbook) y agrega la hoja de trabajo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Genera un archivo Excel y lo descarga
        XLSX.writeFile(workbook, `${meta.indicator}-${frequencyValue}.xlsx`);
    }
    const viewerHandler = () => {
        setViewerModal(true)
    }

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    // const setIndicator = async (indicator) => {
    //     dispatch({
    //         type: SELECTED_INDICATOR,
    //         payload: indicator
    //     })
    // }

    

    const folderInfo = useSelector(state => state.listFolder)
    const {loading: loadingFolder, error: errorFolder, folderList} = folderInfo

    // const folderCopy = folderList ? [...folderList]: []

    const folderOptions = folderList?.map(item => ({
        value: item.id,
        label: item.folder_name,
    }))

    const favoriteHandler = () => {

        if(userInfo){
            setSaveType('favorite')
            dispatch({
                type: SAVE_SERIES_RESET,
            })
            dispatch(setIndicator(meta.indicator))
            let mainId = folderList?.filter(item => item.folder_name == 'Main Folder')[0].id
            // console.log(`default_${finalMeta.indicator}`)
            dispatch(saveSeries([mainId], finalMeta, finalMeta.indicator))
            // dispatch({
            //     type: SAVE_SERIES_RESET,
            // })
        }else{
            setLoginModal(true)
        }
    }

    const addFolderHandler = () => {
        if(userInfo){
            setSaveType('folders')
            dispatch({
                type: SAVE_SERIES_RESET,
            })
            dispatch(setIndicator(meta.indicator))
            setMetaSender(finalMeta)
            setAddFolderModal(true)
        }else{
            setLoginModal(true)
        }
    }

    const saveInfo = useSelector(state => state.saveSeries)
    const {loading, error, success} = saveInfo

    useEffect(() => {
        if(success && indicator == meta.indicator){
            setModalTitle('Saved')
            // console.log("saveType", saveType)
            if(saveType == 'favorite'){
                setModalMessage('The series has been successfully saved to the "Main Folder".')
            }else{
                setModalMessage('The series has been successfully in folders.')
            }
            setMessageModal(true)
            // dispatch({
            //     type: SAVE_SERIES_RESET,
            // })
        }
    }, [saveInfo])

    const closeViewerHandler = () => {
        setViewerModal(false)
    }
    const closeFolderHandler = () => {
        setAddFolderModal(false)
        // dispatch({type: SAVE_SERIES_RESET})
    }
    const closeMessageHandler = () => {
        setMessageModal(false)
        setModalTitle('')
        setModalMessage('')
    }
    const selectedIndicator = useSelector(state => state.selectedIndicator)
    const {indicator} = selectedIndicator

  return (
    <div className='search-card'>
        <div style={{borderBottom: '1px solid #D9D9D9'}} className='d-flex justify-content-between align-items-center py-3 px-4'>
            <div className='search-card-title'>{meta.indicator}</div>
            <div className='d-flex flex-nowrap'>
                {success && indicator == meta.indicator && (
                    <i className="fa-solid fa-circle-check text-success pe-2"></i>
                )}
                {error && indicator == meta.indicator && (
                    <i className="fa-solid fa-circle-xmark text-danger pe-2"></i>
                )}
                {loading && indicator == meta.indicator  ? (
                    <Spinner animation="border" className='me-2' as={'span'} style={{width: '20px', height: '20px'}}/>
                ): (
                    <i onClick={favoriteHandler} className="favorite fa-regular fa-star pe-2"></i>
                )}
                <i onClick={addFolderHandler} style={{cursor: 'pointer'}} className="fa-solid fa-folder-plus"></i>
            </div>
        </div>
        <div style={{borderBottom: '1px solid #D9D9D9'}} className='d-flex py-3 px-4'>
            <div className='search-card-summary pe-4'>
                <i className="fa-solid fa-calendar pe-2"></i>
                {meta.frequency}
            </div>
            <div className='search-card-summary pe-4'>
                <i className="fa-solid fa-box-archive pe-2"></i>
                {meta.area.split('_')[1]}
            </div>
            <div className='search-card-summary'>
                <i className="fa-solid fa-book-open pe-2"></i>
                {meta.topic.split('_')[1]}
            </div>
        </div>

        <div className='d-flex px-4 py-4'>
            <div className='pe-4'>
                <p className='search-card-label'>Select Unit:</p>
                <div className='bg-none'>
                    <Dropdown style={{height: '100%'}}>
                        <Dropdown.Toggle className='search-card-select'>
                            {unitValue}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {meta.units.map((item, index) => (
                                <Dropdown.Item key={index} onClick={e => changeUnitHandler(item)} className='search-card-select-item'>
                                    {item}
                                </Dropdown.Item>
                            ))}
                            
                        </Dropdown.Menu>
                    </Dropdown>
                </div>    
            
            </div>
            <div>
                <p className='search-card-label'>Select Frequency:</p>
                <div className='bg-none'>
                    <Dropdown style={{height: '100%'}}>
                        <Dropdown.Toggle className='search-card-select'>
                            {frequencyValue}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {otherFrequenciesList.split(',').map((item, index) => (
                                <Dropdown.Item key={index} onClick={e => changeFrequencyHandler(item)} className='search-card-select-item'>
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>    
            
            </div>
        </div>

        <div style={{borderTop: '1px solid #D9D9D9'}} className='mx-4 '>
            <p className='search-p py-4'>
                {definitionValue}
            </p>
            <Row>
                <Col>
                    <p className='search-p py-1'>
                        <b>Unit:</b> {unitValue}
                    </p>
                    <p className='search-p py-1'>
                        <b>Sources:</b> {sourcesList.length > 0 ? (
                            'Multiple sources'
                        ): sourcesList[0]}
                    </p>
                </Col>
                <Col>
                    <p className='search-p py-1 text-truncate'>
                        <b>Government level:</b> -
                    </p>
                    <p className='search-p py-1'>
                        <b>Other Frequencies:</b> {otherFrequenciesList}
                    </p>
                </Col>
            </Row>

            <Row className='py-2'>
                <Col md={3} style={{borderRight: '1px solid #D9D9D9'}} className='d-flex flex-column justify-content-center'>
                    <p style={{fontSize: '12px'}} className='search-p text-secondary'>
                        Timeframe: <br />
                        <big>{minDate} - {maxDate}</big>
                    </p>
                    <p style={{fontSize: '12px'}} className='search-p text-secondary text-truncate'>
                        Countries available: <br />
                        <big>{numCountries}</big>
                    </p>
                </Col>
                <Col md={9} >
                    <Chart options={options} series={series} type="line" height='100%'/>
                </Col>
                {/* <Col md={3} className='d-flex flex-column justify-content-center'>
                    <p style={{fontSize: '12px'}} className='search-p text-secondary'>Min: 3.2</p>
                    <p style={{fontSize: '12px'}} className='search-p text-secondary'>Max: 240</p>
                    <p style={{fontSize: '12px'}} className='search-p text-secondary'>Avg: 57.7</p>
                </Col> */}
            </Row>
            <div className='pb-4 pt-3'>
                <button onClick={downloadHandler} className='search-card-button me-4'>
                    <i className="fa-solid fa-download pe-3"></i>
                    Download
                </button>
                <button onClick={viewerHandler} className='search-card-button'>
                <i className="fa-solid fa-magnifying-glass pe-3"></i>
                    Open in Viewer
                </button>
            </div>
        </div>
        {viewerModal && (
            <ViewerModal meta={finalMeta} closeCallback={closeViewerHandler} />
        )}

        {addFolderModal && (
            <AddFolderModal closeCallback={closeFolderHandler} meta={metaSender} folders={folderOptions} />
        )}
        {messageModal && (
            <MessageModal closeCallback={closeMessageHandler} title={modalTitle} message={modalMessage} />
        )}

        {loginModal && (
          <LoginModal 
            closeCallback={() => {setLoginModal(false)}}
            registerCallback={e => {setLoginModal(false); setRegisterModal(true)}}
            forgotCallback={e => {setLoginModal(false); setForgotModal(true)}}
            isViewer={true}/>
        )}

        {registerModal && (
          <RegisterModal closeCallback={() => {setRegisterModal(false)}} loginCallback={e => {setRegisterModal(false); setLoginModal(true)}} />
        )}
      
        {forgotModal && (
          <ForgotModal closeCallback={() => {setForgotModal(false)}} />
        )}                    
    </div>
  )
}

export default SearchCard
