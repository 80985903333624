import axios from 'axios'
import {
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_FAIL,

    TOPIC_LIST_REQUEST,
    TOPIC_LIST_SUCCESS,
    TOPIC_LIST_FAIL,

    INDICATOR_LIST_REQUEST,
    INDICATOR_LIST_SUCCESS,
    INDICATOR_LIST_FAIL,

    COUNTRY_LIST_REQUEST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAIL,

    SEARCH_CARDS_REQUEST,
    SEARCH_CARDS_SUCCESS,
    SEARCH_CARDS_FAIL,

    CHANGE_CARD_REQUEST,
    CHANGE_CARD_SUCCESS,
    CHANGE_CARD_FAIL,

    SELECTED_INDICATOR,

    SAVE_SEARCH_REQUEST,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_FAIL,

    GLOBAL_TOP_SEARCH_REQUEST,
    GLOBAL_TOP_SEARCH_SUCCESS,
    GLOBAL_TOP_SEARCH_FAIL,

    USER_TOP_SEARCH_REQUEST,
    USER_TOP_SEARCH_SUCCESS,
    USER_TOP_SEARCH_FAIL,
} from '../constants/searchConstants'

export const getAreas = () => async (dispatch) => {
    try{
        dispatch({
            type: AREA_LIST_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.get(
            '/api/get-areas/',
            config
        )

        dispatch({
            type: AREA_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: AREA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

 export const getTopics = () => async (dispatch) => {
    try{
        dispatch({
            type: TOPIC_LIST_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.get(
            '/api/get-topics/',
            config
        )

        dispatch({
            type: TOPIC_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: TOPIC_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const getIndicators = () => async (dispatch) => {
    try{
        dispatch({
            type: INDICATOR_LIST_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.get(
            '/api/get-indicators/',
            config
        )

        dispatch({
            type: INDICATOR_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: INDICATOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const getCountries = () => async (dispatch) => {
    try{
        dispatch({
            type: COUNTRY_LIST_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.get(
            '/api/get-countries/',
            config
        )

        dispatch({
            type: COUNTRY_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: COUNTRY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const getCards = (area, topic, indicator, country, frequency) => async (dispatch) => {
    try{
        dispatch({
            type: SEARCH_CARDS_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/v1/data/request/',{
                area: area,
                topic: topic,
                indicator: indicator,
                country: country,
                frequency: frequency,
            },
            config
        )

        dispatch({
            type: SEARCH_CARDS_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: SEARCH_CARDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const updateCard = (area, topic, indicator, country, unit, frequency) => async (dispatch) => {
    try{
        dispatch({
            type: CHANGE_CARD_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/v1/data/change-card-data/',{
                area: area,
                topic: topic,
                indicator: indicator,
                country: country,
                unit: "",
                frequency: frequency,
            },
            config
        )

        dispatch({
            type: CHANGE_CARD_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: CHANGE_CARD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const setIndicator = (indicator) => async (dispatch) => {
    dispatch({
        type: SELECTED_INDICATOR,
        payload: indicator
    })
}

export const saveSearch = (area, topic, indicator, region, country, frequency) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SAVE_SEARCH_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        if(userInfo) {
            config.headers.Authorization = `Bearer ${userInfo.access}`
        }
        // const config = {
        //     headers: {
        //         'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.access}`
        //     }
        // }

        const {data} = await axios.post(
            'api/v1/series/update-view-count/',{
                area: area,
                topic: topic,
                indicator: indicator,
                region: region,
                country: country,
                frequency: frequency,
            },
            config
        )

        dispatch({
            type: SAVE_SEARCH_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: SAVE_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const getGlobalTopSearch = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: GLOBAL_TOP_SEARCH_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        // const config = {
        //     headers: {
        //         'Content-type': 'application/json',
        //     }
        // }
        // if(userInfo) {
        //     config.headers.Authorization = `Bearer ${userInfo.access}`
        // }
        const config = {
            headers: {
                'Content-type': 'application/json',
                // Authorization: `Bearer ${userInfo.access}`
            }
        }
        
        const {data} = await axios.get(
            '/api/v1/series/top-indicators/',
            config
        )

        dispatch({
            type: GLOBAL_TOP_SEARCH_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GLOBAL_TOP_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }


 export const getUserTopSearch = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_TOP_SEARCH_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `api/v1/dashboard/widgets/data/indicator-data/`,
            config
        )

        dispatch({
            type: USER_TOP_SEARCH_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: USER_TOP_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}