import '../styles/modals.css'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from '../actions/userActions'
import { Spinner } from 'react-bootstrap'
import Message from '../components/Message'


function ForgotModal({closeCallback}) {
    const [forgotEmail, setForgotEmail] = useState('')

    const dispatch = useDispatch()

    const submitHandler = () => {
      console.log("submit handler")
      dispatch(forgotPassword(forgotEmail))
    }

    const forgotPasswordState = useSelector(state => state.forgotPasswordRequest)

    const {loading, error, success} = forgotPasswordState

    useEffect(()=> {
      if(success){
          setForgotEmail('')
      }
  }, [forgotPasswordState])


  return (
    <div className='modal-container'>
        <div style={{maxWidth: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>
          <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
          <img src="/static/forgot.svg" style={{height: '5em', width: 'auto'}} />
            {error && (
              <Message variant={'danger'}>
                  {error}
              </Message>
            )}
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Forgot Password</h1>
            {loading ? (
              <div className='d-flex justify-content-center align-items-center h-100 mx-5 px-5'>
                  <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
              ): (
                <>
                {success && (
                  <Message variant={'success'}>
                      {"An email has been sent to you with a link to reset your password. Please check your inbox."}
                  </Message>
                )}
                <p className='forgot-p text-center'>No worries! Just provide your email address, and we'll send you a link to reset your password.</p>
                <div style={{width: '100%'}} className='py-1'>
                    <div className='login-input'>
                      <i style={{color: '#898989'}} className="fa-regular fa-envelope me-2"></i>
                      <input className='w-100' value={forgotEmail} onChange={e => setForgotEmail(e.target.value)} type="text" placeholder='Enter your email' />
                    </div>
                  </div>

                  <div onClick={submitHandler} style={{width: '100%'}} className='login-button mt-5'>Submit</div>
                  </>
              )}
        </div>
      </div>
  )
}

export default ForgotModal
