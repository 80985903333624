import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_UPDATE_DATA,

    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    UPDATE_NAME_REQUEST,
    UPDATE_NAME_SUCCESS,
    UPDATE_NAME_FAIL,

    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,

    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_FAIL,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,

    CHANGE_FORGOT_PASSWORD_REQUEST,
    CHANGE_FORGOT_PASSWORD_SUCCESS,
    CHANGE_FORGOT_PASSWORD_FAIL,
    CHANGE_FORGOT_PASSWORD_RESET,

 } from '../constants/userConstants'

export const login = (email, password) => async (dispatch) => {
    try{
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/user/token/',
            {
                'email': email,
                'password': password
            },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))


    }catch(error){
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const register = (username, email, password) => async (dispatch) => {
    try{
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/user/register/',
            {
                'first_name': username,
                'email': email,
                'password': password
            },
            config
        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            // payload: data,
        })


    }catch(error){
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data
                ? JSON.stringify(error.response.data)
                : error.message,
        })
    }
 }

 export const logout = (refreshToken) => async (dispatch) => {
    try{
        dispatch({
            type: USER_LOGOUT_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/user/logout/',
            {
                'refresh_token': refreshToken,
            },
            config
        )

        localStorage.removeItem('userInfo')

        dispatch({
            type: USER_LOGOUT_SUCCESS,
        })

    }catch(error){
        dispatch({
            type: USER_LOGOUT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const delete_account = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            '/api/user/delete-account/',
            config
        )

        localStorage.removeItem('userInfo')

        dispatch({
            type: USER_DELETE_SUCCESS,
        })

    }catch(error){
        dispatch({
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateName = (name) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_NAME_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.patch(
            '/api/user/update-first-name/',
            {
                first_name: name,
            },
            config
        )

        dispatch({
            type: UPDATE_NAME_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: UPDATE_NAME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updatePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_PASSWORD_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            '/api/user/change-password/',
            {
                old_password: oldPassword,
                new_password: newPassword,
            },
            config
        )

        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateImage = (image) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_IMAGE_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.put(
            '/api/user/profile-image/',
            {
                profile_image: image,
            },
            config
        )

        dispatch({
            type: UPDATE_IMAGE_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: UPDATE_IMAGE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const refreshUser = (userInfo) => async (dispatch, getState) => {
        // const {
        //     userLogin: { userInfo }
        // } = getState()

        dispatch({
            type: USER_UPDATE_DATA,
            payload: userInfo
        })

        localStorage.setItem('userInfo', JSON.stringify(userInfo))

}


export const forgotPassword = (email) => async (dispatch) => {
    try{
        dispatch({
            type: FORGOT_PASSWORD_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }

        const {data} = await axios.post(
            '/api/user/request-password-reset/',
            {
                email: email,
            },
            config
        )

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const changePasswordForgot = (password, token) => async (dispatch) => {
    try{
        dispatch({
            type: CHANGE_FORGOT_PASSWORD_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }

        const {data} = await axios.post(
            `/api/user/reset-password/`,
            {
                new_password: password,
                token: token,
            },
            config
        )

        dispatch({
            type: CHANGE_FORGOT_PASSWORD_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: CHANGE_FORGOT_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail.token
                ? error.response.data.detail.token
                : error.message,
        })
    }
}