import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import Message from './Message'
import Loader from './Loader'
import {
    deleteWidget,
} from '../actions/platformActions'
import '../styles/modals.css'

function DeleteWidgetModal({closeCallback, wid, did}) {


    const dispatch = useDispatch()


    const deleteInfo = useSelector(state => state.deleteWidget)
    const {loading, error, success} = deleteInfo

    useEffect(() => {
        if(success){
            closeCallback(true, wid)
        }
    }, [deleteInfo])

    const deleteHandler = () => {
        if(isNaN(wid)){
            closeCallback(true, wid)
        }else {
            dispatch(deleteWidget(did, wid))
        }
        // console.log('serieId: ', meta.id)
        // console.log('folderId: ', meta.folder)
    }

  return (
    <div className='modal-container'>
        <div style={{maxWidth: '320px', borderRadius: '8px'}} className='modal-window p-4'>
            <p className='text-black p-paragraph'>
                Are you sure yo want to delete the Widget?
            </p>
            {loading ? (
                <div className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>
            ): error ? (
                <>
                    <Message variant={'danger'}>{error}</Message>
                    <div className='d-flex justify-content-center '>
                        <div onClick={e => closeCallback(false)} className='p-button-2 p-2 me-2 text-black bg-white'>No</div>
                        <div onClick={deleteHandler} className='p-button-2 p-2 ms-2'>Yes</div>
                    </div>
                </>
            ): (
                <div className='d-flex justify-content-center '>
                    <div onClick={e => closeCallback(false)} className='p-button-2 p-2 me-2 text-black bg-white'>No</div>
                    <div onClick={deleteHandler} className='p-button-2 p-2 ms-2'>Yes</div>
                </div>
            )}

            {/* <div className='d-flex justify-content-center '>
                <div onClick={e => closeCallback(false)} className='p-button-2 p-2 me-2 text-black bg-white'>No</div>
                <div onClick={deleteHandler} className='p-button-2 p-2 ms-2'>Yes</div>
            </div> */}
        </div>
    </div>


  )
}

export default DeleteWidgetModal
