import { 
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_FAIL,

    TOPIC_LIST_REQUEST,
    TOPIC_LIST_SUCCESS,
    TOPIC_LIST_FAIL,

    INDICATOR_LIST_REQUEST,
    INDICATOR_LIST_SUCCESS,
    INDICATOR_LIST_FAIL,

    COUNTRY_LIST_REQUEST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAIL,

    SEARCH_CARDS_REQUEST,
    SEARCH_CARDS_SUCCESS,
    SEARCH_CARDS_FAIL,
    SEARCH_CARDS_RESET,

    CHANGE_CARD_REQUEST,
    CHANGE_CARD_SUCCESS,
    CHANGE_CARD_FAIL,

    SELECTED_INDICATOR,

    SAVE_SEARCH_REQUEST,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_FAIL,
    SAVE_SEARCH_RESET,

    GLOBAL_TOP_SEARCH_REQUEST,
    GLOBAL_TOP_SEARCH_SUCCESS,
    GLOBAL_TOP_SEARCH_FAIL,
    GLOBAL_TOP_SEARCH_RESET,

    USER_TOP_SEARCH_REQUEST,
    USER_TOP_SEARCH_SUCCESS,
    USER_TOP_SEARCH_FAIL,
    USER_TOP_SEARCH_RESET,
 } from '../constants/searchConstants'
import { SAVE_SERIES_FAIL } from '../constants/viewerConstants'



 export const areaListReducer = (state = {}, action) => {
    switch(action.type) {
        case AREA_LIST_REQUEST:
            return { ...state, loading: true }

        case AREA_LIST_SUCCESS:
            return { ...state, loading: false, areaList: action.payload }
        
        case AREA_LIST_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const topicListReducer = (state = {}, action) => {
    switch(action.type) {
        case TOPIC_LIST_REQUEST:
            return { loading: true }

        case TOPIC_LIST_SUCCESS:
            return { loading: false, topicList: action.payload }
        
        case TOPIC_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const indicatorListReducer = (state = {}, action) => {
    switch(action.type) {
        case INDICATOR_LIST_REQUEST:
            return { loading: true }

        case INDICATOR_LIST_SUCCESS:
            return { loading: false, indicatorList: action.payload }
        
        case INDICATOR_LIST_FAIL:
            return { loading: false, error: action.payload }


        default:
            return state
    }
 }

 export const countryListReducer = (state = {}, action) => {
    switch(action.type) {
        case COUNTRY_LIST_REQUEST:
            return { loading: true }

        case COUNTRY_LIST_SUCCESS:
            return { loading: false, countryList: action.payload }
        
        case COUNTRY_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const cardListReducer = (state = {}, action) => {
    switch(action.type) {
        case SEARCH_CARDS_REQUEST:
            return { loading: true }

        case SEARCH_CARDS_SUCCESS:
            return { loading: false, cardList: action.payload }
        
        case SEARCH_CARDS_FAIL:
            return { loading: false, error: action.payload }

        case SEARCH_CARDS_RESET:
            return {}

        default:
            return state
    }
 }

 export const cardUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case CHANGE_CARD_REQUEST:
            return { loading: true }

        case CHANGE_CARD_SUCCESS:
            return { loading: false, cardInfo: action.payload }
        
        case CHANGE_CARD_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const selectedIndicatorReducer = (state = {}, action) => {
    switch(action.type) {
        case SELECTED_INDICATOR:
            return { indicator: action.payload }

        default:
            return state
    }
 }

 export const saveSearchReducer = (state = {}, action) => {
    switch(action.type) {
        case SAVE_SEARCH_REQUEST:
            return { loading: true }

        case SAVE_SEARCH_SUCCESS:
            return { loading: false }
        
        case SAVE_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        case SAVE_SEARCH_RESET:
            return {}

        default:
            return state
    }
 }

 export const globalTopSearchReducer = (state = {}, action) => {
    switch(action.type) {
        case GLOBAL_TOP_SEARCH_REQUEST:
            return { loading: true }

        case GLOBAL_TOP_SEARCH_SUCCESS:
            return { loading: false, list: action.payload }
        
        case GLOBAL_TOP_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        case GLOBAL_TOP_SEARCH_RESET:
            return {}

        default:
            return state
    }
 }

 export const userTopSearchReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_TOP_SEARCH_REQUEST:
            return { loading: true }

        case USER_TOP_SEARCH_SUCCESS:
            return { loading: false, list: action.payload }
        
        case USER_TOP_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        case USER_TOP_SEARCH_RESET:
            return {}

        default:
            return state
    }
 }