import React, { useEffect } from 'react'
import axios from 'axios'
import { USER_LOGIN_SUCCESS, GOOGLE_LOGIN } from '../constants/userConstants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function GoogleValidator() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const googleInfo = useSelector(state => state.googleLogin)
    const {success} = googleInfo

    useEffect(() => {
        if(success) {
            // navigate('/home')
            window.location.href = `${window.location.origin}/#/home`
            // window.location.href = 'http://127.0.0.1:8000/#/home';
            window.location.reload(); // Forzar recarga de la página
        }
    }, [googleInfo])

    useEffect(async () => {
        try{
            const response = await axios.get(
                '/api/user/accounts/google/callback/'
            )

            // console.log(response.data)

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: response.data
            })

            localStorage.setItem('userInfo', JSON.stringify(response.data))
            
            dispatch({
                type: GOOGLE_LOGIN,
            })

            

        }catch(error) {
            console.log(error)
        }
    })

  return (
    <div>
      Successfully
    </div>
  )
}

export default GoogleValidator
