export const GET_DATA_REQUEST = 'GET_DATA_REQUEST'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAIL = 'GET_DATA_FAIL'

export const SAVE_SERIES_REQUEST ='SAVE_SERIES_REQUEST'
export const SAVE_SERIES_SUCCESS ='SAVE_SERIES_SUCCESS'
export const SAVE_SERIES_FAIL ='SAVE_SERIES_FAIL'
export const SAVE_SERIES_RESET ='SAVE_SERIES_RESET'

export const UPDATE_SERIES_REQUEST ='UPDATE_SERIES_REQUEST'
export const UPDATE_SERIES_SUCCESS ='UPDATE_SERIES_SUCCESS'
export const UPDATE_SERIES_FAIL ='UPDATE_SERIES_FAIL'
export const UPDATE_SERIES_RESET ='UPDATE_SERIES_RESET'