import '../styles/search.css'
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Header from '../components/Header'
import Header2 from '../components/Header2'
import PreFooter from '../components/PreFooter'
import Footer from '../components/Footer'
import SearchCard from '../components/SearchCard'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import ViewerModal from '../components/ViewerModal'
import SideBar from '../components/SideBar'

import {
  getAreas,
  getTopics,
  getIndicators,
  getCountries,
  getCards,
  saveSearch,
  getGlobalTopSearch,
} from '../actions/searchActions'
import Message from '../components/Message'
import { SEARCH_CARDS_RESET } from '../constants/searchConstants'


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function SearchScreen() {
  const query = useQuery();

  const area = query.get('area');
  const topic = query.get('topic');
  const indicator = query.get('indicator');

  const dispatch = useDispatch()
  const navigate = useNavigate()
  // dispatch({type: 'INDICATOR_RESET_LIST'})


  const [sortOption, setSortOption] = useState('relevance')
  const [areaValue, setAreaValue] = useState(null)
  const [topicValue, setTopicValue] = useState(null)
  const [indicatorValue, setIndicatorValue] = useState(null)
  const [regionValue, setRegionValue] = useState(null)
  const [countryValue, setCountryValue] = useState(null)
  const [frequencyValue, setFrequencyValue] = useState(null)

  const [finalList, setFinalList] = useState(null)

  const [topicFiltered, setTopicFiltered] = useState([])
  const [indicatorFiltered, setIndicatorFiltered] = useState([])

  const [cardPage, setCardPage] = useState(1)


  useEffect(() => {
    console.log("Using LMW-provisional database in postgresql")
    // const loadData = async () => {
      // await dispatch(resetSearch())
    dispatch({
      type: SEARCH_CARDS_RESET,
    })
    dispatch(getGlobalTopSearch())
    dispatch(getAreas())
    dispatch(getTopics())
    dispatch(getIndicators())
    dispatch(getCountries())
    // }
    // loadData()

  },[])

  const userLogin = useSelector(state => state.userLogin)
    const {loading: loadingLogin, error: errorLogin, userInfo} = userLogin


  const areaInfo = useSelector(state => state.areaList)
  const { loading, error, areaList } = areaInfo

  const topicInfo = useSelector(state => state.topicList)
  const { loading: loadingTopic , error: errorTopic, topicList } = topicInfo

  const indicatorInfo = useSelector(state => state.indicatorList)
  const { loading: loadingIndicator , error: errorIndicator, indicatorList } = indicatorInfo

  const globalInfo = useSelector(state => state.globalTopSearch)
  const {loading: loadingGlobal, error: errorGlobal, list} = globalInfo

  useEffect(() => {
    if(areaList && topicList && indicatorList && area)
    {
        searchHandler()
    }

  }, [areaList, topicList, indicatorList])

  useEffect(() => {
    if(area && area !== '' && areaList && areaList.length > 0){
      const areaItem = areaList?.find(item => item.id == area)
      const areaOption = {api: areaItem.name, label: areaItem.beauty_name, value: areaItem.id}
      setAreaValue(areaOption)
    }
  }, [areaList])



  useEffect(() => {
    setTopicFiltered(topicList)
    if(topic && topic !== '' && topicList && topicList.length > 0){
      const topicItem = topicList.find(item => item.id == topic)
      const topicOption = [{api: topicItem.name, area: topicItem.foreign_area, label: topicItem.beauty_name, value: topicItem.id}]
      setTopicValue(topicOption)
    }
  }, [topicList])



  useEffect(() => {
    setIndicatorFiltered(indicatorList)
    if(indicator && indicator !== '' && indicatorList && indicatorList.length > 0){
      const indicatorItem = [...indicatorList].find(item => item.id == indicator)
      const indicatorOption = [{api: indicatorItem.name, area: indicatorItem.foreign_area, label: indicatorItem.name, topic: indicatorItem.foreign_topic, value: indicatorItem.id}]
      setIndicatorValue(indicatorOption)
    }
  }, [indicatorList])



  const countryInfo = useSelector(state => state.countryList)
  const { loading: loadingCountry , error: errorCountry, countryList } = countryInfo





  const cardInfo = useSelector(state => state.cardList)
  const { loading: loadingCard, error: errorCard, cardList } = cardInfo

  useEffect(() => {
    setFinalList(cardList)
  }, [cardList])



    // ************ select options

    const areaOptions = areaList?.map(item => {
      return {
        value: item.id,
        label: item.beauty_name,
        api: item.name,
      }
    })

    const areas = [...new Set(topicFiltered?.map(item => item.foreign_area))]

    const topicOptions = areas?.map(item => {
      return {
        label: areaList?.filter(element => element.id === item)[0]?.name.split('_')[1],
        options: topicFiltered.filter(element => element.foreign_area === item).map(element => {
          return {
            value: element.id,
            label: element.name.split('_')[1],
            area: element.foreign_area,
            // topic: element.foreign_topic,
            api: element.name
          }
        })
      }
    })


    const topics = [...new Set(indicatorFiltered?.map(item => item.foreign_topic))]

    const indicatorOptions = topics?.map(item => {
      return {
        label: topicFiltered?.filter(element => element.id === item)[0]?.name.split('_')[1],
        options: indicatorFiltered.filter(element => element.foreign_topic === item).map(element => {
          return {
            value: element.id,
            label: element.name,
            area: element.foreign_area,
            topic: element.foreign_topic,
            api: element.name
          }
        })
      }
    })

    const regionJson = {
      'LAC': [
        'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Ecuador', 'Paraguay', 'Peru', 'Uruguay',
        'Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama', 'Bahamas', 'Barbados', 'Jamaica', 'Dominican Republic',
      ],
      'Andean': ['Bolivia', 'Colombia', 'Ecuador', 'Peru'],
      'Southern_cone': ['Argentina', 'Brazil', 'Chile', 'Paraguay', 'Uruguay'],
      'Central_america': ['Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama'],
      'Caribbean': [
        'Bahamas', 'Barbados', 'Jamaica',
        'Dominican Republic']
      }

    const regionOptions = Object.keys(regionJson).map(item => {
      return {
        value: item,
        label: item.split('_').join(' '),
      }
    })
    const countryOptions = countryList?.map(item => {
      return {
        value: item.id,
        label: item.name,
      }
    })

    const frequencyOptions = [
      {value: 'Monthly', label: 'Monthly'},
      {value: 'Quarterly', label: 'Quarterly'},
      {value: 'Annual', label: 'Annual'},
    ]


    const sortHandler = (option) => {
        setSortOption(option)
        if(option === 'title'){
          setFinalList(prevState => ({
            ...prevState,
            data_list: [...finalList.data_list].sort((a,b) => a.group.indicator.localeCompare(b.group.indicator))
          }))

        }
    }

    const searchHandler = () => {
      let area = (areaValue ? areaValue.api : '')
      // let topic = (topicValue ? topicValue.api : '')
      let topic = (topicValue ? topicValue.map(item => {
        return item.api
      }): [])
      // let indicator = (indicatorValue ? indicatorValue.api : '')
      let indicator = (indicatorValue ? indicatorValue.map(item => {
        return item.api
      }): [])
      let country = (countryValue ? countryValue.map(item => {
        return item.label
      }): [])
      let frequency = (frequencyValue ? frequencyValue.value : '')
      // console.log('area: ', areaValue)
      // console.log('topic: ', topicValue)
      // console.log('indicator: ', indicatorValue)
      // console.log('region: ', regionValue)
      // console.log('country: ', countryValue)
      // console.log('frequency: ', frequencyValue)
      dispatch(saveSearch(
        areaValue?.value,
        topicValue ? topicValue.map(item => item.value): [],
        indicatorValue ? indicatorValue.map(item => item.value): [],
        regionValue ? regionValue.value: null,
        countryValue ? countryValue.map(item => item.value): [],
        frequencyValue ? frequencyValue.value: null
      ))
      dispatch(getCards(area, topic, indicator, country, frequency))
    }

    const resetHandler = () => {
      setAreaValue(null)
      setTopicValue(null)
      setIndicatorValue(null)
      setCountryValue(null)
      setFrequencyValue(null)

      setTopicFiltered(topicList)
      setIndicatorFiltered(indicatorList)
      dispatch({
        type: SEARCH_CARDS_RESET,
      })

    }

    const areaHandler = (option) => {
      setAreaValue(option)
      setTopicValue(null)
      setIndicatorValue(null)
      const newTopicArray = topicList.filter(item => item.foreign_area === option.value)
      setTopicFiltered(newTopicArray)
      const newIndicatorArray = [...indicatorList].filter(item => item.foreign_area === option.value)
      setIndicatorFiltered(newIndicatorArray)
    }

    const topicHandler = (option) => {
      setIndicatorValue(null)
      if(option.length === 0){
        setTopicValue(null)
        const newIndicatorArray = [...indicatorList].filter(item => item.foreign_area === areaValue.value)
        setIndicatorFiltered(newIndicatorArray)
      }else{

        setTopicValue(option)
        let area = areaList.filter(item => item.id === option[0]?.area)[0]
        if(area.id !== areaValue?.value){
          setAreaValue({
            value: area.id,
            label: area.beauty_name,
            api: area.name,
          })
        }
        const newTopicArray = topicList.filter(item => item.foreign_area === area.id)
        setTopicFiltered(newTopicArray)
        const newIndicatorArray = [...indicatorList].filter(item => [...new Set(option.map(element => element.value))].includes(item.foreign_topic))
        setIndicatorFiltered(newIndicatorArray)
      }
    }

    const indicatorHandler = (option) => {
      if(option.length === 0){
        setIndicatorValue(null)
        const newIndicatorArray = [...indicatorList].filter(item => [...new Set(topicValue.map(element => element.value))].includes(item.foreign_topic))
        setIndicatorFiltered(newIndicatorArray)
      }
      else{
        setIndicatorValue(option)
        let area = areaList?.filter(item => item.id === option[0].area)[0]
        if(area.id !== areaValue?.value){
          setAreaValue({
            value: area.id,
            label: area.beauty_name,
            api: area.name,
          })


        let topic = topicList?.filter(item => item.id === option[option.length -1].topic)[0]
        if(!topicValue?.some(item => item.value === topic.id)){
          if(topicValue == null){
            setTopicValue([
              {
                value: topic.id,
                label: topic.beauty_name,
                area: topic.foreign_area,
                api: topic.name,
              }
            ])
          }else {
            setTopicValue(prev => [
              ...prev,
              {
                  value: topic.id,
                  label: topic.beauty_name,
                  area: topic.foreign_area,
                  api: topic.name,
              },
            ]);
          }
        }
      }


      const newTopicArray = topicList.filter(item => [...new Set(option.map(element => element.area))].includes(item.foreign_area))
      setTopicFiltered(newTopicArray)

      const newIndicatorArray = [...indicatorList].filter(item => [...new Set(option.map(element => element.topic))].includes(item.foreign_topic))
      setIndicatorFiltered(newIndicatorArray)
      }
    }
    const regionHandler = (option) => {
      setRegionValue(option)
      let a = regionJson[option.value]
      let countries = a.map(item => {
        return {
          value: countryList?.filter(element => element.name === item)[0]?.id,
          label: item,
        }
      })
      setCountryValue(countries)
    }

    const countryHandler = (option) => {
      setCountryValue(option)
      // console.log("countryValue: ", countryValue)
    }

    const loadMoreHandler = () => {
      setCardPage(cardPage + 1)
    }

    const popularSearchHandler = (item) => {
      var area = item.area_id
      var topic = item.topic_id
      var indicator = item.indicator_id
      // navigate(`/search?area=${encodeURIComponent(area)}&topic=${encodeURIComponent(topic)}&indicator=${encodeURIComponent(indicator)}`);
      window.location.href = `${window.location.origin}/#/search?area=${encodeURIComponent(area)}&topic=${encodeURIComponent(topic)}&indicator=${encodeURIComponent(indicator)}`
      window.location.reload(); // Forzar recarga de la página
    }



  return (
    <div>
        {userInfo ? (
          <Header2 />
        ): (
          <Header />
        )}

        <div className='d-flex'>
          {userInfo && (
            <SideBar />
          )}
          <div style={{flex: '1'}} className='search-main-container'>
              <h1 style={{color: '#303950'}} className='search-title text-center pt-5'>LATIN MACRO WATCH - FISLAC DATASET</h1>
              <p className='comment text-center'>Updated on June 24, 2024</p>

              <Container className='little-p'>
                  Popular search terms:
              </Container>
              <div style={{backgroundColor: '#F6F6F6'}} className='w-100'>
                  <Container className='popular-container'>
                      {loadingGlobal && (
                        <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                      )}

                      {list && list.slice(0, 5).map((item, index) => (
                        <div key={index} onClick={e => popularSearchHandler(item)} className='popular-term'>{item.indicator}</div>
                      ))}

                  </Container>
              </div>
              <Container fluid='md'>
                  <div className='d-flex justify-content-end pt-4'>
                      {finalList && (
                        <div>
                            <p className='little-p m-0'>Sort by:</p>
                            <div className='sort-container'>
                                <div onClick={e => sortHandler('relevance')} className={`${sortOption === 'relevance' ? 'sort-selected': ''}`}>Relevance</div>
                                <div onClick={e => sortHandler('title')} className={`${sortOption === 'title' ? 'sort-selected': ''}`}>Title</div>
                            </div>
                        </div>
                      )}
                  </div>
                  <Row>
                      <Col className='search-params-container' md={5}>
                        <p className='search-label'>Area: <sup style={{color: 'red'}}>*</sup></p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          options={areaOptions}
                          placeholder={'Select area'}
                          value={areaValue}
                          onChange={option => areaHandler(option)}
                        />

                        <p className='search-label'>Topic:</p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          isMulti
                          options={topicOptions}
                          placeholder={'Select topic'}
                          value={topicValue}
                          onChange={option => topicHandler(option)}
                        />

                        <p className='search-label'>Indicator:</p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          isMulti
                          options={indicatorOptions}
                          placeholder={'Select indicator'}
                          value={indicatorValue}
                          onChange={option => indicatorHandler(option)}
                        />
                        {/* <p className='search-label'>Goverment Level:</p>
                        <input className='search-input mb-3' type="text" placeholder='Search goverment level' /> */}
                        <p className='search-label'>Region:</p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          // isMulti
                          options={regionOptions}
                          placeholder={'Select region'}
                          value={regionValue}
                          onChange={option => regionHandler(option)}
                        />

                        <p className='search-label'>Country:</p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          isMulti
                          options={countryOptions}
                          placeholder={'Select countries'}
                          value={countryValue}
                          onChange={option => countryHandler(option)}
                        />
                        <p className='search-label'>Frequency:</p>
                        <Select
                          className='mb-3'
                          classNamePrefix="search-select"
                          options={frequencyOptions}
                          placeholder={'Select frequency'}
                          value={frequencyValue}
                          onChange={option => {setFrequencyValue(option)}}
                        />


                        <div className='py-5'>
                            <button onClick={searchHandler} className='search-button me-4' disabled={areaValue == null}>Search</button>
                            <button onClick={resetHandler} className='search-button'>Reset</button>
                        </div>
                      </Col>
                      <Col className='search-card-container' md={7}>
                        {loadingCard ? (
                          // <Loader />
                          <div className='h-100 d-flex align-items-center justify-content-center'>
                            <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                          </div>
                        ) : error ? (
                          <Message variant={'danger'}>{error}</Message>
                        ) : finalList && finalList.data_list ? finalList.data_list.slice(0, 4*cardPage).map((item, index) => (
                          <SearchCard key={index} meta={item.group} data ={item.data} countries={countryValue} />
                        )): (
                          <div className='p-5 d-flex flex-column align-items-center justify-content-center h-100'>
                            <img src="/static/empty-search.svg" style={{width: '15em'}} className='py-5 opacity-75' alt='' />
                            <p style={{fontSize: '15px'}} className='text-center'>Select criteria from the drop-down lists and click <b>'Search'</b> to begin. Area field is required.</p>
                          </div>
                        )}

                        {finalList != null && (cardPage*4 < finalList?.data_list?.length) && (
                          <div className='d-flex justify-content-center pt-5'>
                            <button onClick={loadMoreHandler} className='search-button'>Load More</button>
                          </div>
                        )}

                      </Col>
                  </Row>

              </Container>

              <br /><br /><br />

          </div>

        </div>

        <SideResponsiveOpen />
        <PreFooter />
        <Footer />
    </div>
  )
}

export default SearchScreen
