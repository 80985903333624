import React from 'react';
import { Link } from 'react-router-dom';

function AccountActivated() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ color: '#4CAF50' }}>Account Verified!</h1>
      <p>Your account has been successfully verified. You can now close this page and log in to your account.</p>
      <Link to="/" style={{ textDecoration: 'none', backgroundColor: '#4CAF50', color: '#fff', padding: '10px 20px', borderRadius: '5px' }}>
        Go back to LMW-Fislac
      </Link>
    </div>
  );
}

export default AccountActivated;