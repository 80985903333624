import '../styles/modals.css'
import React, { useEffect, useState } from 'react'
import  Select  from 'react-select'
import { getFolders, getSeries, editTracker } from '../actions/platformActions'
import { useDispatch, useSelector } from 'react-redux'
import { EDIT_TRACKER_RESET } from '../constants/platformConstants'
import Message from './Message'
import { Spinner } from 'react-bootstrap'

function EditTrackerModal({tracker, closeCallback}) {

  const dispatch = useDispatch()
  const [folderValue, setFolderValue] = useState(null)
  const [seriesValue, setSerieValue] = useState(null)
  const [folderOptions, setFolderOptions] = useState([])
  const [seriesOptions, setSeriesOptions] = useState([])

  useEffect(() => {
        dispatch({
            type: EDIT_TRACKER_RESET
        })
        dispatch(getFolders())
    }, [])

    const folderInfo = useSelector(state => state.listFolder)
    const {loading, error, folderList} = folderInfo

    useEffect(() => {
        if(folderList){
            setFolderOptions(folderList.map(item => {
                return {
                    value: item.id,
                    label: item.folder_name,
                }
            }))
        }
    }, [folderInfo])

    // const trackerInfo = useSelector(state => state.seriesTracker)
    // const {loading: loadingTracker, error: errorTracker, series} = trackerInfo

    const seriesInfo = useSelector(state => state.seriesList)
    const {loading: loadingSeries, error: errorSeries, listSeries} = seriesInfo

    useEffect(() => {
        if(listSeries){
            setSeriesOptions(listSeries.map(item => {
                return {
                    value: item.id,
                    label: item.series_name
                }
            }))
        }
    }, [seriesInfo])


    const selectFolderHandler = (option) => {
        setFolderValue(option)
        dispatch(getSeries(option.value))
    }

    const selectSerieHandler = (option) => {
        setSerieValue(option)
    }

    // const addSerieHandler = () => {
    //     console.log("order: ", order + 1)
    //     console.log("serieId: ", seriesValue.value)
    //     dispatch(createTracker(seriesValue.value, order + 1))
    // }
    const editTrackerHandler = () => {
        dispatch(editTracker(tracker, seriesValue.value))
    }


    const editInfo = useSelector(state => state.editTracker)
    const {loading: loadingEdit, error: errorEdit, success} = editInfo

    useEffect(() => {
        if(success){
            closeCallback(true)
        }
    }, [editInfo])


  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

            <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Edit Series</h1>

           <p className='p-paragraph'>
            Select one of your favorite series to change selected current series
            </p>

            <Select
                className="custom-select-container"
                classNamePrefix="custom-select"
                placeholder={'Select folders'}
                value={folderValue}
                options={folderOptions}
                onChange={option => selectFolderHandler(option)}
            />

            <span className='p-label-2 text-start w-100 pt-4'>Select one of you favorites:</span>
            <Select
                className={`custom-select-container ${!folderValue ? 'button-disabled': ''} `}
                classNamePrefix="custom-select"
                placeholder={'Select folders'}
                value={seriesValue}
                options={seriesOptions}
                onChange={option => selectSerieHandler(option)}
            />



           {loadingEdit ? (
                <div style={{width: '100%'}} className='login-button mt-3'>
                    <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>
            ): (
                <div onClick={editTrackerHandler} style={{width: '100%'}} className={`login-button mt-3 ${(seriesValue && seriesValue.value && seriesValue != '') ? '': 'button-disabled'}`}>Swap Series</div>
            )}

            {errorEdit && (
                <Message variant={'danger'}>{errorEdit}</Message>
            )}

        </div>
      </div>
  )
}

export default EditTrackerModal
