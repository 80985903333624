import { 
    CREATE_FOLDER_REQUEST,
    CREATE_FOLDER_SUCCESS,
    CREATE_FOLDER_FAIL,
    CREATE_FOLDER_RESET,

    FOLDER_LIST_REQUEST,
    FOLDER_LIST_SUCCESS,
    FOLDER_LIST_FAIL,

    DELETE_FOLDER_REQUEST,
    DELETE_FOLDER_SUCCESS,
    DELETE_FOLDER_FAIL,
    DELETE_FOLDER_RESET,

    GET_SERIES_REQUEST,
    GET_SERIES_SUCCESS,
    GET_SERIES_FAIL,

    UPDATE_FOLDER_REQUEST,
    UPDATE_FOLDER_SUCCESS,
    UPDATE_FOLDER_FAIL,
    UPDATE_FOLDER_RESET,

    DELETE_SERIES_REQUEST,
    DELETE_SERIES_SUCCESS,
    DELETE_SERIES_FAIL,
    DELETE_SERIES_RESET,

    FAVORITE_COUNT_REQUEST,
    FAVORITE_COUNT_SUCCESS,
    FAVORITE_COUNT_FAIL,
    FAVORITE_COUNT_RESET,

    GET_SERIES_TRACKER_REQUEST,
    GET_SERIES_TRACKER_SUCCESS,
    GET_SERIES_TRACKER_FAIL,
    GET_SERIES_TRACKER_RESET,

    CREATE_TRACKER_REQUEST,
    CREATE_TRACKER_SUCESS,
    CREATE_TRACKER_FAIL,
    CREATE_TRACKER_RESET,

    DELETE_TRACKER_REQUEST,
    DELETE_TRACKER_SUCESS,
    DELETE_TRACKER_FAIL,
    DELETE_TRACKER_RESET,

    EDIT_TRACKER_REQUEST,
    EDIT_TRACKER_SUCESS,
    EDIT_TRACKER_FAIL,
    EDIT_TRACKER_RESET,

    CREATE_DASHBOARD_REQUEST,
    CREATE_DASHBOARD_SUCCESS,
    CREATE_DASHBOARD_FAIL,
    CREATE_DASHBOARD_RESET,

    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_FAIL,
    GET_DASHBOARD_RESET,

    UPDATE_DASHBOARD_NAME_REQUEST,
    UPDATE_DASHBOARD_NAME_SUCCESS,
    UPDATE_DASHBOARD_NAME_FAIL,
    UPDATE_DASHBOARD_NAME_RESET,

    UPDATE_DASHBOARD_DESCRIPTION_REQUEST,
    UPDATE_DASHBOARD_DESCRIPTION_SUCCESS,
    UPDATE_DASHBOARD_DESCRIPTION_FAIL,
    UPDATE_DASHBOARD_DESCRIPTION_RESET,

    SAVE_DASHBOARD_REQUEST,
    SAVE_DASHBOARD_SUCCESS,
    SAVE_DASHBOARD_FAIL,
    SAVE_DASHBOARD_RESET,

    GET_WIDGETS_REQUEST,
    GET_WIDGETS_SUCCESS,
    GET_WIDGETS_FAIL,
    GET_WIDGETS_RESET,

    GET_INDICATOR_UNITS_REQUEST,
    GET_INDICATOR_UNITS_SUCCESS,
    GET_INDICATOR_UNITS_FAIL,
    GET_INDICATOR_UNITS_RESET,

    GET_INDICATOR_TIMEFRAME_REQUEST,
    GET_INDICATOR_TIMEFRAME_SUCCESS,
    GET_INDICATOR_TIMEFRAME_FAIL,
    GET_INDICATOR_TIMEFRAME_RESET,

    DELETE_WIDGET_REQUEST,
    DELETE_WIDGET_SUCCESS,
    DELETE_WIDGET_FAIL,
    DELETE_WIDGET_RESET,

    GET_WIDGET_SERIE_DATA_REQUEST,
    GET_WIDGET_SERIE_DATA_SUCCESS,
    GET_WIDGET_SERIE_DATA_FAIL,
    GET_WIDGET_SERIE_DATA_RESET,

    GET_DASHBOARD_LIST_REQUEST,
    GET_DASHBOARD_LIST_SUCCESS,
    GET_DASHBOARD_LIST_FAIL,
    GET_DASHBOARD_LIST_RESET,

    DELETE_DASHBOARD_REQUEST,
    DELETE_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_FAIL,
    DELETE_DASHBOARD_RESET,

    DASHBOARD_COUNT_REQUEST,
    DASHBOARD_COUNT_SUCCESS,
    DASHBOARD_COUNT_FAIL,
    DASHBOARD_COUNT_RESET,

    GET_DASHBOARD_HOME_REQUEST,
    GET_DASHBOARD_HOME_SUCCESS,
    GET_DASHBOARD_HOME_FAIL,
    GET_DASHBOARD_HOME_RESET,

    VIEW_COUNT_REQUEST,
    VIEW_COUNT_SUCCESS,
    VIEW_COUNT_FAIL,
    VIEW_COUNT_RESET,

    UPDATE_DASHBOARD_IMAGE_REQUEST,
    UPDATE_DASHBOARD_IMAGE_SUCCESS,
    UPDATE_DASHBOARD_IMAGE_FAIL,
    UPDATE_DASHBOARD_IMAGE_RESET,

    GET_RECENT_SEARCH_REQUEST,
    GET_RECENT_SEARCH_SUCCESS,
    GET_RECENT_SEARCH_FAIL,
    GET_RECENT_SEARCH_RESET,
 } from '../constants/platformConstants'

 export const createFolderReducer = (state = {}, action) => {
    switch(action.type) {
        case CREATE_FOLDER_REQUEST:
            return {loading: true }

        case CREATE_FOLDER_SUCCESS:
            return {loading: false, success: true }
        
        case CREATE_FOLDER_FAIL:
            return {loading: false, error: action.payload }

        case CREATE_FOLDER_RESET:
            return {}

        default:
            return state
    }
 }

 export const listFolderReducer = (state = {}, action) => {
    switch(action.type) {
        case FOLDER_LIST_REQUEST:
            return { ...state, loading: true }

        case FOLDER_LIST_SUCCESS:
            return { ...state, loading: false, folderList: action.payload }
        
        case FOLDER_LIST_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
 }

 export const deleteFolderReducer = (state = {}, action) => {
    switch(action.type) {
        case DELETE_FOLDER_REQUEST:
            return {loading: true }

        case DELETE_FOLDER_SUCCESS:
            return {loading: false, success: true }
        
        case DELETE_FOLDER_FAIL:
            return {loading: false, error: action.payload }
            
        case DELETE_FOLDER_RESET:
            return {}

        default:
            return state
    }
 }

 export const seriesListReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_SERIES_REQUEST:
            return {loading: true }

        case GET_SERIES_SUCCESS:
            return {loading: false, listSeries: action.payload }
        
        case GET_SERIES_FAIL:
            return {loading: false, error: action.payload }

        default:
            return state
    }
 }


 export const updateFolderReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_FOLDER_REQUEST:
            return {loading: true }

        case UPDATE_FOLDER_SUCCESS:
            return {loading: false, success: true }
        
        case UPDATE_FOLDER_FAIL:
            return {loading: false, error: action.payload }
        
        case UPDATE_FOLDER_RESET:
            return {}

        default:
            return state
    }
 }

 export const deleteSerieReducer = (state = {}, action) => {
    switch(action.type) {
        case DELETE_SERIES_REQUEST:
            return {loading: true }

        case DELETE_SERIES_SUCCESS:
            return {loading: false, success: true }
        
        case DELETE_SERIES_FAIL:
            return {loading: false, error: action.payload }

        case DELETE_SERIES_RESET:
            return {}

        default:
            return state
    }
 }

 export const favoriteCountReducer = (state = {}, action) => {
    switch(action.type) {
        case FAVORITE_COUNT_REQUEST:
            return {loading: true }

        case FAVORITE_COUNT_SUCCESS:
            return {loading: false, count: action.payload }
        
        case FAVORITE_COUNT_FAIL:
            return {loading: false, error: action.payload }

        case FAVORITE_COUNT_RESET:
            return {}

        default:
            return state
    }
 }

 export const dashboardCountReducer = (state = {}, action) => {
    switch(action.type) {
        case DASHBOARD_COUNT_REQUEST:
            return {loading: true }

        case DASHBOARD_COUNT_SUCCESS:
            return {loading: false, count: action.payload }
        
        case DASHBOARD_COUNT_FAIL:
            return {loading: false, error: action.payload }

        case DASHBOARD_COUNT_RESET:
            return {}

        default:
            return state
    }
 }

 export const seriesTrackerReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_SERIES_TRACKER_REQUEST:
            return {loading: true }

        case GET_SERIES_TRACKER_SUCCESS:
            return {loading: false, series: action.payload }
        
        case GET_SERIES_TRACKER_FAIL:
            return {loading: false, error: action.payload }

        case GET_SERIES_TRACKER_RESET:
            return {}

        default:
            return state
    }
 }


 export const createTrackerReducer = (state = {}, action) => {
    switch(action.type) {
        case CREATE_TRACKER_REQUEST:
            return {loading: true }

        case CREATE_TRACKER_SUCESS:
            return {loading: false, success: true }
        
        case CREATE_TRACKER_FAIL:
            return {loading: false, error: action.payload }

        case CREATE_TRACKER_RESET:
            return {}

        default:
            return state
    }
 }

 export const deleteTrackerReducer = (state = {}, action) => {
    switch(action.type) {
        case DELETE_TRACKER_REQUEST:
            return {loading: true }

        case DELETE_TRACKER_SUCESS:
            return {loading: false, success: true }
        
        case DELETE_TRACKER_FAIL:
            return {loading: false, error: action.payload }

        case DELETE_TRACKER_RESET:
            return {}

        default:
            return state
    }
 }

 export const editTrackerReducer = (state = {}, action) => {
    switch(action.type) {
        case EDIT_TRACKER_REQUEST:
            return {loading: true }

        case EDIT_TRACKER_SUCESS:
            return {loading: false, success: true }
        
        case EDIT_TRACKER_FAIL:
            return {loading: false, error: action.payload }

        case EDIT_TRACKER_RESET:
            return {}

        default:
            return state
    }
 }

 export const createDashboardReducer = (state = {}, action) => {
    switch(action.type) {
        case CREATE_DASHBOARD_REQUEST:
            return {loading: true }

        case CREATE_DASHBOARD_SUCCESS:
            return {loading: false, dashboard: action.payload }
        
        case CREATE_DASHBOARD_FAIL:
            return {loading: false, error: action.payload }

        case CREATE_DASHBOARD_RESET:
            return {}

        default:
            return state
    }
 }

 export const getDashboardReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_DASHBOARD_REQUEST:
            return {loading: true }

        case GET_DASHBOARD_SUCCESS:
            return {loading: false, dashboard: action.payload }
        
        case GET_DASHBOARD_FAIL:
            return {loading: false, error: action.payload }

        case GET_DASHBOARD_RESET:
            return {}

        default:
            return state
    }
 }

 export const updateDashboardTitleReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_DASHBOARD_NAME_REQUEST:
            return {loading: true }

        case UPDATE_DASHBOARD_NAME_SUCCESS:
            return {loading: false, name: action.payload }
        
        case UPDATE_DASHBOARD_NAME_FAIL:
            return {loading: false, error: action.payload }

        case UPDATE_DASHBOARD_NAME_RESET:
            return {}

        default:
            return state
    }
 }

 export const updateDashboardDescriptionReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_DASHBOARD_DESCRIPTION_REQUEST:
            return {loading: true }

        case UPDATE_DASHBOARD_DESCRIPTION_SUCCESS:
            return {loading: false, description: action.payload }
        
        case UPDATE_DASHBOARD_DESCRIPTION_FAIL:
            return {loading: false, error: action.payload }

        case UPDATE_DASHBOARD_DESCRIPTION_RESET:
            return {}

        default:
            return state
    }
 }

 export const saveDashboardReducer = (state = {}, action) => {
    switch(action.type) {
        case SAVE_DASHBOARD_REQUEST:
            return {loading: true }

        case SAVE_DASHBOARD_SUCCESS:
            return {loading: false, success: true }
        
        case SAVE_DASHBOARD_FAIL:
            return {loading: false, error: action.payload }

        case SAVE_DASHBOARD_RESET:
            return {}

        default:
            return state
    }
 }

 export const getWidgetsReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_WIDGETS_REQUEST:
            return {loading: true }

        case GET_WIDGETS_SUCCESS:
            return {loading: false, widgets: action.payload }
        
        case GET_WIDGETS_FAIL:
            return {loading: false, error: action.payload }

        case GET_WIDGETS_RESET:
            return {}

        default:
            return state
    }
 }

 export const getIndicatorUnitsReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_INDICATOR_UNITS_REQUEST:
            return {loading: true }

        case GET_INDICATOR_UNITS_SUCCESS:
            return {loading: false, result: action.payload }
        
        case GET_INDICATOR_UNITS_FAIL:
            return {loading: false, error: action.payload }

        case GET_INDICATOR_UNITS_RESET:
            return {}

        default:
            return state
    }
 }

 export const indicatorTimeframeReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_INDICATOR_TIMEFRAME_REQUEST:
            return {loading: true }

        case GET_INDICATOR_TIMEFRAME_SUCCESS:
            return {loading: false, timeframe: action.payload }
        
        case GET_INDICATOR_TIMEFRAME_FAIL:
            return {loading: false, error: action.payload }

        case GET_INDICATOR_TIMEFRAME_RESET:
            return {}

        default:
            return state
    }
 }

 export const deleteWidgetReducer = (state = {}, action) => {
    switch(action.type) {
        case DELETE_WIDGET_REQUEST:
            return {loading: true }

        case DELETE_WIDGET_SUCCESS:
            return {loading: false, success: true }
        
        case DELETE_WIDGET_FAIL:
            return {loading: false, error: action.payload }

        case DELETE_WIDGET_RESET:
            return {}

        default:
            return state
    }
 }

 export const serieWidgetReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_WIDGET_SERIE_DATA_REQUEST:
            return {loading: true }

        case GET_WIDGET_SERIE_DATA_SUCCESS:
            return {loading: false, result: action.payload }
        
        case GET_WIDGET_SERIE_DATA_FAIL:
            return {loading: false, error: action.payload }

        case GET_WIDGET_SERIE_DATA_RESET:
            return {}

        default:
            return state
    }
 }

 export const dashboardListReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_DASHBOARD_LIST_REQUEST:
            return {loading: true }

        case GET_DASHBOARD_LIST_SUCCESS:
            return {loading: false, dashboards: action.payload }
        
        case GET_DASHBOARD_LIST_FAIL:
            return {loading: false, error: action.payload }

        case GET_DASHBOARD_LIST_RESET:
            return {}

        default:
            return state
    }
 }

 export const deleteDashboardReducer = (state = {}, action) => {
    switch(action.type) {
        case DELETE_DASHBOARD_REQUEST:
            return {loading: true }

        case DELETE_DASHBOARD_SUCCESS:
            return {loading: false, success: true }
        
        case DELETE_DASHBOARD_FAIL:
            return {loading: false, error: action.payload }

        case DELETE_DASHBOARD_RESET:
            return {}

        default:
            return state
    }
 }

 export const dashboardsHomeReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_DASHBOARD_HOME_REQUEST:
            return {loading: true }

        case GET_DASHBOARD_HOME_SUCCESS:
            return {loading: false, dashboards: action.payload }
        
        case GET_DASHBOARD_HOME_FAIL:
            return {loading: false, error: action.payload }

        case GET_DASHBOARD_HOME_RESET:
            return {}

        default:
            return state
    }
 }

 export const viewCountReducer = (state = {}, action) => {
    switch(action.type) {
        case VIEW_COUNT_REQUEST:
            return {loading: true }

        case VIEW_COUNT_SUCCESS:
            return {loading: false, success: true }
        
        case VIEW_COUNT_FAIL:
            return {loading: false, error: action.payload }

        case VIEW_COUNT_RESET:
            return {}

        default:
            return state
    }
 }


 export const updateDashboardImageReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_DASHBOARD_IMAGE_REQUEST:
            return {loading: true }

        case UPDATE_DASHBOARD_IMAGE_SUCCESS:
            return {loading: false, success: true }
        
        case UPDATE_DASHBOARD_IMAGE_FAIL:
            return {loading: false, error: action.payload }

        case UPDATE_DASHBOARD_IMAGE_RESET:
            return {}

        default:
            return state
    }
 }

 export const recentSearchReducer = (state = {}, action) => {
    switch(action.type) {
        case GET_RECENT_SEARCH_REQUEST:
            return {loading: true }

        case GET_RECENT_SEARCH_SUCCESS:
            return {loading: false, recentSearch: action.payload }
        
        case GET_RECENT_SEARCH_FAIL:
            return {loading: false, error: action.payload }

        case GET_RECENT_SEARCH_RESET:
            return {}

        default:
            return state
    }
 }