import { useDispatch, useSelector } from 'react-redux'
import '../styles/modals.css'
import React, { useEffect, useState } from 'react'
import Message from './Message'
import { Spinner } from 'react-bootstrap'

function AddLabelModal({closeCallback, addCallback, id}) {
  
  const [labelValue, setLabelValue] = useState(null)

  const addLabelHandler = async () => {
    await addCallback(id, labelValue)
    closeCallback()
  }

  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

          <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Label</h1>
            
            <div className='d-flex flex-column w-100'>
                <span className='p-label-2'>Name:</span>
                <input value={labelValue} onChange={(e) => setLabelValue(e.target.value)} className='p-input my-2' placeholder='Y axis label' type="text" />
            </div>
            
            <div onClick={addLabelHandler} style={{width: '100%'}} className='login-button mt-3'>Add Label</div>
              
        </div>
      </div>
  )
}

export default AddLabelModal
