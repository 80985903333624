import React, {useState} from 'react'
import '../styles/general.css'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, NavDropdown, Spinner } from 'react-bootstrap'
import  LoginModal from "../components/LoginModal"
import  RegisterModal from "../components/RegisterModal"
import  ForgotModal from "../components/ForgotModal"
import  Loader from "../components/Loader"
import { logout } from '../actions/userActions'

function Header() {
  const dispatch = useDispatch()

  const [loginModal, setLoginModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [forgotModal, setForgotModal] = useState(false)
  

  const userLogin = useSelector(state => state.userLogin)
  const {loading, error, userInfo} = userLogin

  const logoutHandler = () => {
    dispatch(logout(userInfo.refresh))
  }
  return (
    <header className='search-header'>
      <LinkContainer to={'/'}>
        <img className='search-header-image' src="/static/idb-white.svg"/>
      </LinkContainer>
        <div className='search-header-right'>
            <div className='search-header-nav'>
              
                <a href='https://dev.socrata.com/foundry/mydata.iadb.org/tbnd-z94f' target='_blank'>Web API</a>
                
                {loading ? (
                  <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                ) :userInfo ? (
                  <Nav>
                    {userInfo && (
                        <NavDropdown className='no-chevron p-0' menuVariant='dark' title={userInfo.name} id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={logoutHandler} style={{fontSize: '16px'}} className='text-white px-0'>
                            Sign Out
                          </NavDropdown.Item>
                        </NavDropdown>
                    )}
                  </Nav>
                ): (
                  <div style={{cursor: 'pointer'}} onClick={e => setLoginModal(true)}>Log in</div>
                )}
            </div>
            <div className='search-header-title'>
                LMW-FISLAC
            </div>
        </div>

        {loginModal && (
          <LoginModal 
            closeCallback={() => {setLoginModal(false)}}
            registerCallback={e => {setLoginModal(false); setRegisterModal(true)}}
            forgotCallback={e => {setLoginModal(false); setForgotModal(true)}} />
        )}

        {registerModal && (
          <RegisterModal closeCallback={() => {setRegisterModal(false)}} loginCallback={e => {setRegisterModal(false); setLoginModal(true)}} />
        )}
      

        {forgotModal && (
          <ForgotModal closeCallback={() => {setForgotModal(false)}} />
        )}
    </header>
  )
}

export default Header
